import React, { useCallback, useEffect} from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import map from 'lodash/map';
import range from 'lodash/range';
import { Button, Grid } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import {isMobileOnly} from 'react-device-detect';

import {
  ResolvedJsonFormsDispatch,
  withJsonFormsArrayLayoutProps
} from '@jsonforms/react';
import { composePaths } from '@jsonforms/core';



const CQSingnaturesControl = ({
  schema,
  uischemas,
  uischema,
  path,
  errors,
  visible,
  enabled,
  minItems,
  label,
  required,
  removeItems,
  addItem,
  data,
  renderers,
  cells,
  config} : any) => {

  useEffect(() => {
    if(data === 0) {
      // add one item by default
      addMoreSigs();
    }
  }, data);

  const isEnabled = window.location.pathname.indexOf('selected-submission') === -1 && enabled;

  const handleRemoveItem = useCallback(
    (p: string, value: any) => () => {

      if(!window.confirm('Are you sure ?')) {
        return;
      }

      removeItems(p, [value])();
    },
    [removeItems]
  );

  const getSchema = () => {
    return {  
      "type": "VerticalLayout",
      "elements": [
        {
          "type": "signatureCard",
          "scope": "#"
        }
      ]
    };
  }

  const addMoreSigs = () => {
    addItem(path, {
      "SignatureBase64": ""
    })();
  }

  /**
   * Checks whether schema definitions have preventAddition property or not  
   * @returns preventAddition status
   */
  const canAdd = (): boolean => {
    if (schema?.definitions?.Signature) {
      let obj = schema.definitions.Signature[Object.keys(schema.definitions.Signature)[2]];
      return (obj && !obj.preventAddition);
    }
    return true;
  }

  /**
   * Checks whether schema definitions have preventDeletion property or not  
   * @returns preventDeletion status
   */
  const canDelete = (): boolean => {
    if (schema?.definitions?.Signature) {
      let obj = schema.definitions.Signature[Object.keys(schema.definitions.Signature)[3]];
      return (obj && !obj.preventDeletion);
    }
    return true;
  }

  return (
    <>
      <Grid container spacing={2} style={isMobileOnly ? {display: 'flex'} : {}}>
        {data > 0 ? 
            map(range(data), index => (<Grid item key={index}>
              {isEnabled && !canDelete() ? 
              <Button onClick={handleRemoveItem(path, index)}><DeleteIcon color="secondary" /></Button> : '' }
              <ResolvedJsonFormsDispatch
                            renderers={renderers}
                            cells={cells}
                            visible={visible}
                            schema={schema}
                            uischema={getSchema()}
                            path={composePaths(path, `${index}`)}
                        />

              </Grid>
            ))
        : ''}
        </Grid>
        {isEnabled ? <>
        <br/>
        <div>
          {
            canAdd() ? null : <AddCircleIcon onClick={addMoreSigs}></AddCircleIcon>
          } 
        </div>
        </>
        : '' }

        {errors ? JSON.stringify(errors): ''}

     </>
  )
};


export default withJsonFormsArrayLayoutProps(CQSingnaturesControl);