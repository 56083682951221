/*
 * Filename: node-config.ts
 * Description: node config comp.
 */

import { ENV_PRODUCTION } from '../config/environments/environment.prod';
import { ENV_SANDBOX } from '../config/environments/environment';

/**
 * @export
 * @class CQNodeServerConfig
 */
export class CQNodeServerConfig {
    public static NODE_BASE_URL_SANDBOX = ENV_SANDBOX.NODE_END_POINT;
    public static NODE_BASE_URL_PROD = ENV_PRODUCTION.NODE_END_POINT;

    public static END_POINTS = {
        AUTH_USER: '/api/authUser',
        GET_TEMPLATE: '/api/templates/getTemplates/',
        GET_ORG_INFO: '/api/sf/orgInfo',
        GET_LAYOUTS: '/api/sf/layouts',
        GET_OBJECTS: '/api/sf/object',
        GET_API_OBJECT_FIELDS: '/api/sf/getObjectFields',
        GET_LAYOUT: '/api/sf/layout',
        GET_APEX_CLASS: '/api/sf/getComponent',
        GET_USERINFO: '/api/sf/userInfo',
        GET_LWC_COMPONENT:'/api/sf/getLWCList',
        GET_VF_PAGE_COMPONENT:'/api/sf/getVFPages',
        PUBLISH_METADATA: '/api/templates/publish/',
        PUBLISH_DIFF_ORG_METADATA: '/api/templates/publishDiffOrg/',
        DOWNLOAD_LWC: '/api/templates/download/',
        GET_DEPLOYED_TEMPLATES: '/api/sf/retrievePackage',
        GET_LOOKUP_OBJECT_FIEDLS: '/api/sf/getObjectFields',
        GET_AUTOLAUCNH_FLOW: '/api/sf/getAutoLaunchedFlow',
        GET_BASE_CLASS: '/api/sf/getDefaultBaseClassJSON',
        GET_PICKLIST_VALUES: '/api/sf/getPicklistValues',
        LOGOUT: '/api/sf/logout',
    };

    public static RESPONSE_TYPE = 'token';
    public static GRANT_TYPE = {
        REFRESH_TOKEN: 'refresh_token'
    };
    public static API_LIMIT = 1;
    public static DEFAULT_LIMIT = 1;
    public static AUTHORIZATION_HEADER_KEY = 'token';
    public static AUTHORIZATION_HEADER_KEY_ACCESS_TOKEN = 'accesstoken';
    public static RESPONSE_TYPES = 'responseType';
    public static CONTENT_TYPE: 'Content-Type';
    public static APPLICATION_JSON: 'application/json';

    public static HEADER_RESPONSE_TYPES = {
        BLOB: 'blob'
    };
}
