import React from 'react';
import {useHistory} from 'react-router-dom'
import { CQAPIService } from '../../services/http-api.service';

import SLDSAvatar from '@salesforce/design-system-react/components/avatar';

import { CQAppConstant } from '../../app-constants';
import strings from '../../localizations/homeScreen';
import CQCircularProgress from '../CQCircularProgress';

const Avatar : any = SLDSAvatar;

// Profile content is currently the contents of a generic `Popover` with markup copied from https://www.lightningdesignsystem.com/components/global-header/. This allows content to have tab stops and focus trapping. If you need a more specific/explicit `GlobalHeaderProfile` content, please create an issue.
function CQHeaderProfileContent (props) {
    let history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const userDetails : any = props.userDetails;
    let cqAPIService = new CQAPIService();
    
    /**
     * Initiate logout process
     */
    const logout = async ()=> {
        try {
            if (window.confirm(strings.logoutConfirm)) {
                setIsLoading(true);
                await cqAPIService.logoutJSForce().then( () => {
                    setIsLoading(false);
                    localStorage.clear();
                    history.push('/login');
                }).catch((error)=>{
                    console.log(error);
                    setIsLoading(false);
                });
            }
        }catch (error) {
            setIsLoading(false);
            const errorObj = {
                errorStatus: error.status,
                errorType: CQAppConstant.ERROR_CUSTOM,
                errorMessage: error.error ? error.error : error.message,
                componentName: ''
            };
        }
    }

    return(<>
        <CQCircularProgress isLoading={isLoading}/>
        <div id="header-profile-custom-popover-content">
            <div className="demo-only" style={{width:"30rem"}}>
                <article className="slds-tile slds-media">
                    <div className="slds-media__figure">
                        <Avatar
                            assistiveText={{ icon: strings.avatarImage}}
                            imgSrc={userDetails.SmallPhotoUrl}
                            imgAlt={userDetails.Name}
                        />
                    </div>
                    <div className="slds-media__body">
                        <p className="tile__title slds-text-heading_small">{userDetails.Name}</p>
                        <p className="tile__title slds-text-heading_small">{userDetails.Username}</p>
                        <div className="slds-tile__detail">
                            <p className="slds-truncate">
                                <a href="javascript:void(0)" 
                                onClick={() => {logout()}}>
                                    {strings.logOut}
                                </a>
                            </p>
                        </div>
                    </div>
                </article>
                </div>
        </div>
    </>
    );
}

export default CQHeaderProfileContent
