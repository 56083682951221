import '../assets/slds/styles/salesforce-lightning-design-system.css'
import React, {useState, useEffect} from 'react';
import CQSectionHeader from 'components/CQSectionHeader';
import { ResolvedJsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';

const CQGrid = (props) => {
  const { uischema, schema, path, visible, renderers, enabled } = props;


  const [isToggle, setIsToggle] = useState<Boolean[]>([]);

  useEffect(() => {
    initialize();
  },[])

  /**
   * This method set initial values of isToggle state
   */
    const initialize = () => {
      console.log('Initialize')
      let copy : Boolean[] = []
      copy[props.index] = false;
      setIsToggle(copy)
    }

  /**
   * This method sets isToggle state after onClick action
   * @param i : Index of section
   */
  const toggle = (i) => {
      let tempIndex : Boolean[] = [];
      tempIndex = [...isToggle]
      tempIndex[i] = !isToggle[i]
      setIsToggle(tempIndex)
  }

  const checkVisibility = () => {
    if(document.location.href.includes('CQEditFormSF')) return false;
    if(uischema?.label?.length === 0) return false;
    return true;
  }

  /**
   * This method check if form is running from the launch mode or preview mode with the no field dragged
   */
  const isFormLauncherWithElements= () => {
    if(!document.location.href.includes('CQEditFormSF') && (uischema && uischema?.elements[0]?.elements?.length) === 0) return false;
    return true;
  }

  return (
    <>
      {
        checkVisibility() ?
          <>
            <section id={'cq_section' + props.idx} className={"slds-accordion__section cq-accordion" + (isToggle[props.idx] === false ? " slds-is-open" : "")}>
            <CQSectionHeader title={uischema.label} toggle={toggle} index={props.idx} isToggle={isToggle[props.idx]}/>
            {
             ( uischema && uischema?.elements[0]?.elements?.length) !== 0 &&
              <div className="slds-accordion__content">
                <article className="slds-card slds-card_boundary">
                  <div className="slds-p-around--large">
                    {visible &&
                      uischema.elements.map((child, index) => (
                        <ResolvedJsonFormsDispatch
                          schema={schema}
                          uischema={child}
                          path={path}
                          renderers={renderers}
                          key={index}
                          enabled={enabled}
                        />
                      ))}
                  </div>
                </article>
              </div>
            }
            </section>
          </> 
        :
        isFormLauncherWithElements() &&
        <>
          <article className="slds-card slds-card_boundary">
            <div className="slds-p-around--large">
              {visible &&
                uischema.elements.map((child, index) => (
                  <ResolvedJsonFormsDispatch
                    schema={schema}
                    uischema={child}
                    path={path}
                    renderers={renderers}
                    key={index}
                    enabled={enabled}
                  />
                ))}
            </div>
          </article>
        </> 
      }
    </>
  );
}

export default withJsonFormsLayoutProps(CQGrid);