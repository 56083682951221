import React from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';

const CQBlank = () => {

    return(
        <>  
            <div
                style={{ height: '50px'}}
            />
        </>
    )
}

export default withJsonFormsControlProps(CQBlank);