import React, { useEffect, useState } from "react";
import {
  ResolvedJsonFormsDispatch,
  withJsonFormsLayoutProps,
} from "@jsonforms/react";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import Tabs from "@salesforce/design-system-react/components/tabs";
import TabsPanel from "@salesforce/design-system-react/components/tabs/panel";
import { isMobileOnly } from 'react-device-detect';
import CQPicklist from '../components/CQPicklist';

/**
 * Renderer used to render multiple tabs.
 */
const CQTabs = (props: any) => {
  const { data, uischema, schema, path, cells, renderers, enabled } = props;


  const [isToggle, setIsToggle] = useState<Boolean[]>([]);
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    initialize();
  },[])

  /**
   * This method set initial values of isToggle state
   */
  const initialize = () => {
    console.log('Initialize')
    let copy : Boolean[] = [];
    setSelectedTab(uischema?.elements[0]?.label);
    setIsToggle(copy)
  }


  // used to check whether object in used as tab is addable or not
  const canAdd = (tabProperty: any): boolean => {
    if (schema?.properties) {
      let obj = schema.properties[Object.keys(schema.properties)[0]].properties;
      let childObj = obj[''+tabProperty]?.items?.properties[Object.keys(obj[''+tabProperty].items.properties)[0]]
      return childObj && !childObj.preventAddition;
    }
    return true;
  };

 // used to return length of related records
 const getRecordsLength = (childSchema: any, data: any): string =>{
    if (childSchema?.elements && childSchema?.elements[0].scope) {
      let childProps = childSchema.elements[0].scope.split('/properties/'); // split to get parent and child keys
      if(canAdd(childProps[2])){
        return (data && data[''+childProps[1]] && data[''+childProps[1]][''+childProps[2]]) ? '('+data[''+childProps[1]][''+childProps[2]].length+')' : '(0)'
      }
    }
    return '';
  }

  /**
   * This method used to get tabs and format as picklist values
   * @param elements 
   * @returns object of label and values which used to show as picklist
   */
  const getTabs = (elements: any): any =>{
    return elements.map((element: any) => ({
      label: element.label + ' '+ getRecordsLength(element, data),
      value: element.label,
    }));
  }
  
  /**
   * This method used to get element based on selected tab from picklist
   * @param selectedTabLabel 
   * @returns selected tab's element
   */
  const getTab = (selectedTabLabel: string): any =>{
    let tabElements: any[] = uischema.elements;
    return tabElements.find((element: any) => element.label === selectedTabLabel);
  }

  const getTabLabel = (selectedTabItem: string): any =>{
    return selectedTabItem +' '+ getRecordsLength(getTab(selectedTabItem), data);
  }

  return (
    <div style={{minHeight : '15rem'}}>
      <article className="slds-card">
        <div className={isMobileOnly ? "slds-p-around--small slds-m-bottom_large" : "slds-p-around--large"}>
          {isMobileOnly?
            <>
              <CQPicklist 
                tabs={getTabs(uischema.elements)}
                defaultTab = {getTabLabel(selectedTab)} 
                setSelectedTab = {setSelectedTab}
              />
              <br />
              <TabsPanel label={getTabLabel(selectedTab)}>
                <ResolvedJsonFormsDispatch
                  schema={schema}
                  uischema={getTab(selectedTab)}
                  path={path}
                  renderers={renderers}
                  cells={cells}
                  key={selectedTab}
                  visible={true}
                  enabled={enabled}
                />
              </TabsPanel>
            </>
            :
            <IconSettings iconPath="/assets/icons">
              <Tabs variant="scoped" id="tabs-example-scoped">
                {uischema.elements.map((child, index) => (
                  <TabsPanel label={getTabLabel(child.label)}>
                    <ResolvedJsonFormsDispatch
                      schema={schema}
                      uischema={child}
                      path={path}
                      renderers={renderers}
                      cells={cells}
                      key={index}
                      visible={true}
                      enabled={enabled}
                    />
                  </TabsPanel>
                ))}
              </Tabs>
            </IconSettings>
          }
        </div>
      </article>
    </div>
  );
};

export default withJsonFormsLayoutProps(CQTabs);
