/*
 * Http Base Service: This class holds generic methods for making get and post calls.
 */

import { CQAppConstant } from './../app-constants';
import { IErrorObject } from './IHttpApiService';
import { CQAPIOperationsObj } from './http-api.operation';
import { CQNodeServerConfig } from '../config/node.config';

/**
 * @export
 * @class CQHttpBaseService
 */
export class CQHttpBaseService {
    private static readonly CQ_HEADERS = new Headers({
      'Content-Type': 'application/json'
    });


  /**
   * @private
   * @returns {Headers}
   * @memberof CQHttpBaseService
   */
  public getHeaders(): Headers {
    CQHttpBaseService.CQ_HEADERS.set(CQNodeServerConfig.AUTHORIZATION_HEADER_KEY, '');
    return CQHttpBaseService.CQ_HEADERS;
  }

  /**
   * @private
   * @returns {Headers}
   * @memberof CQHttpBaseService
   */
  public getHeadersForOtherOrg(accessToken: string): Headers {
    CQHttpBaseService.CQ_HEADERS.set(CQNodeServerConfig.AUTHORIZATION_HEADER_KEY, '');
    CQHttpBaseService.CQ_HEADERS.set(CQNodeServerConfig.AUTHORIZATION_HEADER_KEY_ACCESS_TOKEN, accessToken);
    return CQHttpBaseService.CQ_HEADERS;
  }

  /**
   * @template T
   * @param {string} endpoint
   * @param {T} payload
   * @returns {(Promise<CQResponseType<IAPIResponse | IErrorObject>>)}
   * @memberof CQHttpBaseService
   */
  public async postApi<T>(endpoint: string, payload: T) {
    return fetch(`${CQAppConstant.NODE_SERVER_BASE}${endpoint}`, {
      method: CQAppConstant.HTTP_REQUEST_TYPES.POST_REQUEST,
      body: JSON.stringify(payload),
      headers: this.getHeaders(),
    })
      .then(async (response: Response) => {
        return CQAPIOperationsObj.createPostResponse(response);
      })
      .catch((error: IErrorObject) => {
        throw CQAPIOperationsObj.handleErrorMessage(error);
      });
  }

  /**
   * @template T
   * @param {string} endpoint
   * @param {T} payload
   * @returns {(Promise<CQResponseType<IAPIResponse | IErrorObject>>)}
   * @memberof CQHttpBaseService
   */
  public async postApiForOtherOrg<T>(endpoint: string, accessToken: string, payload: T) {
    return fetch(`${CQAppConstant.NODE_SERVER_BASE}${endpoint}`, {
      method: CQAppConstant.HTTP_REQUEST_TYPES.POST_REQUEST,
      body: JSON.stringify(payload),
      headers: this.getHeadersForOtherOrg(accessToken),
    })
      .then(async (response: Response) => {
        return CQAPIOperationsObj.createPostResponse(response);
      })
      .catch((error: IErrorObject) => {
        throw CQAPIOperationsObj.handleErrorMessage(error);
      });
  }

  public async postApiWithBlobResponse<T>(endpoint: string, payload: T) {
    return fetch(`${CQAppConstant.NODE_SERVER_BASE}${endpoint}`, {
      method: CQAppConstant.HTTP_REQUEST_TYPES.POST_REQUEST,
      body: JSON.stringify(payload),
      headers: new Headers({
        'Content-Type': 'application/json',
        token: '',
        responseType: 'blob',
      }),
    })
      .then(async (response: any) => {
        return response;
      })
      .catch((error: IErrorObject) => {
        throw CQAPIOperationsObj.handleErrorMessage(error);
      });
  }

  /**
   * @template T
   * @param {string} endpoint
   * @returns {(Promise<ICONResponseType<IAPIResponse | IErrorObject>>)}
   * @memberof ICONHttpBaseService
   */
  public async getApi(endpoint: string) {
    return fetch(`${CQAppConstant.NODE_SERVER_BASE}${endpoint}`, {
      // return fetch(`https://api.myjson.com/bins/mpov2`, {
      method: CQAppConstant.HTTP_REQUEST_TYPES.GET_REQUEST,
      headers: this.getHeaders(),
    })
      .then(async (response: Response) => {
        return CQAPIOperationsObj.createPostResponse(response);
      })
      .catch((error: IErrorObject) => {
        throw CQAPIOperationsObj.handleErrorMessage(error);
      });
  }

  /**
   * @template T
   * @param {string} endpoint
   * @param {T} payload
   * @returns {(Promise<CQResponseType<IAPIResponse | IErrorObject>>)}
   * @memberof CQHttpBaseService
   */
  public async postApiLogout<T>(endpoint: string, token: string) {
    return fetch(`${CQAppConstant.NODE_SERVER_BASE}${endpoint}`, {
      method: CQAppConstant.HTTP_REQUEST_TYPES.POST_REQUEST,
      body: JSON.stringify({ loginUrl: 'https://nosoftware-app-343-dev-ed.cs68.my.salesforce.com/' }),
      headers: new Headers({
        'Content-Type': 'application/json',
        token: token,
      }),
    })
      .then(async (response: Response) => {
        return CQAPIOperationsObj.createPostResponse(response);
      })
      .catch((error: IErrorObject) => {
        throw CQAPIOperationsObj.handleErrorMessage(error);
      });
  }
}
