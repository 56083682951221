import React, {useContext} from 'react';
import './App.css';
import {renderRoutes} from 'react-router-config';
import CQGlobalHeader from './components/CQGlobalHeader';
import SFEmbedder from './components/SFEmbedder';
import AccessManagerContext from './context/AccessManagerContext';

function App({route} : any) {

  const accessManagerContext = useContext(AccessManagerContext);
  return (
    <>
          { process.env.REACT_APP_FORMS_APP_MODE === 'Salesforce' ?
          <SFEmbedder></SFEmbedder> :
          accessManagerContext.isCanvasMode?
          <>
            <div className="cq-page">
              {renderRoutes(route.routes)}
            </div>
          </>:
          <>
            <CQGlobalHeader></CQGlobalHeader>
            <div className="cq-page">
              {renderRoutes(route.routes)}
            </div>
          </>
        }
    </>
  );
}

export default App;
