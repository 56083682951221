/*
 * Http Base Service: This class acts as a wrapper over the generic base class.
 * It is a midleware between data layer and generic serice layer.
 */

import { CQHttpBaseService } from './http-base.service';
import { CQNodeServerConfig } from '../config/node.config';
import { SfConnector } from './../common/sf-connector';
import { IOAuthRequestOptions } from './../interfaces/IApp.interface';

const INSTANCE_URL = 'https://nosoftware-app-343-dev-ed.cs68.my.salesforce.com/';

/**
 * @export
 * @class CQAPIService
 * @extends {CQHttpBaseService}
 */
export class CQAPIService extends CQHttpBaseService {
  private readonly sfConnector: SfConnector;
  public constructor() {
    super();
    this.sfConnector = new SfConnector();
  }

  /**
   * @memberof CQAPIService
   */
  public authenicateUser = async (accessToken: string) => {
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.AUTH_USER,
        {
          'accessToken': accessToken
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public fetchTemplates = async (versionNumber: string): Promise<any> => {

    return (
      this.getApi(
        `${CQNodeServerConfig.END_POINTS.GET_TEMPLATE}${versionNumber}`
        // `${CQNodeServerConfig.END_POINTS.GET_TEMPLATE}${'9.1'}`
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public fetchDeployedTemplates = async (developerName = 'cqui'): Promise<any> => {
    return (
      this.postApi(
        `${CQNodeServerConfig.END_POINTS.GET_DEPLOYED_TEMPLATES}`,
        {
          'instanceUrl': INSTANCE_URL,
          'developerName': developerName
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getLookupObjectFields = async (lookObjectName: string): Promise<any> => {
    return (
      this.postApi(
        `${CQNodeServerConfig.END_POINTS.GET_LOOKUP_OBJECT_FIEDLS}`,
        {
          'instanceUrl': INSTANCE_URL,
          'objectName': lookObjectName
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getOrgInformation = async (): Promise<any> => {
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.GET_ORG_INFO,
        {
          'instanceUrl': INSTANCE_URL
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getUserInformation = async () => {
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.GET_USERINFO,
        {
          'instanceUrl': INSTANCE_URL
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getObjects = async (): Promise<any> => {
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.GET_OBJECTS,
        {
          'instanceUrl': INSTANCE_URL
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getApiObjectFields = async (objectName: string, hasParent: boolean, includeStandardFieldFlag?: boolean): Promise<any> => {


    const reqPayload = includeStandardFieldFlag ?
      {
        'instanceUrl': INSTANCE_URL,
        'objectName': objectName,
        'hasParent': hasParent,
        'includeNonUpdateable': true
      } :
      {
        'instanceUrl': INSTANCE_URL,
        'objectName': objectName,
        'hasParent': hasParent,

      };
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.GET_API_OBJECT_FIELDS,
        reqPayload
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getLayouts = async (objectName: string): Promise<any> => {
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.GET_LAYOUTS,
        {
          'instanceUrl': INSTANCE_URL,
          'objectName': objectName
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getLayout = async (selectedLayoutName: string, objectName: string, TableEnumOrId: string) => {
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.GET_LAYOUT,
        {
          instanceUrl: INSTANCE_URL,
          layoutName: selectedLayoutName,
          objName: objectName,
          TableEnumOrId
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getApexClass = async () => {
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.GET_APEX_CLASS,
        {
          instanceUrl: INSTANCE_URL,
          interfaceName: "CQUI_DynamicFilterInterface"
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getAutoLaunchFlows = async () => {
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.GET_AUTOLAUCNH_FLOW,
        {
          instanceUrl: INSTANCE_URL,
          labelPrefix: 'CQ'
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public fetchBaseClass = async () => {
    return (
      this.postApi(
        CQNodeServerConfig.END_POINTS.GET_BASE_CLASS,
        {
          instanceUrl: INSTANCE_URL
        }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public downloadLWC = async (versionNumber: string, templateName: string, metadata: any) => {
    return (
      this.postApiWithBlobResponse(
        `${CQNodeServerConfig.END_POINTS.DOWNLOAD_LWC}${versionNumber}/${templateName}`,
        { ...metadata, template: templateName }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public publishLWC = async (versionNumber: string, templateName: string, metadata: any) => {
    return (
      this.postApi(
        `${CQNodeServerConfig.END_POINTS.PUBLISH_METADATA}${versionNumber}/${templateName}`,
        { ...metadata, template: templateName, instanceUrl: INSTANCE_URL }
      )
    );
  }

  /**
  * @memberof CQAPIService
  */
  public publishLWCToOtherOrg = async (versionNumber: string, templateName: string, metadata: {}, instanceUrl: string, accessToken: string, environment: string) => {
    return (
      this.postApiForOtherOrg(
        `${CQNodeServerConfig.END_POINTS.PUBLISH_DIFF_ORG_METADATA}${versionNumber}/${templateName}/${environment}`,
        accessToken,
        { ...metadata, template: templateName, instanceUrl }
      )
    );
  }

  /**
   * @memberof CQAPIService
   */
  public getAllLWCComponent = async (searchKey?: string) => {
    if (searchKey) {
      return (
        this.postApi(
          `${CQNodeServerConfig.END_POINTS.GET_LWC_COMPONENT}`,
          {
            developerName: searchKey,
            'limit': 50, instanceUrl: INSTANCE_URL
          }
        )
      )
    } else {
      return (
        this.postApi(
          `${CQNodeServerConfig.END_POINTS.GET_LWC_COMPONENT}`,
          {
            developerName: 'CQ',
            'limit': 10, instanceUrl: INSTANCE_URL
          }
        )
      )
    }
  }

  public getAllVFPageComponent = async (searchText?:string) => {
    if(searchText){
      return (
        this.postApi(
          `${CQNodeServerConfig.END_POINTS.GET_VF_PAGE_COMPONENT}`,
          {
            name: searchText,
            'limit': 100,
            instanceUrl: INSTANCE_URL
          }
        )
      );
    }
    return (
      this.postApi(
        `${CQNodeServerConfig.END_POINTS.GET_VF_PAGE_COMPONENT}`,
        {
          name: '',
          'limit': 10,
          instanceUrl: INSTANCE_URL
        }
      )
    );
  }
  //   /**
  //    * @memberof CQAPIService
  //    */
  //   public getAllLWCComponent = async () => {
  //   return (
  //     this.postApi(
  //       `${CQNodeServerConfig.END_POINTS.GET_LWC_COMPONENT}`,
  //       {
  //         developerName: 'CQ',
  //         'limit': 50, instanceUrl: this.cookie.get(CQAppConstant.INSTANCE_URL)
  //       }
  //     )
  //   );
  // }

  public getPicklistValues = async (objectName: string, fieldName: string) => {
    return (
      this.postApi(
        `${CQNodeServerConfig.END_POINTS.GET_PICKLIST_VALUES}`,
        { objectName, fieldName, instanceUrl: INSTANCE_URL }
      ))
  }

  /**
   * @memberof CQAPIService
   */
  public login = async (oAuthRequestBody: IOAuthRequestOptions) => {
    try {
      return await this.sfConnector.login(oAuthRequestBody);
    } catch (error) {
      return error;
    }
  }


  /**
   * @memberof CQAPIService
   */
  public logoutJSForce = async () => {
    try {
      return await this.sfConnector.logOut();
    } catch (error) {
      return error;
    }
  }

  /**
   * @memberof CQAPIService
   */
  public logout = async (token: string) => {
    return (
      this.postApiLogout(
        CQNodeServerConfig.END_POINTS.LOGOUT, token
      )
    );
  }

}
