import React from 'react';
import { ResolvedJsonFormsDispatch, withJsonFormsLayoutProps} from '@jsonforms/react';

/**
 * Renderer used to render content inside a tab.
 */
const CQTab = ( props : any) => {
    const { uischema, schema, path, cells, visible, renderers, enabled } = props;

    const getUISchema = (uischema) => {
        return uischema.elements[0];
    }
    
    return (
        <>  
            <ResolvedJsonFormsDispatch
                    schema={schema}
                    uischema={getUISchema(uischema)}
                    path={path}
                    renderers={renderers}
                    cells = {cells}
                    visible = {visible}
                    enabled={enabled}
            />
        </>
     );
}

export default withJsonFormsLayoutProps(CQTab)