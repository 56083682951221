import React, { useEffect } from 'react';
import '../assets/slds/styles/salesforce-lightning-design-system.css'
import strings from '../localizations/homeScreen';
import formScreenStrings from '../localizations/formScreen';
import { isMobileOnly } from 'react-device-detect';
import { isSalesforceBuild } from 'salesforceBuild';

const CQHeader = (props) => {

  /**
   * This method triggers the print
   */
  const handlePrint = () => {
    window.print();
  }

  useEffect(() => {
    /**
     * This method removed the unselected button on PDF
     */
    window.addEventListener('beforeprint', () => {
      let buttons:any = document.getElementsByClassName('cq-select-btn');
      let footer:any = document.getElementsByClassName('cq-form-footer');
      for(let i=0; i < buttons.length; i++) {
        if(buttons[i].style.backgroundColor === 'white') {
          buttons[i].style.display = 'none';
        }
      }
      footer[0].style.display = 'none';
      const textarea:any = document.getElementsByClassName('cq-input-textarea');
      for(let i=0; i < textarea.length; i++) {
        textarea[i].style.display="block";
        textarea[i].style.height = "fit-content";
        if (textarea[i].value === '') {
          // Set the height of textarea to be initial if there is no content on textarea
          textarea[i].style.height = `auto`;
       } else if (textarea[i] instanceof HTMLTextAreaElement ) {
          // Calculate the increase height and set the textarea height according to increase in height
          const newHeight = textarea[i].scrollHeight;
          textarea[i].style.height = `initial`;
          // Only increase the height if the scroll height is greater than the client height   
          if (textarea[i].scrollHeight > textarea[i].clientHeight) {
            textarea[i].style.height = `${newHeight}px`;
          }
        }
      }
      const inputField:any=document.getElementsByClassName('slds-input');
      for(let i=0; i < inputField.length; i++) {
        if(inputField[i].type==="text"){
          inputField[i].style.display ="none";
        }
      }
    })
    /**
     * This method undo all the changes done on before print
     */
    window.addEventListener('afterprint', () => {
      let buttons:any = document.getElementsByClassName('cq-select-btn');
      let footer:any = document.getElementsByClassName('cq-form-footer');
      for(let i=0; i < buttons.length; i++) {
        if(buttons[i].style.backgroundColor === 'white') {
          buttons[i].style.display = '';
        }
      }
      footer[0].style.display = '';  
      const textarea:any=document.getElementsByClassName('cq-input-textarea');
      for(let i=0; i < textarea.length; i++) {
        textarea[i].style.display="none";
      }
      const inputField:any=document.getElementsByClassName('slds-input');
      for(let i=0; i < inputField.length; i++) {
        if(inputField[i].type==="text"){
        inputField[i].style.display="";
        }
      }
    })

    //This method is used to set the background color for the selected button on print preview
    const printHandler = () => {
      const buttons = document.querySelectorAll('.cq-select-btn');
      buttons.forEach((button: Element) => {
        const btn = button as HTMLElement;
        btn.style.setProperty('--button-bg-color', btn.style.backgroundColor);
        btn.style.setProperty('--button-text-color', btn.style.color);
      });
    };
    window.addEventListener('beforeprint', printHandler);


    return (() => {
      window.removeEventListener('beforeprint', ()=>{})
      window.removeEventListener('afterprint', ()=>{})
      window.removeEventListener('beforeprint', printHandler);
    });
  }, [])
 
  return (
    <div className="slds-page-header slds-grid slds-size_12-of-12">
      <div className={`slds-page-header__row ${isMobileOnly ? `slds-size_10-of-12`: `slds-size_11-of-12`}`}>
        <div className="slds-page-header__col-title">
          <div className="slds-media">
            <div className="slds-media__figure">
              <span className="slds-icon_container">
                <img className="cq-form-logo" src={props.logo} alt={strings.logoAltText}/>
              </span>
            </div>
            <div className="slds-media__body">
              <div className="slds-page-header__name">
                <div className="slds-page-header__name-title">
                  <h1>
                    <span className="slds-page-header__title slds-truncate">{props.header}</span>
                  </h1>
                </div>
              </div>
              <p className="slds-page-header__name-meta">{props.subheader}</p>
            </div>
          </div>
        </div>
      </div>
      <button className='slds-size_1-of-12 slds-button slds-button_outline-brand cq-print-button' onClick={handlePrint} style={{display: isSalesforceBuild() ? 'none': ''}}>
        {formScreenStrings.print}
      </button>
    </div>
  );
}

export default CQHeader;