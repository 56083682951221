import { withJsonFormsControlProps } from "@jsonforms/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { isSalesforceBuild } from "salesforceBuild";
import { adjustTextareaHeights } from "services/adjustTextareaHeight";
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import CQToast from "components/CQToast/CQToast";
import { CQApiConstant } from "api/api-constants";
import CQSubmissionContext from "context/CQSubmissionContext";


const CQTable = (props) => {
  const { uischema, schema, path, cells, visible, renderers, enabled, data, handleChange } = props;

  const [initialRowData, setInitialRowData]=useState<any>();
  const submissionContext = useContext(CQSubmissionContext);
  const submissionInfo :any = submissionContext?.submission;

  /**
   *  This is used to update the initial row data of the table based on the submission information whenever the submission information changes. 
   */
  useEffect(()=>{
    if(submissionInfo?.data){
      const objName=submissionInfo.formDef.cqFormType;
      if(submissionInfo?.data[objName]){
        for (const [key, value] of Object.entries(submissionInfo.data[objName])) {
          if (uischema.scope.includes(key)) {
            setInitialRowData(()=>getData(value).unformattedStructure())
          }
      }
    }
    }
  },[submissionInfo?.data, data]);

  const originalSubmissionData = useMemo(() => submissionInfo?.originalData, [submissionInfo]);
  const [input, setInput] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [validSchema, setValidSchema] = useState<any>(() => {
    if(schema?.type === 'array'){
      let objName = Object.keys(schema.items.properties)[0];
      return schema.items.properties[objName];
    }
    return schema;
  });

  useEffect(() => {
    if(data && data.length !== uischema.options.rowsCount){
        //Create a shallow copy of data
        let tempData = [...data];
        // adding missing data from table
        let dataStruct = { [uischema.label] : {}};
        let tableFields = Object.keys(schema.items.properties[uischema.label].properties );
        tableFields.forEach((field) => {
            dataStruct[uischema.label][field] = undefined;
        })
        for(let index = 0 ; index < Number(uischema.options.rowsCount - tempData.length); index++){
           data.push(dataStruct);
        }
    }
    setIsLoaded(true);
  },[])

  let validationError = {};
  const [showToast, setShowToast] = useState(false);
  const [errorToastMessage, setErrorToastMessage] = useState('');
  /**
   * This method formats and unformats the structure for submission data and from submission data respectively
   * @param data 
   * @returns 
   */
  const getData = (data) => {
    /**
     * This method formats data into submission data structure
     * @returns 
     */
    const formattedStructure = () => {
      let formattedData : any = [];
      let isArrayItem : boolean = schema?.type === 'array';
      let objName = isArrayItem ? Object.keys(schema.items.properties)[0] : undefined;
      for (let i=0; i < data.length; i++) {
        formattedData.push({});
        for (let j=0; j < data[i].length; j++) {
          formattedData[i][data[i][j]['for']] = data[i][j]['value'];
        }
        formattedData[i] = objName ? { [objName] : formattedData[i]} : formattedData[i];
      }
      return formattedData;
  }

    /**
     * This method unformats data into initial rows structure
     * @returns 
     */
    const unformattedStructure = () => {
      let unformattedData:any = [];
      for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
          let rowData: any = [];
          for (let columnIndex = 0; columnIndex < getColumns().length; columnIndex++) {
              const columnName = getColumns()[columnIndex].name;
              let isArrayItem : boolean = schema?.type === 'array';
              let objName = isArrayItem ? Object.keys(schema.items.properties)[0] : undefined;
              const columnValue = objName ? data[rowIndex][objName][columnName] : data[rowIndex][columnName];
              let mainObjectApiName = path.split('.')[0];
              let tableApiName = path.split('.')[1];
              if(submissionInfo && objName){
                if(data[rowIndex][objName]?.[columnName] === originalSubmissionData?.[mainObjectApiName]?.[tableApiName]?.[rowIndex]?.[objName]?.[columnName]){
                  const cellObject = {value: columnValue || undefined, for: columnName, 'isEdited': false};
                  rowData.push(cellObject);
                }else{
                  const cellObject = {value: columnValue || undefined, for: columnName, 'isEdited': true};
                  rowData.push(cellObject);
                }
              }
              else{
                const cellObject = {value: columnValue || undefined, for: columnName, 'isEdited': false};
                rowData.push(cellObject);
              }
              if(columnValue !== undefined && columnValue !== ""){
                const column = getColumns()[columnIndex];
                const regexPattern = column.pattern;
                //Check if the input field match the regex pattern 
                const regex = new RegExp(regexPattern);
                const isValid = regex.test(columnValue);
                if (!isValid) {
                  validationError[`${rowIndex}-${columnIndex}`] = true; // Store validation error
                }
              }
          }
          unformattedData.push(rowData);
      }
      return unformattedData;
  };
  

    return {formattedStructure, unformattedStructure}
  }

  /**
   * This method gets rows from uischema count
   * @returns 
   */
  const getRows = () => {
    if (uischema.options.hasOwnProperty("rowsCount") && data === undefined) {
      let tableRows: any = [];
      for (let i = 0; i < uischema.options.rowsCount; i++) {
        tableRows.push([]);
      }
      for (let i = 0; i < getColumns().length; i++) {
        tableRows.map((row: any) => row.push({value:undefined, for: getColumns()[i].name, 'isEdited': false}));
      }
      return tableRows;
    }
      return getData(data).unformattedStructure();
  };

  /**
   * This method gets columns from the schema and sort them accoring to their keys
   * @returns 
   */
  const getColumns = () => {
    let tableColumns : any = [];
    let columnType:any = 'string';
    const columnName = Object.keys(validSchema.properties)
    .sort((a, b) => {
      if (validSchema.properties[a].id < validSchema.properties[b].id) return -1;
      if (validSchema.properties[a].id > validSchema.properties[b].id) return 1;
      return 0;
  })
    columnName.forEach(key => {
        switch(validSchema.properties[key].regexRuleName) {
          case 'Numeric Only':
              columnType = 'number';
              break;
          default:
              columnType = 'string';
        }
        const title = validSchema.properties[key].title;
        const regexRuleName = validSchema.properties[key].regexRuleName;
        const regexCode = validSchema.properties[key].pattern;
        const message = validSchema.properties[key].errorMessage;
        const type = columnType;
        validSchema.properties[key].type = columnType;
        tableColumns.push({ title: title, name: key, regexRuleName: regexRuleName, pattern: regexCode, errorMessage: message, type });
    });
    return tableColumns;
  };

  // This method checks if string can be parsed
  const canBeParsedToInt = (str: any) => {
    const parsed = parseInt(str);
    return !isNaN(parsed) && parsed.toString() === str;
  }

  /**
   * This method executes when row is changed
   * @param event 
   * @param index 
   * @param rowIndex 
   */
  const handleRowChange = (event:any, index:any, rowIndex:any) => {
    if(event.target.value.length < 255) {
      const textarea = event.target;
      textarea.style.height = "fit-content";
      if (textarea.scrollHeight > textarea.clientHeight && data.length) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
      setInput(event.target.value);
      let myList:any = getRows();
      let newValue = {value: getColumns()[index].type === 'number' ? 
        canBeParsedToInt(event.target.value) ? 
          parseInt(event.target.value)
          : event.target.value === '' ?  undefined : event.target.value 
        : event.target.value === '' ? undefined : event.target.value , for: getColumns()[index].name, 'isEdited': event.target.value ? true : false};
      myList[rowIndex][index] = newValue;
      if(event.target.value !== "" && event.target.value !== undefined){
        //Get the regex pattern and error message for the coloumn
        const column = getColumns()[index];
        const regexPattern = column.pattern;
        //Check if the input field match the regex pattern 
        const regex = new RegExp(regexPattern);
        const isValid = regex.test(event.target.value);
        if(!isValid){
          validationError=(prevErrors => ({
            ...prevErrors,
            [`${rowIndex}-${index}`] : true
          }));
        }else{
          validationError=(prevErrors => {
            const newErrors = {...prevErrors};
            delete newErrors[`${rowIndex}-${index}`];
            return newErrors;
          });
        }    
      }
      myList = getData(myList).formattedStructure();
      handleChange(path, myList);
    }
    adjustTextareaHeights(event);
  }

  /**
   * This methods gets width of column from uischema
   * @param column 
   * @returns 
   */
  const getColumnWidth = (column) => {
    if(uischema.options.hasOwnProperty('columnWidth') && uischema.options['columnWidth'][column.name]) {
      return uischema.options['columnWidth'][column.name]
    }
    return '';
  }

  const timeCount = useRef(3000);

  const handleClick = (index:any) => {
    const column = getColumns()[index];
    setErrorToastMessage(column.errorMessage);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, timeCount.current)
  }

  return (
    <>
    {
      isLoaded ? 
            <>
            {isMobileOnly ? (
              <div className="slds-card slds-m-vertical_medium" style={{padding: "0.5rem", textAlign: 'center' }}>
                <label style={{ fontSize: '1rem' }}>{validSchema.title}</label>
                {getRows().map((rows, rowIndex) => (
                  <div className="cq-table-mobile slds-m-vertical_xx-small" key={rowIndex}>
                    {getColumns().map((column, columnIndex) => (
                      <div key={columnIndex} className="cq-table-column-container slds-m-vertical_small" style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                        <label className="cq-grid-input" style={{textAlign: "start", width:"45%"}} >{column.title}</label>
                        <div style={{display: "flex", flexDirection: "row", border: validationError[`${rowIndex}-${columnIndex}`]? '2px solid red' : '0.5px solid white', justifyContent:"flex-start", width:"60%"}}>
                          {
                            validationError[`${rowIndex}-${columnIndex}`]?
                              <span onClick={()=>handleClick(columnIndex)}>
                                <IconSettings iconPath="/assets/icons">
                                  <div className="slds-col_padded">
                                    <Icon
                                      assistiveText={{ label: 'Warning' }}
                                      category="utility"
                                      colorVariant="warning"
                                      size="x-small"
                                      name="warning"
                                    />
                                  </div>
                                </IconSettings>
                              </span> : null
                          }
                          <textarea
                            rows={1}
                            className={!isSalesforceBuild() && rows[columnIndex].isEdited === true && rows[columnIndex].value!== "" && rows[columnIndex].value!== undefined  ? "cq-input-edit cq-table-textarea" : "cq-table-textarea"}
                            style={columnIndex === 0 || rowIndex === 0 ? {width: '99%', padding: "10px" } : { width: '99%', padding: "10px" }}
                            onChange={(event) => handleRowChange(event, columnIndex, rowIndex)}
                            value={rows[columnIndex].hasOwnProperty('value') ? rows[columnIndex].value : input}
                            disabled={isSalesforceBuild() || !enabled}
                          />
                          {
                            showToast && validationError ? 
                              <CQToast className="cq-table-toast slds-m-top_xx-large"
                                duration={CQApiConstant.TOAST_TIMER}
                                variant='warning'
                                heading={errorToastMessage}
                              /> : null
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ): (
              <div className="cq-form-table-desktop">
              <div
                className="slds-m-top_small"         
                style={{
                  backgroundColor: "white",
                  padding: "0.5rem",
                  border: "1px solid lightgrey",
                  textAlign: 'center'
              }}>
                <label style={{fontSize: '1rem'}}>{validSchema.title}</label>
                <div className="slds-grid cq-form-table slds-m-top_small slds-size_12-of-12">
                  <table
                    className="slds-table slds-scrollable slds-table_cell-buffer slds-table_bordered slds-table_col-bordered slds-size_12-of-12"
                    aria-labelledby="element-with-table-label other-element-with-table-label"
                  >
                    <thead>
                      <tr className="slds-line-height_reset">
                        {getColumns().map((column, index) => (
                          <th scope="col" key={index} style={{width: getColumnWidth(column)}}>
                            <div className="slds-truncate" title={column.title}>
                            <label key={index} className="cq-column-label">{column.title}</label>
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {getRows().map((rows, rowIndex) => (
                        <tr className="slds-hint-parent">
                          {rows.map((row, index) => (
                            <td key={index} style={{padding:0}}>
                              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", border: validationError[`${rowIndex}-${index}`]? '2px solid red' : '0.5px solid white'}}>
                              {
                                  validationError[`${rowIndex}-${index}`]?
                                  <span onClick={()=>handleClick(index)} className="slds-p-vertical_xxx-small">
                                    <IconSettings iconPath="/assets/icons">
                                      <div className="slds-p-horizontal_x-small">
                                        <Icon
                                          style={{cursor: "pointer"}}
                                          assistiveText={{ label: 'Warning' }}
                                          category="utility"
                                          colorVariant="warning"
                                          name="warning"
                                          size="xx-small"
                                        />
                                      </div>
                                    </IconSettings>
                                  </span> : null
                                }

                                <textarea
                                  rows={1}
                                  className={ !isSalesforceBuild() && rows[index].isEdited === true && rows[index].value!== "" && rows[index].value!== undefined ? "cq-input-edit cq-table-textarea" : "cq-table-textarea"}
                                  style={{width:'99%', border: '0.5px solid white'}} 
                                  onChange={(event) => handleRowChange(event, index, rowIndex)}
                                  value={row.hasOwnProperty('value') ? row.value: input}
                                  disabled={isSalesforceBuild() || !enabled}
                                />
                                {
                                  showToast && validationError ? 
                                    <CQToast className="slds-p-top_xx-large"
                                      duration={CQApiConstant.TOAST_TIMER}
                                      variant='warning'
                                      heading={errorToastMessage}
                                    /> : null
                                }
                              </div>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                </div>
              </div>
            )}
          </> 
      : null
    }
    </>
  );
};

export default withJsonFormsControlProps(CQTable);