import {CQFormLayout} from './CQFormLayout';


export class CQFormBuilderConstants{
    public static safetyInspectionSchema = {
        "objectType": "cqext__SQX_Safety_Inspection__c",
        "fields": [],
        "child": {
            "objectType": "cqext__SQX_Safety_Inspection_Criteria__c",
            "fields": [
                "cqext__criteria_title__c"
            ],
            "relationshipName": "cqext__SQX_Safety_Inspection_Criteria__r",
            "child": {
                "objectType": "cqext__SQX_Safety_Checklist__c",
                "fields": [
                    "cqext__response_type__c",
                    "cqext__response_value__c",
                    "cqext__response_score__c",
                    "cqext__comment__c"
                ],
                "relationshipName": "cqext__SQX_Safety_Checklist__r"
            }
        }
    };

    public static safetyInspectionUiSchema = new CQFormLayout({
        "type": "VerticalLayout",
        "elements": [
            {
                "label": "Section Header Title Area",
                "type": "Group",
                "elements": [
                    {
                        "type": "HorizontalLayout",
                        "elements": [
                        ]
                    }
                ]
            },
            {
                "layout": {
                    "type": "ListWithDetail",
                    "scope": "#/properties/cqext__SQX_Safety_Inspection_Criteria__c/properties/cqext__SQX_Safety_Checklist__r",
                    "title": "Questions",
                    "layout": {
                        "type": "VerticalLayout",
                        "elements": [
                            {
                                "elements": [
                                    {
                                        "type": "label",
                                        "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/cqext__Response_label__c",
                                        "options": {
                                            "style": "bold",
                                            "size": 8,
                                            "align": "left"
                                        }
                                    },
                                    {
                                        "elements":[
                                            {
                                                "type": "select",
                                                "scope": "#/properties/cqext__SQX_Safety_Checklist__c",
                                                "options": {
                                                    "size": 12,
                                                    "align": "right",
                                                    "valueScope": "cqext__Response_value__c",
                                                    "labelScope": "cqext__Response_value__c",
                                                    "field": "compliancequest__SQX_Result_Type__c",
                                                    "dependsOn": "cqext__Response_Type__c",
                                                    "lookupTo": "compliancequest__SQX_Result_Type_Value__c",
                                                    "valueField": "Name",
                                                    "titleField": "Name"
                                                }
                                            }
                                        ],
                                        "type": "CQFlexLayout"
                                    }
                                ],
                                "type": "HorizontalLayout"
                            },
                            {
                                "elements": [
                                    {
                                        "type": "Control",
                                        "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/cqext__Comment__c",
                                        "options": {
                                            "style": "bold",
                                            "size": 8,
                                            "align": "left",
                                            "multi": true
                                        }
                                    }
                                ],
                                "type": "HorizontalLayout"
                            }
                        ]
                    },
                    "footerLayout": {
                        "type": "HorizontalLayout",
                        "elements": [
                            {
                                "type": "fileUpload",
                                "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/Files"
                            }
                        ]
                    }
                },
                "section": 1,
                "scope": "#/properties/cqext__SQX_Safety_Inspection__c/properties/cqext__SQX_Safety_Inspection_Criteria__r",
                "title": "Sections",
                "type": "Section",
                "options": {
                    "droptype": "DOCUMENTS",
                    "headerLayout": {
                        "type": "label",
                        "scope": "#/properties/cqext__SQX_Safety_Inspection_Criteria__c/properties/cqext__Criteria_Title__c"
                    }
                }
            }
        ]
    });

    public static safetyInspectionUItemplate = {
        "type": "VerticalLayout",
        "elements": [
            {
                "label": "Section Header Title Area",
                "type": "Group",
                "elements": [
                    {
                        "type": "HorizontalLayout",
                        "elements": [
                        ]
                    }
                ]
            },
            {
                "layout": {
                    "type": "ListWithDetail",
                    "scope": "#/properties/cqext__SQX_Safety_Inspection_Criteria__c/properties/cqext__SQX_Safety_Checklist__r",
                    "title": "Questions",
                    "layout": {
                        "type": "VerticalLayout",
                        "elements": [
                            {
                                "elements": [
                                    {
                                        "type": "label",
                                        "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/cqext__Response_label__c",
                                        "options": {
                                            "style": "bold",
                                            "size": 8,
                                            "align": "left"
                                        }
                                    },
                                    {
                                        "elements":[
                                            {
                                                "type": "select",
                                                "scope": "#/properties/cqext__SQX_Safety_Checklist__c",
                                                "options": {
                                                    "size": 12,
                                                    "align": "right",
                                                    "valueScope": "cqext__Response_value__c",
                                                    "labelScope": "cqext__Response_value__c",
                                                    "field": "compliancequest__SQX_Result_Type__c",
                                                    "dependsOn": "cqext__Response_Type__c",
                                                    "lookupTo": "compliancequest__SQX_Result_Type_Value__c",
                                                    "valueField": "Name",
                                                    "titleField": "Name"
                                                }
                                            }
                                        ],
                                        "type": "CQFlexLayout"
                                    }
                                ],
                                "type": "HorizontalLayout"
                            },
                            {
                                "elements": [
                                    {
                                        "type": "Control",
                                        "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/cqext__Comment__c",
                                        "options": {
                                            "style": "bold",
                                            "size": 8,
                                            "align": "left",
                                            "multi": true
                                        }
                                    }
                                ],
                                "type": "HorizontalLayout"
                            }
                        ]
                    },
                    "footerLayout": {
                        "type": "HorizontalLayout",
                        "elements": [
                            {
                                "type": "fileUpload",
                                "scope": "#/properties/cqext__SQX_Safety_Checklist__c/properties/Files"
                            }
                        ]
                    }
                },
                "scope": "#/properties/cqext__SQX_Safety_Inspection__c/properties/cqext__SQX_Safety_Inspection_Criteria__r",
                "title": "Sections",
                "type": "Section",
                "options": {
                    "droptype": "DOCUMENTS",
                    "headerLayout": {
                        "type": "label",
                        "scope": "#/properties/cqext__SQX_Safety_Inspection_Criteria__c/properties/cqext__Criteria_Title__c"
                    }
                }
            }
        ]
    }

    public static NAMESPACE_REGEX = /^(compliancequest__|cqext__)/;
    public static OBJECT_NAME_REGEX = /[^_]+(?=__c$)/;
}

