import React, { useContext, useEffect, useRef, useState } from 'react'; 
import { withJsonFormsControlProps } from '@jsonforms/react';
import { isSalesforceBuild } from '../salesforceBuild';
import Input from '@salesforce/design-system-react/components/input'; 
import CQSubmissionContext  from '../context/CQSubmissionContext'; 
import { setEditedValueinOption, getEditedValueFromOption } from 'services/data-changeindicator.service';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import CQToast from "components/CQToast/CQToast";
import { CQApiConstant } from "api/api-constants";

const CQInputControl = ({data, handleChange, path, required, visible,label, enabled, uischema, errors, schema}: any) => {
  
  
  const [validationError, setValidationError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorToastMessage, setErrorToastMessage] = useState('');

  const submissionContext = useContext(CQSubmissionContext);

  useEffect(() => {
    if(!data && schema.hasOwnProperty('default')){
      if(schema?.default.length){
        handleInputChange({ "target" : { "value" : schema.default}});
      }
    }
  },[schema])
  
  const handleInputChange = (evt : any) => {
    if (!evt.target.value.trim().length) {
      handleChange(path, undefined);
      setValidationError(false);
      data= ""; // update the data to empty to avoid showing previous value as input doesn't take undefined as input value
    } else {
      if(!(evt.target.value === "")){ //only validate when the field consists value
        validationCheck(evt.target.value);
      }
      handleChange(path, evt.target.value);
    }
    uischema = setEditedValueinOption(data, submissionContext.submission, path, uischema); 
  }
  const timeCount = useRef(3000);

  const handleClick = () => {
    const errorMessage = schema.errorMessage;
    setErrorToastMessage(errorMessage);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, timeCount.current)
  }

  const validationCheck=(value)=>{
      data = value;
      const regexPattern  = schema.pattern;
      const regex = new RegExp(regexPattern);
      const isValid = regex.test(value);
      if(!isValid){
        setValidationError(true);
      }else{
        setValidationError(false)
      }
  }

  useEffect(()=>{
    if(data === undefined){
      setValidationError(false);     
    }else{
      validationCheck(data);
    }
  },[validationError])

  return (
  <>
  {visible?
    <>
      <IconSettings iconPath="/assets/icons">
        <div>
          <Input
          className={ !isSalesforceBuild() && getEditedValueFromOption(uischema,data,path,submissionContext.submission) ? 'cq-input-edit' : ''}
          label={label}
          iconLeft={
            validationError?
            <InputIcon
              name="warning"
              category="utility"
              onClick={handleClick}
            />:null
          }
          styleInput={{border: validationError ? '2px solid red' : '0.5px solid #dddbda'}}
          required={required}
          onInput={handleInputChange}
          value={data}
          disabled={isSalesforceBuild() || !enabled}
        />
        <div className="slds-form-element">
        <div className="slds-form-element__control">
        <textarea rows={1} className="slds-textarea cq-input-textarea" value={data}></textarea>
        </div>
        </div>
        {
            showToast && validationError ? 
              <CQToast className="slds-m-top_xx-large"
                duration={CQApiConstant.TOAST_TIMER}
                variant='warning'
                heading={errorToastMessage}
              /> : null
          }
        </div> 
      </IconSettings>
    </>:''}
  </>
  );
};
export default withJsonFormsControlProps(CQInputControl);