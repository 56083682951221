import React, { useState, useEffect } from 'react';
import strings from '../../../localizations/formScreen';
import homeScreenStrings from '../../../localizations/homeScreen';

import CQFormBuilderSF from 'components/editor/CQFormBuilderSF';
import Spinner from '@salesforce/design-system-react/components/spinner';


/*
 * Edit Form component for loading Form Builder in salesforce 
 */
function CQEditFormSF(props){
    const [isPageLoaded, setisPageLoaded] = useState(false);
    const [errors, setErrors] = useState('');
    
    const { params } = props.match;
    useEffect(() => {
        const editForm = async()=>{
            try{
                setisPageLoaded(true);
                let cqPageDiv = document.getElementsByClassName('cq-page') ? document.getElementsByClassName('cq-page')[0]:'';
                if(cqPageDiv){
                    cqPageDiv['style'].marginTop = '0';
                }
            } catch (e) {
                setisPageLoaded(false);
                if(e.response && e.response.data){
                    setErrors(homeScreenStrings.serverError + JSON.stringify(e.response.data));
                }else{
                    if(e.message === 'Network Error'){
                        setErrors(strings.networkError);
                    }else{
                        setErrors(JSON.stringify(e.message));
                    }
                }
            }
        }

        editForm();

    },[]);
   
    return (<>
        {isPageLoaded  ?
            <CQFormBuilderSF recordId={params.editFormRecordId}></CQFormBuilderSF> 
            :
            <Spinner
                size="small"
                variant="base"
                assistiveText={{ label: strings.loading }}
			/> 
        }
    </>);

}


export default CQEditFormSF;