
// Connected app properties to be used while authenticating user with salesforce - SANDBOX
export const ENV_SANDBOX = {
  production: false,
  CLIENT_ID: '3MVG9dPGzpc3kWycayyniM9jYdXLqHYotqsJEfJAO7c5IO2zaH.FMWsktH659HvK9egB.XIj0NZsU6uVqIVlB',
  CLIENT_SECRET: '7E335F771B06C288B9BB1FE6E674DFB5FA6631FA92626F842B5801BDF30EA163',
  REDIRECT_URI: 'http://localhost:3000/authSuccessCallback',
  AUTH_ENDPOINT: 'https://test.salesforce.com',
  RESPONSE_TYPE: 'token',
  // CQ Lightning Backend 
  NODE_END_POINT: 'http://localhost:8000'
};

