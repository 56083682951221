
/**
 * This method set namespace accordingly
 * @param data 
 * @param fieldApiName 
 * @returns 
 */

export function getNameSpaceField(data, fieldApiName) {
  let namespace = getNameSpace();
  return namespace && namespace.length > 0 ? data[getNameSpace() + '__' + fieldApiName] : data[fieldApiName];
}

/**
 * Return the namespace applicable for the job. If a localstorage value is present it is returned else default namespace is returned
 * @returns namespace
 */
export function getNameSpace() {
  let localStorageValue = localStorage.getItem('namespace');

  return  localStorageValue === null ? 'cqext' : localStorageValue;
}

/**
 * Return the field or object api name with namespace
 * @param apiName 
 * @returns field/object api name with namespace
 */
export function getFieldObjectApiNameWithNamespace(apiName) {
  let namespace = getNameSpace();
  return namespace && namespace.length > 0 ? getNameSpace() + '__' + apiName : apiName;
}