import React from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import strings from '../../localizations/homeScreen';


function CQPageHeader(props) {
    return (<>
        <IconSettings iconPath="/assets/icons">
            <PageHeader
                    icon={
                        <Icon
                            assistiveText={{
                                label: strings.checklists,
                            }}
                            category="standard"
                            name="record_update"
                            title={strings.checklists}
                        />
                    }
                    title={strings.checklists}
                    info={"Synced on : "+props.syncedDateValue}
                />
        </IconSettings>
    
    </>);
}

export default CQPageHeader;
