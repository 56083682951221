import React from "react";
import formBuilderScreenStrings from "localizations/formBuilderScreen";
import Icon from '@salesforce/design-system-react/components/icon';

/**
 * incoming props for Signature section with its type
 */
interface signatureProps {
    signature: string,
    handleClick: (signature: any) => void
}

export default function CQFormBuilderSignatureSection(props: signatureProps) {
    return (
        <div className="slds-card slds-grid slds-p-around_small">
            <span className="slds-size_11-of-12">{`${formBuilderScreenStrings.signature}: ${props.signature}`}</span>
            <span className="slds-size_1-of-12 slds-align_absolute-center" onClick={props.handleClick}>
                <Icon
                    assistiveText={{ label: formBuilderScreenStrings.deleteLabel }}
                    category="utility"
                    colorVariant="default"
                    name="delete"
                    size="x-small"
                />
            </span>
        </div>
    )
}