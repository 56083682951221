import React from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import { JsonForms } from '@jsonforms/react';

import {
  materialRenderers,
  materialCells
} from '@jsonforms/material-renderers';
import CQLookup from '../renderer/CQLookup';
import CQLookupTester from '../renderer/CQLookupTester';
import CQFileUpload from '../renderer/CQFileUpload';
import CQFileUploadTester from '../renderer/CQFileUploadTester';
import CQSubForm from '../renderer/CQSubForm';
import CQSubFormTester from '../renderer/CQSubFormTester';
import customSchema from './huge';

const renderers = [
    ...materialRenderers,
    //register custom renderers
    { tester: CQLookupTester, renderer: CQLookup },
    { tester: CQFileUploadTester, renderer: CQFileUpload },
    { tester: CQSubFormTester, renderer: CQSubForm}
  ];

const CQFileUploadControl = ({dispatch, schema} : any) => {


  return (
    <div>

<JsonForms
                   schema={customSchema.schema}
                   uischema={customSchema.uischema}
                   renderers={renderers}
                   data={customSchema.data}
                   cells={materialCells}
               />    </div>
  )
};


export default CQFileUploadControl;