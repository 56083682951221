import React from "react";
import strings from "localizations/formScreen";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import Icon from "@salesforce/design-system-react/components/icon";

interface fileAttachmentProps {
  handleClick: () => void;
}

export default function CQFormBuilderFileAttachment(
  props: fileAttachmentProps
) {
  return (
    <div className="slds-card slds-grid slds-p-vertical_x-small">
      <div className="slds-grid slds-size_12-of-12">
        <span className="slds-size_11-of-12 slds-m-left_x-small">
          <span className="slds-size_11-of-12 slds-m-left_x-small">
            <section className="slds-align_absolute-center slds-p-top_small">
              <div className="slds-box slds-grid" style={{ width: "10rem" }}>
                <input type="file" multiple disabled className="cq-input-file" />
                <IconSettings iconPath="/assets/icons">
                  <Icon
                    className="cq-footer-icon"
                    assistiveText={{ label: strings.uploadFiles }}
                    category="utility"
                    name="upload"
                    size="x-small"
                  />
                </IconSettings>
                <label
                  htmlFor="filefield"
                  className="slds-text-heading slds-size_full cq-footer-anchor"
                >
                  {strings.uploadFiles}
                </label>
              </div>
            </section>
            <div className="slds-align_absolute-center slds-m-top_x-small">
              <label className="slds-text-heading_small">
                {strings.attachOrDrop}
              </label>
            </div>
          </span>
        </span>
        <span
          className="slds-size_1-of-12 slds-text-align_center"
          onClick={props.handleClick}
        >
          <Icon
            assistiveText={{ label: strings.deleteIcon }}
            category="utility"
            colorVariant="default"
            name="delete"
            size="medium"
          />
        </span>
      </div>
    </div>
  );
}
