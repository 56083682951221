import React, {useEffect, useState} from "react";
import Combobox from "@salesforce/design-system-react/components/combobox";
import comboboxFilterAndLimit from "@salesforce/design-system-react/components/combobox/filter";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import localizationString from 'localizations/formScreen';

const CQCombobox = ({
  options,
  disabled,
  selectedOption,
  handleSelectedOptionChange,
  handleSelectedOptionSelection,
  handleRemoveSelection,
  validateAndShowError,
  isEdited,
  error
}: any) => {

  const [inputValue, setInputValue] = useState(selectedOption.inputValue);

  useEffect(() => {
    setInputValue(selectedOption.inputValue);
  },[selectedOption])

  /**
   * This variable holds all options for combobox
   */
  const optionsWithIcon =
    JSON.stringify(options.selection).includes("backgroundColor") ||
    JSON.stringify(options.selection).includes("textColor")
      ? options.selection.map((elem) => ({
          ...elem,
          ...{
            icon: (
              (elem.backgroundColor && elem.backgroundColor !== "") && (elem.textColor &&  elem.textColor!== "") ?
              (<span
                className="slds-button"
                style={{
                  backgroundColor: elem.backgroundColor,
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              ></span>
            ) : <></>
            ),
          },
        }))
      : options.selection;

  /**
   * This variable updates the selection with icon on relaunching the form
   */ 
  const selection =
    selectedOption.selection.length > 0
      ? selectedOption.selection.map((elem) => ({
          ...elem,
          ...{
            icon: (
              (elem.backgroundColor && elem.backgroundColor !== "") && (elem.textColor &&  elem.textColor!== "") ?
              (
              <span
                className="slds-button"
                style={{
                  backgroundColor: elem.backgroundColor,
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              ></span>): <></>
            ),
          },
        }))
      : [];
  
    useEffect(() => {
      //This method is used to set the background color for the selected button on print preview
      const printHandler = () => {
        const buttons = document.querySelectorAll('.slds-button');
        buttons.forEach((button: Element) => {
          const btn = button as HTMLElement;
          btn.style.setProperty('--button-bg-color', btn.style.backgroundColor);
          btn.style.setProperty('--button-text-color', btn.style.color);
        });
      };
      window.addEventListener('beforeprint', printHandler);
    
      return () => {
        window.removeEventListener('beforeprint', printHandler);
      };
    }, []);

  return (
    <IconSettings iconPath="/assets/icons">
      <Combobox
        menuPosition="overflowBoundaryElement"
        id="combobox-inline-single"
        events={{
          onChange: (event, { value }) => {
            if (!disabled) {
              handleSelectedOptionChange(value);
              validateAndShowError(value, optionsWithIcon);
            }
          },
          onRequestRemoveSelectedOption: (event, data) => {
            if (!disabled) {
              handleRemoveSelection(data);
            }
          },
          onSelect: (event, data) => {
            if (!disabled) {
              handleSelectedOptionSelection(data, optionsWithIcon);
            }
          },
        }}
        labels={{
          placeholder: localizationString.comboboxPlaceHolderMessage,
        }}
        menuItemVisibleLength={5}
        options={comboboxFilterAndLimit({
          inputValue: inputValue,
          options: optionsWithIcon,
          limit: optionsWithIcon.length,
          selection: [selection],
        })}
        selection={selection}
        value={inputValue}
        errorText={error}
        singleInputDisabled = {disabled ? true: false}
        variant="inline-listbox"
        className={ disabled ? 'cq-combobox-disabled' : isEdited ? "cq-input-edit-combobox cq-combobox" : "cq-combobox"}
        classNameMenu="cq-combobox-option"
      />
    </IconSettings>
  );
};

export default CQCombobox;
