import React from 'react';
import GlobalHeader from '@salesforce/design-system-react/components/global-header'; 
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import SLDSGlobalHeaderHelp from '@salesforce/design-system-react/components/global-header/help';
import SLDSGlobalHeaderNotifications from '@salesforce/design-system-react/components/global-header/notifications';
import SLDSGlobalHeaderProfile from '@salesforce/design-system-react/components/global-header/profile';
import SLDSGlobalHeaderButton from '@salesforce/design-system-react/components/global-header/button';
import SLDSPopover from '@salesforce/design-system-react/components/popover';
import SLDSAvatar from '@salesforce/design-system-react/components/avatar';
import Icon from '@salesforce/design-system-react/components/icon'; 
import strings from '../../localizations/homeScreen';
import {Link} from 'react-router-dom';
import CQHeaderProfileContent from './CQHeaderProfileContent';

const GlobalHeaderHelp : any = SLDSGlobalHeaderHelp;
const GlobalHeaderNotifications : any = SLDSGlobalHeaderNotifications;
const GlobalHeaderProfile : any = SLDSGlobalHeaderProfile;
const Popover : any = SLDSPopover;
const Avatar : any = SLDSAvatar;

function CQGlobalHeader() {

    const userDetailsStr = localStorage.getItem('userDetails');
    var userDetails : any;
    if(userDetailsStr !== null && userDetailsStr !== "undefined"){
        userDetails = JSON.parse(userDetailsStr);
    }
    
    return (<>
        <IconSettings iconPath="/assets/icons">
          <GlobalHeader
            logoSrc="/assets/images/logo.png"
            onSkipToContent={() => {
              console.log('>>> Skip to Content Clicked');
            }}
            onSkipToNav={() => {
              console.log('>>> Skip to Nav Clicked');
            }}
          >
          {/* <GlobalHeaderSearch
              combobox={
                  <Combobox
                      assistiveText={{ label: strings.search }}
                      events={{
                          onSelect: () => {
                              console.log('>>> onSelect');
                          },
                      }}
                      id="header-search-custom-id"
                      labels={{ placeholder: strings.search }}
                      options={[]}
                  />
              }
          /> */}
          {/* <GlobalHeaderFavorites
              actionSelected={true}
              onToggleActionSelected={(event, data) => {
                  //
              }}
              popover={
                  <Popover
                      ariaLabelledby="favorites-heading"
                      body={
                          <div>
                              <h2
                                  className="slds-text-heading_small"
                                  id="favorites-heading"
                              >
                                  Favorites
                              </h2>
                              Ipsum
                          </div>
                      }
                      id="header-favorites-popover-id"
                  />
              }
          /> */}
          {/* <GlobalHeaderTask
              dropdown={
                  <Dropdown
                      id="header-task-dropdown-id"
                      options={[
                          { id: 'taskOptionOne', label: 'Task Option One' },
                          { id: 'taskOptionTwo', label: 'Task Option Two' },
                      ]}
                  />
              }
          /> */}
          <GlobalHeaderHelp
              popover={
                  <Popover
                      ariaLabelledby="help-heading"
                      body={
                          <></>
                      }
                      id="header-help-popover-id"
                  />
              }
          />
          {/* <GlobalHeaderSetup
              dropdown={
                  <Dropdown
                      id="header-setup-dropdown-id"
                      options={[
                          { id: 'setupOptionOne', label: 'Setup Option One' },
                          { id: 'setupOptionTwo', label: 'Setup Option Two' },
                      ]}
                  />
              }
          /> */}
          <GlobalHeaderNotifications
              notificationCount={0}
              popover={
                  <Popover
                      ariaLabelledby="header-notifications-custom-popover-content"
                      body={
                          <></>
                      }
                      id="header-notifications-popover-id"
                  />
              }
          />
            {userDetails != null ? 
                <GlobalHeaderProfile
                    avatar={
                        <Avatar
                          variant="user"
                          label={userDetails.Name}
                          size="small"
                        />}
                    popover={
                        <Popover
                            heading=''
                            body={<CQHeaderProfileContent userDetails={userDetails} />}
                            id="header-profile-popover-id"
                        />
                    }
                />:
                <SLDSGlobalHeaderButton>
                    <Link to="/login">
                    <Icon
                                assistiveText={{ label: strings.offline }}
                                category="utility"
                                name="offline"
                                size="small"
                            />
                    </Link>
                </SLDSGlobalHeaderButton>
            }

          </GlobalHeader>
        </IconSettings>
    
    </>);
}

export default CQGlobalHeader;
