import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { getGuideImage } from '../../api/imagedbapi';
import ModalImage from "react-modal-image";


const useStyles = makeStyles((theme) => ({
  img: {
    width: 'auto',
    height: '200px',
    display: 'inline-block',
    backgroundSize: '50%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
  }
}));


const CQGuide = (props: any) => {
  const classes = useStyles();

  const { uischema, data } = props;
  const [guideImg, setGuideImg] = useState('');
  const [guideText, setGuideText] = useState('');


  /**
   * This method return boolean based on operator and operand
   * @param sectionOperand 
   * @param guideOperand 
   * @param operator 
   * @returns boolean
   */
  const checkSectionAndGuide = (sectionOperand, guideOperand, operator) => {
      switch(operator){
        case 'eq':
          if (sectionOperand === guideOperand){
            return true;
          }
          break;
        case 'neq':
          if (sectionOperand !== guideOperand){
            return true;
          }
          break;
        default:
          return false;
      }
      return false;
  }

  /**
   * This method filter guide image and text according to section
   */
  const setGuideProp = () => {
    try{
    uischema.options.forEach(async (item) => {
      let sectionProps = item.field.split('.');
      let field =  data[sectionProps[0]][sectionProps[1]];
      if(field === undefined){
        return;
      }
      let status = checkSectionAndGuide(field, item.value, item.operator)
      if(status){
        if(!window.navigator.onLine){
          let cachedImage = await getGuideImage(item.imageURL)
          setGuideImg(cachedImage);
        }else{
          setGuideImg(item.imageURL)
        }
        setGuideText(item.text)
      }
    })
    }catch(error){
      console.error(error);
    }
  }

  useEffect(() => {
    setGuideProp();
  },[])

  return (
    <React.Fragment>
      {guideImg && guideText &&
      <section className="slds-card slds-card__body">
        <h1 className="slds-text-heading_small slds-p-top--large slds-p-around--medium">{guideText}</h1>
        <div className="slds-align_absolute-center slds-p-around--medium">
          <ModalImage
            className={classes.img}
            small={guideImg}
            large={guideImg}
            alt={guideText}
            hideDownload={true}
          />
        </div>
      </section>
      }
    </React.Fragment>
  )
}


export default withJsonFormsControlProps(CQGuide);