import React from "react";
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';

const CQPicklist = (props) => {

 /**
  * @description This method is used to select the tab and navigate to the selected tab table
  * @param evt 
  */
 const handleSelect = (evt) => {
    props.setSelectedTab(evt.value);
 }

  return (
    <>
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Dropdown
                iconCategory="utility"
                iconName="down"
                iconPosition="right"
                iconSize="medium"
                label={props.defaultTab}
                value={props.defaultTab}
                options={props.tabs}
                onSelect={handleSelect}
                width="large"
                style={{display:'flex', justifyContent: 'space-between', width:"100%", padding:'0px 10px'}}
                menuPosition="relative"
                menuStyle={{minWidth: '100%'}}
            />
        </div>
    </>
  );
};

export default CQPicklist
