import { getOfflineForms } from '../api/submissiondbapi'

export const getCachedDefinition = async (formDefId: string) => {
    let forms : any [] = await getOfflineForms();
    let form = null;

    forms.forEach((offlineForm) => {
        if(offlineForm.Id === formDefId) {
            form = offlineForm.formDef;
        }
    });

    return form;
}