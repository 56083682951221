import React from "react";
import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import { LayoutProps} from "@jsonforms/core";

const fieldsSection = (props: LayoutProps) => {
    const layoutUISchema : any = props.uischema; //TODO: figure out a better way to get elements
    console.log('Fields path is', props.path);
    return (<>
        {layoutUISchema.elements.map((child, index) =>
            <div key={`${props.path}-${index}`}>
                <JsonFormsDispatch
                    uischema={child}
                    schema={props.schema}
                    path={props.path}
                    enabled={props.enabled}
                    renderers={props.renderers}
                    cells={props.cells}
                />
            </div>
        )} 
        </>);
}


export default withJsonFormsLayoutProps(fieldsSection);