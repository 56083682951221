import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter } from 'react-router-dom';
import {renderRoutes} from 'react-router-config';
import routes from './route-config';
import AccessManagerContext from './context/AccessManagerContext';
import { AccessManager } from './api/AccessManager';
import CQFileUploaderContext from './context/CQFileUploaderContext';
import { CQFileUploadManager } from './api/CQFileUploadManager';
import CQSubmissionContext from './context/CQSubmissionContext';
import { CQSubmissionManager } from './api/CQSubmissionManager';


const accessManager = new AccessManager();
const submissionManager = new CQSubmissionManager(true, accessManager);
const fileUploader = new CQFileUploadManager([], true, submissionManager, accessManager);


ReactDOM.render(
  <React.StrictMode>
     <BrowserRouter>
    <AccessManagerContext.Provider value={accessManager}>
      <CQSubmissionContext.Provider value={submissionManager}>
        <CQFileUploaderContext.Provider value={fileUploader}>
        {renderRoutes(routes)}
        </CQFileUploaderContext.Provider> 
      </CQSubmissionContext.Provider>
    </AccessManagerContext.Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
