import React, { useState } from 'react';
import Icon from '@salesforce/design-system-react/components/icon';
import CQSectionHeader from 'components/CQSectionHeader';
import { Draggable } from 'react-beautiful-dnd';
import formBuilderScreenStrings from 'localizations/formBuilderScreen';


/*
 * Component used to render sections and its content in right panel of Form Builder
 */
function CQFormBuilderSFSection(props) {

    const [isToggle, setIsToggle] = useState<Boolean[]>([true, true, false]);


    /**
     * This method sets isToggle state after onClick action
     * @param i : Index of section
     */
    const toggle = (i) => {
        let tempIndex : Boolean[] = [];
        tempIndex = [...isToggle]
        tempIndex[i] = !isToggle[i]
        setIsToggle(tempIndex)
    }

    // styles used for section in right panel
    const sectionContentStyle = {
        border: '1px solid #c7c4c4'
    }


    return(
        <>
            <section id={'cq_section' + props.idx} className={"slds-accordion__section cq-accordion" + (isToggle[props.idx] === false ? " slds-is-open" : "")}>
                <CQSectionHeader title={props.sectionName} toggle={toggle} index={props.idx} isToggle={isToggle[props.idx]} isFormBuilder={false}/>
                <div className="slds-accordion__content">
                    <div style={sectionContentStyle}>
                        <ul className="slds-p-around_medium">
                            {
                                props.isLoaded ?
                                props.items.map((child, index) => {
                                    return(
                                        <Draggable key={child.id} draggableId={child.id} index={index} isDragDisabled={child.isDragged}> 
                                            {(provided, snapshot) => (
                                                <div className="slds-p-around_xx-small slds-text-heading_small" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                >
                                                    <div className="slds-grid slds-gutters slds-box slds-box_x-small slds-is-relative"
                                                        style={!child.isDragged ? {
                                                            backgroundColor: snapshot.isDragging ? 'white' : 'inherit'
                                                        }:
                                                        {
                                                            backgroundColor: '#cccccc',
                                                            color: '#666666'
                                                        }
                                                        }>
                                                        <div className="slds-size_1-of-12 slds-is-absolute"  >
                                                                <Icon
                                                                    assistiveText={{ label: formBuilderScreenStrings.dragLabel }}
                                                                    category="utility"
                                                                    colorVariant="default"
                                                                    name="drag_and_drop"
                                                                    size="x-small"
                                                                />
                                                        </div>
                                                        <div className="slds-size_11-of-12 slds-m-left_large" data-id={child.id} data-value={child.value}>
                                                        {child.isReferenceField ? `${child.contextObject} > ${child.label}`: child.label}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })
                                :''
                               
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CQFormBuilderSFSection;