import React, {useEffect , useState} from 'react';

import ProgressBar from '@salesforce/design-system-react/components/progress-bar';
import strings from '../../localizations/homeScreen';

export default function CQProgressBar(props) {

    const [left, setLeft] = useState("0%");
    useEffect(() => {

        if (props.indeterminate) {
            const interval = setInterval(() => {
                let leftValue = parseInt(left, 10);
                leftValue = leftValue + 5;
                if(leftValue >= 95)
                    setLeft("0%");
                else{
                    setLeft(leftValue + "%");
                }
            }, 50);

            return () => clearInterval(interval);
        }
      }, [left, props]);
    
    return (
        <>
        {props.indeterminate?
            <div>
                <div id="indeterminate-progress-bar" aria-valuemin={0} aria-valuemax={100} aria-valuetext={strings.starting} role="progressbar"
                     className="slds-progress-bar slds-progress-bar_small">
                        <span className="slds-progress-bar__value" style={{width: '10%', marginLeft: left }}></span>
                </div>
            </div>:
            props.progress !== -1 && props.progress !== 100?
            <div>
                <ProgressBar
                id="setup-progress-bar"
                value={props.progress}
                thickness="small"
                />
            </div>:''
        }
        </>
    )
}
