import React, {useContext} from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import ChildContext from '../context/CQChildDataContext';

const CQLabelControl = ({data, handleChange, path, required, visible, label, uischema, errors, schema, enabled}: any) => {
  
  const childData = useContext(ChildContext);

  const getValue = () => {
    if(uischema.formula) {
      let exprString = uischema.formula.match(/^#=(.*)=#$/)[1];

      let expr = new Function('$record', 'return ' + exprString);
      let retVal = null;
      try {
        retVal = expr.call(null, data); 
      } catch(ex) {
        console.error(ex);
      }
      return retVal || '';
    } else {
      // check if incoming data is an object
      if (typeof data === "object") {
        return renderChildData(uischema);
      }  
      return data || uischema.value || '';
    }
  }

  const renderChildData = (uischema: any) => {
    // check if scope contains context in it 
    if (uischema.scope.includes("parent")) {
      let keys = Object.keys(childData); // get all checklist names in an array with type conversion
      let matchingField = uischema.scope.split("/").pop(); // get the last element in scope
      let matchingData:any = "";
  
      keys.forEach((key) => {
        // check if checklist name is included in data or not
        if (JSON.stringify(data).includes(key)) {
          matchingData = childData[key][matchingField]; //gets the value of parent and child sharing field
          return matchingData;
        }
      });
  
      return matchingData;
    }
  }

    const getClassNames = () => {
      if(uischema && uischema.options) {
        switch(uischema.options.style) {
          case "bold": return "slds-text-heading_small slds-text-title_bold cq-text";
          case "badge": return "cq-text-badge";
          case "medium": return "slds-text-heading_medium slds-text-title_bold cq-text";
        }
        
      }

      return "slds-text-heading_small";
    }
  return (
  <>
  {visible?
   <div className={getClassNames()}>{getValue()}</div>:''}
  </>
  );
};

export default withJsonFormsControlProps(CQLabelControl);