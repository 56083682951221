import React from 'react';
import CQInProgressTab from '../CQInProgressTab';

export default function CQSubmittedList(props) {
    return (
      <React.Fragment>
        {Object.keys(props!.item).length > 0 ?
        <React.Fragment>
        <div className="slds-box slds-grid slds-text-body_regular slds-m-horizontal_small slds-m-bottom_small">
          <div className="slds-col slds-grid slds-size_12-of-12 slds-wrap slds-p-vertical_xxx-small">
            <CQInProgressTab item={props.item} totalItems={4} />
          </div>
        </div>
        </React.Fragment>: ""}
      </React.Fragment>
    )
}