/**
 * Adjust the height of textareas to fit their content
 */
export const adjustTextareaHeights = (evt: any) => {
    let textarea = evt.target;
    let data = evt.target.value;
    const lineHeight = data ? (data.length) / 16 : 0;
    if (textarea.value === '') {
       // Set the height of textarea to be initial if there is no content on textarea
       textarea.style.height = `auto`;
    } else if (textarea instanceof HTMLTextAreaElement && lineHeight) {
       // Calculate the increase height and set the textarea height according to increase in height
       const newHeight = Math.max(textarea.scrollHeight, lineHeight);
       textarea.style.height = `initial`;
       // Only increase the height if the scroll height is greater than the client height
       if (textarea.scrollHeight > textarea.clientHeight) {
         textarea.style.height = `${newHeight}px`;
       }
    }
};