export default class UserContext {
    public url : String = '';
    public token : String = '';
    public organizationId : String = '';
    public userDetails : String = '';

    public isAuthorized() : boolean {

        return (this.url && this.url.length > 0 ) && 
                (this.token && this.token.length > 0 ) && 
                (this.organizationId && this.organizationId.length > 0 );
    }
}