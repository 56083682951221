/*
 * Filename: SfConnector.ts
 * Description:Connects our app to salesforce
 */

import jsforce from 'jsforce';
import IAccessManager from 'api/IAccessManager';
import StubAccessManager from 'api/StubAccessManager';
import { IOAuthRequestOptions } from 'interfaces/IApp.interface';

export class SfConnector {
  private readonly jsForce: any;
  private jsForceConnection: any;

  private accessManager: IAccessManager = new StubAccessManager();

  constructor() {
    this.jsForce = jsforce;
  }

  public setAccessManager(accessManager: IAccessManager) : SfConnector {
    this.accessManager = accessManager;

    return this;
  }

  public login = async (oAuthRequestBody: IOAuthRequestOptions) => {
    return new Promise((resolve, reject) => {
      this.jsForce.browser.init({
        loginUrl: oAuthRequestBody.SF_OAUTH_LOGIN_URL,
        clientId: oAuthRequestBody.SF_CLIENT_ID,
        clientSecret: oAuthRequestBody.SF_CLIENT_SECRET,
        redirectUri: oAuthRequestBody.SF_REDIRECT_URL,
      });
      this.jsForce.browser.login({
        prompt: 'login consent',
      });
      return this.jsForce.browser.on('connect', (connection: jsforce.Connection) => {
        this.jsForceConnection = connection;
        if (connection) {
          localStorage.clear();
          resolve(connection);
        } else {
          reject('Unable to connect to SF');
        }
        localStorage.clear();
      });
    });
  };

  logOut = async () =>  {
    return new Promise(async (resolve, reject) => {
      if(this.jsForce.browser.connection){
        this.jsForce.browser.connection.logout(false)
        .then(()=>{
          this.jsForce.browser.on('disconnect', async (connection) => {
            this.jsForceConnection = null;
            await this.accessManager.clearAuthorization();
            resolve(null);
          });
        }).catch(async (e)=>{
          this.jsForceConnection = null;
          await this.accessManager.clearAuthorization();
          resolve(e);
        })
      }else{
        this.jsForceConnection = null;
        await this.accessManager.clearAuthorization();
        reject(null);
      }
        
    });
  }
}
