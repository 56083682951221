/*
 * Filename: response-type.ts
 * Description: CQ response type class.
 */

import { IErrorObject } from './IHttpApiService';

export class CQResponseType<T> {
    public value?: T;
    public error?: IErrorObject | string;

    private constructor(val: T, err: IErrorObject | string) {
        this.value = val;
        this.error = err;
    }

    public static onValidResponse<A>(val: A): CQResponseType<A> {
        return new CQResponseType(val, '');
    }
    public static onError<A>(err: IErrorObject | string): CQResponseType<A> {
        return new CQResponseType(undefined, err) as A;
    }
}
