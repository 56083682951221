import React, {useContext} from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Labels } from '@jsonforms/core/lib/util/renderer';
import AccessManagerContext from '../context/AccessManagerContext';
import strings from '../localizations/homeScreen';
import SFAPI from '../api/sfapi';
import IAccessManager from '../api/IAccessManager';
import { isSalesforceBuild } from '../salesforceBuild';
import CQSubmissionContext  from '../context/CQSubmissionContext'; 
import { setEditedValueinOption, getEditedValueFromOption } from 'services/data-changeindicator.service';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import CQToast from "components/CQToast/CQToast";
import { CQApiConstant } from "api/api-constants";
import { InputAdornment } from '@material-ui/core';
import Icon from '@salesforce/design-system-react/components/icon';

interface CQLookupProps {
    data: any;
    path: string;
    handleChange(path: string, value: any): void;
    required?: boolean;
    visible?: boolean;
    label: string | Labels;
    uischema: any;
    errors: any;
    enabled: boolean;
    schema:any
}

const CQLookupControl = ({data, handleChange, path, required, visible, label, uischema, errors, enabled, schema}: CQLookupProps) => {

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState('');
  const loading = open && options.length === 0;
  const [defaultValue, setDefaultValue] = React.useState(true);
  const accessManager : IAccessManager = useContext(AccessManagerContext);
  const sfAPI = new SFAPI().setAccessManager(accessManager);
  const submissionContext = useContext(CQSubmissionContext);
  const [validationError, setValidationError] = React.useState(false);
  const timeCount = React.useRef(3000);
  const [showToast, setShowToast] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  
  /**
   * Compares and returns correct value
   * @returns data or default value
   */
  const getData = () => {
    if(!data && schema.hasOwnProperty('default') && defaultValue){
      if(schema?.default.length){
        return schema.default;
      }
    }
    return data;
  }
  //check whether the default data match the validation pattern
  React.useEffect(() => {
    if(!data && schema.hasOwnProperty('default')){
      if(schema?.default.length){
        handleInputChange({ "target" : { "value" : schema.default}});
      }
    }
  },[schema])

  /**
   * This method helps to check if title of the schema is same of ui schema options title to work for field with more than one lookup object
   * @returns label of field 
   */
  const getLabel = () => {
    if(schema.hasOwnProperty('title') && uischema.hasOwnProperty('options')) {
      if (uischema.options.hasOwnProperty('title')) {
        if(schema['title'] !== uischema.options.title) {
          return uischema.options.title;
        }
      }
    }

    return label;
  }

  const updateOptions = async (active) => {
    const lookupOptions = await sfAPI.findRecords(searchText, uischema.lookupTo || 'Account', null, null, null);

    if (active) {
      setOptions(lookupOptions);
    }
  }

  const handleInputChange=(evt : any) => {
    evt.target.value === '' ? setDefaultValue(false) : setDefaultValue(true);
    if(!(evt.target.value === "")){
      validationCheck(evt.target.value);
    }
    handleChange(path, evt.target.value);
    handleChange(path + '_Id', null);
    setSearchText(evt.target.value);
    updateOptions(true);
    uischema = setEditedValueinOption(evt.target.value, submissionContext.submission, path, uischema);
  }

  const handleClick = () => {
    const errorMessage = schema.errorMessage;
    setErrorMessage(errorMessage);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, timeCount.current)
  }
  
  /**
   * This method check whether the value matches the validition or not
   * @param value
   */
  const validationCheck =(value) => {
    data = value;
    const regexPattern = schema.pattern;
    const regex = new RegExp(regexPattern);
    const isValid = regex.test(value);
    if(!isValid){
      setValidationError(true);
    }else{
      setValidationError(false);
    }
  }

  React.useEffect(() => {
    if(data === undefined){
      setValidationError(false);
    }else{
      validationCheck(data);
    }
  },[])

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    updateOptions(active);

    return () => {
      active = false;
    };
  }, [loading, uischema, setSearchText]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
    {visible?
    <IconSettings iconPath="/assets/icons">
      <div>
        <div className="slds-form-element">
          {required ? <abbr className="slds-required" title={strings.required}>* </abbr>: <></>}
          <label className="slds-form-element__label">{getLabel()}</label>
        </div>
        <Autocomplete
          className={ !isSalesforceBuild() && getEditedValueFromOption(uischema,data,path,submissionContext.submission) ? 'cq-input-edit' : ''} 
          disabled={!enabled || isSalesforceBuild()}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(evt : any, value : any) => {
            handleChange(path, value.Name);
            handleChange(path + '_Id', value.Id);
            uischema = setEditedValueinOption(value.Name, submissionContext.submission, path, uischema); 
          }}
          value = {getData()}
          freeSolo
          disableClearable
          getOptionSelected={(option : any, value : any) => option.Name === value.Name}
          getOptionLabel={(option : any) => option.Name || option}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(evt : any) => {
                handleInputChange(evt)
              }}
              className={ !isSalesforceBuild() && getEditedValueFromOption(uischema,data,path,submissionContext.submission) ? 'cq-input-edit' : ''} 
              
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    {validationError ? 
                      <InputAdornment position='start'>
                        <span onClick={handleClick} style={{cursor: "pointer"}} className="slds-p-left_small">
                          <Icon
                            assistiveText={{ label: 'Warning' }}
                            category="utility"
                            colorVariant="warning"
                            size="x-small"
                            name="warning"
                          />
                        </span>
                      </InputAdornment>: ""
                    }
                  </React.Fragment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              style={{border: validationError ? '2px solid red' : ''}}
            />
          )}
          />
          {
            showToast && validationError ? 
            <CQToast
              className = "slds-m-top_xx-large"
              duration={CQApiConstant.TOAST_TIMER}
              variant='warning'
              heading={errorMessage}
            />:null
          }
        {errors !== null ? <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-filled">{errors}</p> : ''}
      </div>
    </IconSettings>
    :''}
      </>
  );
};

export default withJsonFormsControlProps(CQLookupControl);