import { CQApiConstant } from './api-constants';
import { uuidv4 } from '../services/cqIdGenerator';
import { 
    deleteSubmissionDBItem, 
    storeSubmissionInDB, 
    updateSubmissionDBItem,
    getSubmissionsInDB,
    getSubmissionFromId, isIndexAvailable, getInProgressSubmissions } from './submissiondbapi';
import {getFilesItemInDB } from './imagedbapi';
import { CQFileUploadManager } from './CQFileUploadManager';
import { getCachedDefinition } from '../actions/offlineFormDefinitions';
import IAccessManager from './IAccessManager';
import StubAccessManager from './StubAccessManager';
import SFAPI from './sfapi';
import { getNameSpaceField } from './namespace';
import { CQAutoFillup } from './CQAutoFillup';
import { mapDataToSubmission, removeEmptyTableRecords } from 'services/data-mapping.service';



//constants
const NOT_STARTED : number = -1;
const PAUSED : number = -1;
const SUBMIT_FORM_ACTIVITY_CODE : string = 'submitForm';

/*
 * class that holds methods for syncing submission
 */
export class CQSubmissionManager{
    
    submissionSyncIntervalId : number = NOT_STARTED;
    formSubmitted : boolean = false;
    submissionId: string = '';
    uploadingSubmission: string = '';
    isSubmissionUploading : Boolean = false;
    accessManager: IAccessManager = new StubAccessManager();
    sfAPI : SFAPI;
    cqAutoFillup : CQAutoFillup;
    submission : any = null;
    updatedHeaderSyncedDate : string = '';

    /*
     * constructor 
     */
    constructor(startImmediately : boolean = false, accessManager ?: IAccessManager){
        

        this.sfAPI = new SFAPI();
        this.cqAutoFillup = new CQAutoFillup();
        if(accessManager !== undefined) {
            this.setAccessManager(accessManager as IAccessManager);
            this.cqAutoFillup.setAccessManager(accessManager  as IAccessManager);
        }

        if(startImmediately === true) {
            this.start();

            /* 
            * added event when network status changed to online
            * this event will start uploading the unsynced submission from fileInfos when online
            * when network is online, submission syncing should process so this listener proceeds sync again
            */
            window.addEventListener('online', (e) => { 
                console.log('online');
                if(this.submissionSyncIntervalId !== NOT_STARTED || this.submissionSyncIntervalId === PAUSED) {
                    this.start();
                    window.location.reload();
                }
            });

            /*
            * addd when network status changed to offline
            * this event will stop submission sync process in background when network changes to offline
            * when network is offline,submission sync should pause so this listener stops syncing submission
            */
            window.addEventListener('offline', (e) => {
                console.log('oflline');
                if(this.submissionSyncIntervalId !== PAUSED && this.submissionSyncIntervalId !== NOT_STARTED) {
                    this.stop();
                }
           });      
        }
    }

    public setAccessManager(accessManager : IAccessManager) : CQSubmissionManager {
        this.accessManager = accessManager;
        this.sfAPI.setAccessManager(accessManager);

        return this;
    }

    /**
     * This method gets schema and update DB
     * @param syncSubmissionList : list of submission to be sync
     */
    async addsubmissions(syncSubmissionList){
        syncSubmissionList.forEach(async element => {
            let data = await this.sfAPI.syncPendingSubmissions(element.Id);
            let isSubmissionAAvailable = await isIndexAvailable(data.id);
            if(data.LastModifiedDate !== undefined || data.LastModifiedDate !== undefined){
                data.syncedOn = data.LastModifiedDate;
            }
            if(data.hasOwnProperty('status')){
                data.status = data.status === CQApiConstant.SUBMISSION.STATUS_SUBMITTED ? CQApiConstant.SUBMISSION.STATUS_IN_PROGRESS : data.status;
            }
            isSubmissionAAvailable ? await this.updateSubmission(data.id, data) : await this.storeSubmissionToDB(data);
        });
    }

    /**
     * @description This method deletes specific submission from db
     * @param id
     */
    async deleteSubmissionFromDB(id) {
        await deleteSubmissionDBItem(id);
    }

    /**
     * this method deletes the submission which are not synced
     */
    async deleteUnSyncSubmission(){
        if(window.navigator.onLine && (!this.isSubmissionUploading && !this.uploadingSubmission.length)){
            let submissionIdsInPendingSubmissionList : any[] = [];
            let pendingSubmissions: any[] = await this.sfAPI.findPendingSubmissions() || [];
            let localPendingSubmissions: any[] = await this.getSubmissionsFromDB();
            if(pendingSubmissions && localPendingSubmissions && pendingSubmissions.length && localPendingSubmissions.length){
                for(let i= 0; i < pendingSubmissions.length ; i++){
                    let submissionId : string = this.getSubmissionIdFromField(pendingSubmissions[i]);
                    submissionIdsInPendingSubmissionList.push(submissionId);
                }
                localPendingSubmissions.forEach(async (item) => {
                    if (item.id) {
                        // check the form submission heroku id in SF pending submissions heroku id list
                        if(!submissionIdsInPendingSubmissionList.includes(item.id) && item.status == CQApiConstant.SUBMISSION.STATUS_IN_PROGRESS && item.syncStatus == CQApiConstant.SUBMISSION.SYNC_STATUS_IN_SYNC && (!item.hasOwnProperty('isSalesforceRecord') || item.isSalesforceRecord)){
                            await deleteSubmissionDBItem(item.id);
                            await CQFileUploadManager.deleteImagesFromDB(item.id);
                        }
                    }
                });
            }
        }
    }

    /**
     * description this method returns fromName from submission
     * @param submission 
     * @returns formName
     */
    getFormName(submission) {
        let formName:any;

        const data:object = submission.data;
        if (Object.values(data).length !== 0) {
            formName = Object.values(data)[0]['Name'];
        }

        return formName;
    }

    /**
     * This method retrieve Pending Submission
     */
    async retrievePendingSubmissionFromOrg(){
        let Id: string[] = [];
        let syncSubmissionList: any[] = [];

        try {
            let pendingSubmissions: any[] = await this.sfAPI.findPendingSubmissions() || [];
            let localPendingSubmissions: any[] = await this.getSubmissionsFromDB();
            localPendingSubmissions.forEach((item) => {
                if (item.id) {
                    Id.push(item.id);
                }
            });
            for(let i= 0; i < pendingSubmissions.length ; i++){
                let submissionId : string = this.getSubmissionIdFromField(pendingSubmissions[i]);
                let isSubmissionAAvailable = await isIndexAvailable(submissionId);
                if(getNameSpaceField(pendingSubmissions[i], 'Main_Record_Id__c') != null && !isSubmissionAAvailable){
                    let instanceUrl = localStorage.getItem('instanceurl') || '';
                    let formDef = await this.cqAutoFillup.updateContentAccordingFormSubmissionRecordId(pendingSubmissions[i]);
                    if(formDef){
                        const submission = await mapDataToSubmission({data: formDef.data || {}, formDef});
                        if(getNameSpaceField(pendingSubmissions[i], 'SQX_Controlled_Document__c')){
                            await this.addSubmission(getNameSpaceField(pendingSubmissions[i], 'SQX_Controlled_Document__c'), submission['formDef'], getNameSpaceField(pendingSubmissions[i], 'SQX_Controlled_Document__r').Name, submissionId, instanceUrl, pendingSubmissions[i].LastModifiedDate, true);
                        }else{
                            await this.addSubmission(pendingSubmissions[i].Id, submission['formDef'], this.getFormName(submission), submissionId, instanceUrl, pendingSubmissions[i].LastModifiedDate, true);
                        }
                    }
                 }else{
                     let unique: string =  this.getSubmissionIdFromField(pendingSubmissions[i]);
                     if (!Id.includes(unique)) {
                         syncSubmissionList.push(pendingSubmissions[i])
                     }
                 }                
            }
            
            if (syncSubmissionList) {
                this.addsubmissions(syncSubmissionList);
            }
        }catch(e){

        }
    }
    
    /*
     * method for uploading unsynced submission from DB 
     */
    async uploadUnsyncedSubmission(){
        if(window.navigator.onLine){
            let userContext = await this.accessManager.getUserContext();
            const organizationId = userContext.organizationId;
            let pendingSubmission = await getSubmissionsInDB(organizationId);
            for(let index = 0 ; index < pendingSubmission.length; index++){
                if( pendingSubmission[index] && this.submissionId !== pendingSubmission[index] && this.uploadingSubmission !== pendingSubmission[index]){
                    this.isSubmissionUploading = true;
                    this.uploadingSubmission = pendingSubmission[index].id;
                    if(!this.isSubmissionFilesUploaded(pendingSubmission[index])){
                        await this.updateSubmissionFiles(pendingSubmission[index]);
                        return;
                    }
                    if( pendingSubmission[index].status === CQApiConstant.SUBMISSION.STATUS_SUBMITTED){
                        let data = await this.submitToSF(pendingSubmission[index]);
                        if(data && !data.url){
                            pendingSubmission[index].errors =  [{message: data[0].errorMessage || data }];
                            pendingSubmission[index].status = CQApiConstant.SUBMISSION.STATUS_IN_PROGRESS;
                            let isSubmissionAAvailable = await isIndexAvailable(pendingSubmission[index].id)
                            if(isSubmissionAAvailable){
                                await updateSubmissionDBItem(pendingSubmission[index])
                            }else{
                                await storeSubmissionInDB(pendingSubmission[index]);
                            }
                        }
                    }else if (pendingSubmission[index].status === CQApiConstant.SUBMISSION.STATUS_IN_PROGRESS && (pendingSubmission[index].syncStatus === CQApiConstant.SUBMISSION.SYNC_STATUS_OUT_OF_SYNC)){
                        await this.performSubmission(pendingSubmission[index]);
                    }
                    this.uploadingSubmission = '';
                }
            };
            this.isSubmissionUploading = false;
        }
    }
    
    /*
     * Starts the submission sync service
     * prevents uploading same submission again and again 
     */
    start() {
        console.log('Starting Submission uploader');
        if(!this.accessManager.isCanvasMode){
            this.submissionSyncIntervalId = setInterval( async () => {
                if(!this.isSubmissionUploading){
                    try{
                        this.isSubmissionUploading = true;
                        await this.uploadUnsyncedSubmission();
                        await this.deleteUnSyncSubmission();
                        let currentUserTimeZone : string = JSON.parse(String(localStorage.getItem('userDetails'))) ? JSON.parse(String(localStorage.getItem('userDetails'))).TimeZoneSidKey : '';
                        this.updatedHeaderSyncedDate = currentUserTimeZone ? new Date().toLocaleString("en-Us", {timeZone: currentUserTimeZone}).replace(/(.*)\D\d+/, '$1') : '';
                    }finally{
                        this.isSubmissionUploading = false;
                    }
                }
            }, 2000) as unknown as number;
            this.retrievePendingSubmissionFromOrg();
        }
    }

    /*
     * Stops the submission sync service
     */
    stop() {
        console.log("Stopping submission manager!!!");
        this.isSubmissionUploading = false;
        if(this.submissionSyncIntervalId !== NOT_STARTED) {
            clearInterval(this.submissionSyncIntervalId);
            this.submissionSyncIntervalId = NOT_STARTED;
        }
    }

    /*
     * method to add submission in DB 
     * @params  formId: id of the form in SF
     *          formDef: object which contains info about the form
     *          submissionId: id of submission 
     *         instanceUrl: url of the SF org
     */
    async addSubmission(formId : any, formDef: any, formName: string, submissionId : string = uuidv4(), instanceUrl, LastModifiedDate, isSalesForceRecord?:boolean){
        let userContext = await this.accessManager.getUserContext();
        const organizationId = userContext.organizationId;
        let submission : CQSubmission = new CQSubmission(formId, formDef, formName, submissionId, instanceUrl, organizationId); 
        // check if incoming submission is coming from org or not
        if(isSalesForceRecord === undefined || !isSalesForceRecord) {
            submission['isSalesforceRecord'] = false;
        }else if(isSalesForceRecord){
            submission['isSalesforceRecord'] = true;
        }
        submission.syncedOn = LastModifiedDate;
        await storeSubmissionInDB(submission);
        return submission;
    }

    /*
     * Method to store submission in DB
     */
    async storeSubmissionToDB(submission){
        await storeSubmissionInDB(submission);
    }
    
    /*
     * method which returns submissions in DB
     */
    async getSubmissionsFromDB(){
        let userContext = await this.accessManager.getUserContext();
        return await getInProgressSubmissions(userContext.organizationId);
    }

    /**
     * Method to return submission id from field
     */
    getSubmissionIdFromField(submissionData){
        let valueFromNameSpaceField = getNameSpaceField(submissionData, 'Uniqueness_Constraint__c');
        let submissionIdFromField = (valueFromNameSpaceField && valueFromNameSpaceField.includes('#')) ? valueFromNameSpaceField.split('#')[1] : '';
        return submissionIdFromField;
    }

    /*
     * method for interaction with SF by submitting the submission
     */
    async syncSubmission(submission: any, formSubmissionId : string = '', networkId?:any){
        // Prevent creating inprogress form submission for forms with no main record id
        if(!submission.hasOwnProperty('isSalesforceRecord') || submission.status === CQApiConstant.SUBMISSION.STATUS_SUBMITTED) {
            if(submission.hasOwnProperty('isSalesforceRecord')) {
                delete submission.isSalesforceRecord;
            }
            let url =`/api/data?recordId=${submission.formId}&submissionStatus=${encodeURIComponent(submission.status)}&submissionId=${encodeURIComponent(submission.id)}&version=${submission.version}&formSubmissionId=${formSubmissionId}`;
            if(networkId) {
                url += `&networkId=${networkId}`;
            }
            return await this.sfAPI.performPostRequest(url, submission);
        }
    }

    /**
     * 
     * @param submission submission for the form
     * @param formSubmissionIdentifier form submission id
     * @returns 
     */
    async saveSubmissionJsonForPdfRendering(submission : string, formSubmissionIdentifier : string){
        let url = `/api/savesubmission?formSubmissionIdentifier=${formSubmissionIdentifier}`;
        let networkId = null;
        if(localStorage.getItem('userDetailsSF')){
            const userDetails:any =  JSON.parse(localStorage.getItem('userDetailsSF') || '');
            if(userDetails['networkId'] !== null) {
                networkId = userDetails['networkId'];
            }
        }
        if(networkId){
            url += `&networkId=${networkId}`;
        }
        return await this.sfAPI.performPostRequest(url, submission);
    }

    /**
     * Method to get the submission data by file name
     * @param filename name of the file whose data is to be rendered
     * @returns 
     */
    async getSubmissionDataByFileName(filename : string){
        let url = `/api/content/${filename}`;
        return await this.sfAPI.performGetRequest(url);
    }

    /*
     * method for interaction with SF for getting scheduled submission
     */
    async getScheduledSubmissions(){
        let url =`/api/scheduledSubmissions`;
        return await this.sfAPI.performGetRequest(url);
    }

    /*
     * Method to get form def data for given form id
     */
    async getFormDefData(formId){
        let data = await getCachedDefinition(formId);
        if (!data) {
            data = await this.sfAPI.getFormDefinition(formId);
        }
        return data;
    }

    /*
     * method for start submission for assigned submission
     */
    async startSubmission(formSubmission, id){
        try{
            let data = await this.getFormDefData(getNameSpaceField(formSubmission,"SQX_Controlled_Document__c"));
            if(data != null){
                const instanceUrl = localStorage.getItem('instanceurl') || '';
                let userContext = await this.accessManager.getUserContext();
                const organizationId = userContext.organizationId;
                let submission : CQSubmission = new CQSubmission(getNameSpaceField(formSubmission,"SQX_Controlled_Document__c"), data,  formSubmission.Name, id, instanceUrl, organizationId);
                submission.version = getNameSpaceField(formSubmission,"Version__c") + 1;
                submission.status = CQApiConstant.SUBMISSION.STATUS_SCHEDULED;
                await this.syncSubmission(submission, formSubmission.Id);
                submission.status = CQApiConstant.SUBMISSION.STATUS_IN_PROGRESS;
                submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_IN_SYNC;
                await storeSubmissionInDB(submission);
            }else{
                throw Error('No Data found for form submission');
            }
        }catch(ex){
            throw ex;
        }
    }

    /*
     * method to perform sync submission in background
     */
    async performSubmission(submission: any) {
        try {
            let response = await this.syncSubmission(submission);
            if(response){
                if(submission.status === CQApiConstant.SUBMISSION.STATUS_SUBMITTED) {
                    await deleteSubmissionDBItem(submission.id);
                    await CQFileUploadManager.deleteImagesFromDB(submission.id);
                } else {
                    submission.syncStatus === CQApiConstant.SUBMISSION.SYNC_STATUS_OFFLINE_SUBMISSION_SYNC ? submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_OUT_OF_SYNC :submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_IN_SYNC;
                    delete submission.errors;
                    await updateSubmissionDBItem(submission);
                    return true;
                }
            }
        } catch (e) {
            if(e.message !== 'Network Error') {
                submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_IN_ERROR;
                submission.errors = e.response ? e.response.data : e;
                await updateSubmissionDBItem(submission);
            }
            return true;
        }
    }

    /*
     * method to check if files for given submission is uploaded or not
     */
    isSubmissionFilesUploaded(submission){
        let fileNodes: any[] = [];
        CQSubmissionManager.findFileNodes(submission.data, fileNodes);
        if(fileNodes.length > 0){
            let file = fileNodes.find(obj => obj.ContentDocumentId === '');
            if(file){
                return false;
            }
        }
        return true;
    }

    /**
     * This method synced the unsycned file in submission record on the basis of ImageDB file information 
     * @param submission : update submission with file information
     */
    async updateSubmissionFiles(submission){
        let fileNodes : any[] = [];
        CQSubmissionManager.findFileNodes(submission.data, fileNodes);
        let fileSubmissions = await getFilesItemInDB();
        let unsynedSubmission : any = fileSubmissions.filter(obj => obj.submissionId === submission.id);
        for(let index = 0 ; index < unsynedSubmission.length ; index++){
            let file = fileNodes.find((item) => item.LocalId === unsynedSubmission[index].id);
            if(file) {
                if(file.ContentDocumentId === '' && unsynedSubmission[index].ContentDocumentId !== 'Unsynced'){
                    file.ContentDocumentId = unsynedSubmission[index].ContentDocumentId;
                    file.error = '';
                    file.progress = 100;
                }
            }
        }
        this.setSubmissionSyncStatus(submission);
        await updateSubmissionDBItem(submission);
    }

    /*
     * method to set submission sync status based on file uploads
     */
    setSubmissionSyncStatus(submission){
        // Update status for submission with main record Id only
            if (this.isSubmissionFilesUploaded(submission)){
                submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_OUT_OF_SYNC;
            }else{
                submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_FILE_UNSYNC;
            }
        }

    /**
     * This method updates submission structure to create record for any object
     * @param submission 
     */
    updateSubmissionForSObject = (submission:any) => {
        // updated submission for other objects than Safety Inspection to create a record when form is submitted
        if(submission.hasOwnProperty('formDef') && Object.keys(submission.data).length) {
            let mappingObject:any = Object.keys(submission.data).find(key => key !== 'General') || undefined;
            if(!(submission.data[mappingObject] && submission.data[mappingObject].hasOwnProperty("attributes"))) {
                Object.assign(submission.data[mappingObject], {"attributes": {"type": mappingObject}});
            }
            submission['formDef']['data'] = submission.data;
        }
        if(Object.keys(submission).includes('originalData')){
            delete submission.originalData; 
        }
    }

    /**
     * This method checks if form is submitted by portal user or not and add networkId accordingly
     * @param submission 
     * @returns syncSubmission method
     */
    checkUserDetailsandSyncSubmission = (submission:any) => {
        if (localStorage.getItem('userDetailsSF')) {
            const userDetails:any =  JSON.parse(localStorage.getItem('userDetailsSF') || '');
            if(userDetails['networkId'] !== null) {
                let networkId = userDetails['networkId'];
                return this.syncSubmission(submission, '', networkId);
            }
        }
        return this.syncSubmission(submission);
    }

    /*
     * method to submit submission to SF
     */
    async submitToSF(submission){
        if(window.navigator.onLine){
            if(!this.isSubmissionFilesUploaded(submission)){
                await this.updateSubmissionFiles(submission);
            }
            submission.version++;
            submission.status = CQApiConstant.SUBMISSION.STATUS_SUBMITTED;
            submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_OUT_OF_SYNC;
            // send activity code as 'submitForm' in data
            if(submission?.data !== undefined && Object.keys(submission.data).length && submission.formDef?.cqFormType) {
                const selectedObjectForForm = submission.formDef.cqFormType;
                const selectedObjectNamespace = submission.formDef.cqFormType.includes("__") ? submission.formDef.cqFormType.split("__")[0] : '';
                if(selectedObjectNamespace){
                    const activityField = `${selectedObjectNamespace}__Activity_Code__c`;
                    let response = await this.sfAPI.getSeletectedObjectMetadata(selectedObjectForForm);
                    if(response && response.fields){
                        const formTypeFieldsList : any = response.fields;
                        const isFieldExist = formTypeFieldsList.find(field => field.name === activityField);
                        if(isFieldExist){
                            const submissionDataObjName = Object.keys(submission.data)[0];
                            if(submissionDataObjName !== 'General'){
                                submission.data[submissionDataObjName][activityField] = SUBMIT_FORM_ACTIVITY_CODE;
                            }
                        }
                    }
                }
            }else if(submission?.data !== undefined && Object.keys(submission.data).length) {
                let submissionDataObjName = Object.keys(submission.data)[0];
                let nextActionField = Object.keys(submission.formDef.nextaction)[0];
                submission.data[submissionDataObjName][nextActionField] = SUBMIT_FORM_ACTIVITY_CODE;
            }
            //perform table data triming before sending it 
            submission.data = removeEmptyTableRecords(submission);
            this.updateSubmissionForSObject(submission);
            let data:any = await this.checkUserDetailsandSyncSubmission(submission);
            if(!data) return;
            if(data && data.url){
                await deleteSubmissionDBItem(submission.id);
                await CQFileUploadManager.deleteImagesFromDB(submission.id);
            }
            return data;
        }
    }

    /*
     * method to update submission in DB with submission status 'submitted' 
     * when submit is clicked
     */
    async submitSubmission(submissionId: string, submissionData : any){
        let submission = await getSubmissionFromId(submissionId);
        
        submission.status = CQApiConstant.SUBMISSION.STATUS_SUBMITTED;
        submission.version = (submission.version + 1);
        submission.submittedOn =  new Date().toISOString();
        submission.data =  submissionData;
        this.setSubmissionSyncStatus(submission);
        
        await updateSubmissionDBItem(submission);
        return submission;
    }
    
    /*
     * method to update submission in DB with sync status and status
     * when close is clicked 
     */
    async updateSubmission(submissionId: string, submissionData : any){
        let submission =await getSubmissionFromId(submissionId);
        if(submission){
            submission.data = submissionData;
            submission.version = (submission.version + 1);
            
            this.setSubmissionSyncStatus(submission);
            await updateSubmissionDBItem(submission);
            return submission;
        }
    }

    /**
     * Method to update submission in database
     * @param submission 
     */

    async updateSubmissionInDB(submission, currentData) {
        submission.data = currentData;
        await updateSubmissionDBItem(submission);
    }

    /*
     * method to set errror in submission 
     */
    async setError(submissionId: string, errors : string[]){
        let submission =await getSubmissionFromId(submissionId);
        submission.errors = errors;
        submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_IN_ERROR;
        submission.syncedOn = new Date().toISOString() ;
        this.setSubmissionSyncStatus(submission);
        
        await updateSubmissionDBItem(submission);
        return submission;
    }
    
    /*
     * method to get given pending submission from submission db
     */
    async getPendingSubmission(submissionId : string) {
        let userContext = await this.accessManager.getUserContext();
        let submissions :any = await getSubmissionsInDB(userContext.organizationId);
    
        if(submissionId === "selected-submission") {
            return JSON.parse(window.localStorage.getItem('selected-submission') || '{}');
        } else {
            return submissions.find((currentVal : any) => currentVal.id === submissionId);
        }
    }
    
    /*
     * method to get subbmissionId
     */
    getCurrentSubmissionId(){
        const currLocation  = window.location.pathname;
        const pattern = new RegExp('^\/form\/(?<submissionId>[\\w\-]{36})$');
        const match : any = currLocation.match(pattern);
        if(match && match.groups.submissionId) {
        return match.groups.submissionId;
        }
        console.error('Submission Id not found for current form');
    }
    
    /*
     * method to get unresolved Files from given data
     */
    getUnresolvedFiles(data: any){
        let unResolvedFiles: any[] = [];
        let fileNodes: any[] = [];
        CQSubmissionManager.findFileNodes(data, fileNodes);

        for (let index = 0; index < fileNodes.length; index++) {
            let file = fileNodes[index];
            if (file.ContentDocumentId === '' ) {
                unResolvedFiles.push(file);
            }
        }
        return unResolvedFiles;
    }

    /*
     * static method which gets the file nodes from submission data
     */
    static findFileNodes(formData: any, fileNodes: any[]){
        for (let key in formData) {
            if (key === 'Files') {
                let files = formData['Files'];
                files.forEach((file: any) => {
                    fileNodes.push(file);
                });
                break;
            }
            if (typeof (formData[key]) === 'object') {
                CQSubmissionManager.findFileNodes(formData[key], fileNodes);
            }
        }

        return fileNodes;
    }

    /*
     * method to update pending submission in submission DB
     */
    public async updateFilePathInSubmission (submissionId, fileInfo) {
        let userContext = await this.accessManager.getUserContext();
        let pendingSubmissions:any[] = await getSubmissionsInDB(userContext.organizationId);
        if(pendingSubmissions.length > 0 ){
            const submission : any = pendingSubmissions.find(obj => obj.id === submissionId);
        
            if(submission && submission.data && Object.keys(submission.data).length > 0 ){
                let unResolvedFiles: any[] = [];
                let fileNodes: any[] = [];
                CQSubmissionManager.findFileNodes(submission.data, fileNodes);
        
                for (let index = 0; index < fileNodes.length; index++) {
                    let file = fileNodes[index];
                    if (file.LocalId === fileInfo.LocalId && file.ContentDocumentId === '') {
                        //search for file uploaded async
                        if (fileInfo.ContentDocumentId !== '') {
                            file.ContentDocumentId = fileInfo.ContentDocumentId;
                            file.error = '';
                            file.progress = 100;
                        }else{
                            file.error = fileInfo.error;
                        }
                    }else if (file.ContentDocumentId === ''){
                        unResolvedFiles.push();
                    }
                }
                
                if(unResolvedFiles.length === 0){
                     submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_OUT_OF_SYNC;
                }else{
                    submission.syncStatus = CQApiConstant.SUBMISSION.SYNC_STATUS_FILE_UNSYNC;
                }
                
                await updateSubmissionDBItem(submission);
                return submission;
            }
        }
    }

    /**
     * method returns the synced date
     */
    getUpdatedSyncedDate = () => {
        return this.updatedHeaderSyncedDate;
    }
}

/*
 * class that holds structure of Submission
 */
export class CQSubmission{
    id: string = '';
    data: any = {};
    originalData: any = {};
    syncStatus: string = CQApiConstant.SUBMISSION.SYNC_STATUS_OUT_OF_SYNC
    status: string = CQApiConstant.SUBMISSION.STATUS_SUBMITTED;
    isUploading: Boolean = false;
    formName: string = '';
    formId: string = '';
    formDef: any = {};
    createdOn: string =  new Date().toISOString();
    syncedOn: string =  new Date().toISOString();
    version: number = 1;
    error: any =  [];
    instanceUrl : string;
    organizationId : string;

    /*
     * constructor for submission
     */
    constructor(formId, formDef, formName, submissionId , instanceUrl, organizationId){
        this.id=  submissionId;
        this.formName= formName;
        this.formId= formId;
        this.formDef= formDef;
        this.data= formDef.data || {};
        this.status= CQApiConstant.SUBMISSION.STATUS_IN_PROGRESS;
        this.instanceUrl = instanceUrl;
        this.organizationId = organizationId;
    }
    
    /**
     * This method updates the submission status to 'In Progress'
     * For repoen form submission
     *          --> Form submission SF record changed to 'In Progress' from 'Processed' or 'Submitted' 
     *          --> But Submission status remains as submitted 
     * makes submission as a new submission and process it
     * @param submission 
     * @returns submission
     */
     public static syncSubmission(submission){
        submission.status = submission.status === CQApiConstant.SUBMISSION.STATUS_SUBMITTED ? CQApiConstant.SUBMISSION.STATUS_IN_PROGRESS : submission.status;
        return submission
    }
}