import React, { useEffect, useState, useContext, useRef } from 'react';
import { Snackbar } from '@material-ui/core';
import CQPageHeader from '../components/CQPageHeader';
import Input from '@salesforce/design-system-react/components/input';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';


import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanelObj from '@salesforce/design-system-react/components/tabs/panel';
import CQTable from '../components/CQTable';
import strings from '../localizations/homeScreen';

import CQSubmissionContext from '../context/CQSubmissionContext';
import { getOfflineForms, deleteFormFromDB, getSubmittedSubmissions } from  '../api/submissiondbapi'
import AccessManagerContext from '../context/AccessManagerContext';
import SFAPI from '../api/sfapi';
import IAccessManager from '../api/IAccessManager';
import CQToast from './CQToast/CQToast';
import { CQApiConstant } from '../api/api-constants';
import CQPicklist from '../components/CQPicklist';
import { isMobileOnly } from 'react-device-detect';



function CQFormsComp({ cqForms, submissions, dispatch }: any) {

  const submissionContext = useContext(CQSubmissionContext);
  const accessManager : IAccessManager = useContext(AccessManagerContext);
  const sfAPI = new SFAPI().setAccessManager(accessManager);

  const [formsData, setFormsData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [pendingSubmission, setPendingSubmission] = useState<any[]>([]);
  const [submitted, setSubmitted] = useState<any[]>([]);
  const [assignedSubmission, setAssignedSubmission] = useState<any[]>([]);
  const [assignedTasks, setAssignedTasks] = useState<any[]>([]);
  const [errors, setErrors] = useState('');
  const [offlineSubmission, setOfflineSubmission] = useState<boolean>(false);
  const [headerSyncedDate, setHeaderSyncedDated] = useState('');
  const [selectedTab, setSelectedTab] = useState(strings.inProgress);

  /**
   * This methods retrieves pending submission from org and adds it to database
   */

  const retrievePendingSubmissionFromOrg = async () => {
    try {
      await submissionContext.retrievePendingSubmissionFromOrg();
    } catch (error) {
      setErrors(JSON.stringify(error));
    }
  }

  /**
   * This method retrieve Pending Submission
   */  
  const retrievePendingSubmission = async () => {
    try{
      setPendingSubmission(await submissionContext.getSubmissionsFromDB());
    }catch(error){
      if (error.message === 'Network Error') {
        setErrors(strings.networkError);
      } else {
        setErrors(JSON.stringify(error));
      }
    }
  }

  /**
   * This method check whether cache form is obselete or have offline support  or not 
   */
  const checkAndClearObsoleteForm = async () => {
     try {
        let offlineForms = await getOfflineForms();
        let recordIds : string [] = [];
        offlineForms.forEach(element => {
          recordIds.push(element.Id);
        });
        let formIds : string = recordIds.join(',');
        let response = await sfAPI.checkFormStatus(formIds);
        response.forEach(async (element) => {
          await deleteFormFromDB(element);
        }) 
     }catch(error) {
       if (error.message === 'Network Error') {
         setErrors(strings.networkError);
       } else {
         setErrors(JSON.stringify(error));
       }
     }
  }
  
  /**
  * This method retrieve scheduled Submission
  */  
 const retrieveAssignedSubmission = async () => {
   try{
    let response : any[] =  await submissionContext.getScheduledSubmissions();
     setAssignedSubmission(response || []);
   }catch(error){
     if (error.message === 'Network Error') {
       setErrors(strings.networkError);
     } else {
       setErrors(JSON.stringify(error));
     }
   }
 }

  /**
  * This method retrieve assigned tasks which are not started in inspection program
  */ 
 const retrieveAssignedTasks = async () => {
  try{
    let response: any[] = await sfAPI.getAssignedTasks();
    setAssignedTasks(response || []);
  }catch(error){
    if (error.message === 'Network Error') {
      setErrors(strings.networkError);
    } else {
      setErrors(JSON.stringify(error));
    }
  }
}

  /**
   * This methods retrieve forms from salesforce while initializing 
   */
  const formsRetreive = async () => {
    try {
      let offlineForms: any[] = await getOfflineForms();
      let response: any[] = await sfAPI.getForms();
      setFormsData(response || offlineForms);
    }catch (e) {
      if(e.message === 'Network Error'){
        setErrors(strings.networkError);
      }else {
        setErrors(JSON.stringify(e));
      }
    }
  }

  /**
   * This method search data from salesforce related org
   * @param data : search data
   */
  const hanldeSearch = async (data) => {
    try{
      if(data.length > 1 && formsData !== undefined){
        let filtered : any[] = await sfAPI.searchFormItem(data);
        setFilteredData(filtered);
      }else {
        setFilteredData(formsData);
      }
    }catch(error){
      setErrors(error);
    }

  }

  /**
   * This method gets the synced date for every 2 seconds in user timezone
   */  
   const getUpdatedHeaderSyncDate = async () => {
    try{
      setInterval(() =>{
        setHeaderSyncedDated(submissionContext.getUpdatedSyncedDate);
      },2000);
    }catch(error){
      if (error.message === 'Network Error') {
        setErrors(strings.networkError);
      } else {
        setErrors(JSON.stringify(error));
      }
    }
  }

  // Initilaize a refresh flag
  const refresh = useRef(false);

  /**
   * continously fetch inprogress submissions from database
   */
  useEffect(() => {
    let interval = setInterval(async() => {
      let newpending:any[] = await submissionContext.getSubmissionsFromDB();
      refresh.current = !refresh.current
      if (newpending.length !== pendingSubmission.length) {
        retrievePendingSubmission();
      }
    }, 2000)

    return (() => clearInterval(interval));
  }, [pendingSubmission.length])
  
  /**
   * Added logic to handle submitted forms on offline and synced on online
   */

  async function retrieveUnsyncedSubmissions() {
    let userContext = await accessManager.getUserContext();
    let unsyncedLocalSubmissions:any[] = await getSubmittedSubmissions(userContext.organizationId);
    
    if (submitted.length !== unsyncedLocalSubmissions.length) {
      setSubmitted(unsyncedLocalSubmissions);
    } else if(unsyncedLocalSubmissions.length === 0) {
      setSubmitted([]);
    }
  }
  
  /**
   * Initiate form loading only once
   */

  useEffect(() => {
    setOfflineSubmission(submissionContext.formSubmitted);
    retrievePendingSubmissionFromOrg(); //start adding pending submissions in database after user log in
    formsRetreive();
    retrievePendingSubmission();
    retrieveAssignedSubmission();
    retrieveAssignedTasks();
    checkAndClearObsoleteForm();
    getUpdatedHeaderSyncDate();
  },[])

  /**
   * Retrieved unsynced submissions and updated when synced
   */
  useEffect(() => {
    retrieveUnsyncedSubmissions();
  }, [refresh.current, pendingSubmission.length, submitted.length])

  /**
   * After form initilize, initilize filtered form data 
   */
  useEffect(() => {
    setFilteredData(formsData);
  }, [formsData, pendingSubmission])


  const TabsPanel: any = TabsPanelObj;
  const Icon : any = InputIcon;

  const tabsInfo = {
      assigned : { label: strings.assigned , value: strings.assigned, submissionType :"assigned" },
      inProgress: { label: strings.inProgress, value: strings.inProgress, submissionType: "inProgress" },
      submitted:{ label: strings.submitted , value: strings.submitted, submissionType: "submitted" },
      forms: { label: strings.forms, value: strings.forms, submissionType: "forms" }
  }

  const getRelatedTabInfo = () => {
    return Object.values(tabsInfo).filter((item) => item.value === selectedTab)[0]
  }
  
  return (
  <>
    <CQPageHeader syncedDateValue={headerSyncedDate}></CQPageHeader>
    <div className="cq-page-content slds-card slds-box">
      <IconSettings iconPath="/assets/icons">
        { isMobileOnly ? 
          <React.Fragment>
                <CQPicklist tabs={Object.values(tabsInfo)}
                  defaultTab = {selectedTab}
                  setSelectedTab ={setSelectedTab}
                />
                <div style={{margin: 2.5, padding:2.5}}>
                  {
                        <> 
                          {
                            selectedTab === strings.forms ?
                            <>
                              <IconSettings iconPath="/assets/icons">
                                <Input
                                  iconLeft={
                                    <Icon
                                      assistiveText={{
                                        icon: 'Search',
                                      }}
                                      name="search"
                                      category="utility"
                                    />
                                  }
                                  onChange = {(evt, data) => hanldeSearch(data.value)}
                                  id="unique-id-1"
                                  placeholder="Search"
                                  />
                              </IconSettings>
                              <br></br>
                            </> : null
                          }
                          <CQTable data={
                                    selectedTab === strings.assigned ? assignedTasks : 
                                    selectedTab === strings.inProgress ? pendingSubmission : 
                                    selectedTab === strings.submitted ? submitted :
                                    selectedTab === strings.forms ? filteredData : null
                                  }
                                  submittedTab = {selectedTab === strings.submitted}
                                  submissionType={getRelatedTabInfo().submissionType}>  
                          </CQTable>
                        </>
                  }
                </div>
            </React.Fragment>
        :
          <Tabs defaultSelectedIndex={CQApiConstant.TABS.IN_PROGRESS}>
            <TabsPanel label={strings.assigned + " (" + assignedTasks.length + ")"}>
              <CQTable data={assignedTasks} submissionType="assigned"></CQTable>
            </TabsPanel>
            <TabsPanel label={strings.inProgress + " (" + pendingSubmission.length + ")"}>
              <CQTable data={pendingSubmission} submissionType="inProgress"></CQTable>
            </TabsPanel>
            <TabsPanel label={strings.submitted + " (" + submitted.length + ")"}>
              <CQTable submittedTab={true} data={submitted} submissionType="submitted"></CQTable>
            </TabsPanel>
            <TabsPanel label={strings.forms + " (" + filteredData.length + ")"}>
              <IconSettings iconPath="/assets/icons">
                  <Input
                    iconLeft={
                      <Icon
                        assistiveText={{
                          icon: 'Search',
                        }}
                        name="search"
                        category="utility"
                      />
                    }
                    onChange = {(evt, data) => hanldeSearch(data.value)}
                    id="unique-id-1"
                    placeholder="Search"
                    />
              </IconSettings>
              <br></br>
              <CQTable data={filteredData} submissionType="forms"></CQTable>
            </TabsPanel>
          </Tabs>
        }
      </IconSettings>
    </div>
    {
      offlineSubmission ? 
        <CQToast
          duration = {CQApiConstant.TOAST_TIMER}
          variant = 'success'
          heading={strings.offlineFormSubmit}
        /> : null
    }

  </>);
}

const CQForms = CQFormsComp;
export default CQForms;

