/*
 * Http API Operations: This class acts as a helper class for api-service class,
 * base-service class and data-manager class.
 */
import { IErrorObject, IAPIResponse, ResponseType, APIResponseType, INullErrorType } from './IHttpApiService';
import { CQAppConstant } from '../app-constants';
import { CQResponseType } from './response-type';
/**
 * @export
 * @class CQAPIOperations
 */
export class CQAPIOperations {

  /**
   * @template T
   * @param {Response} response
   * @returns {(Promise<CQResponseType<IAPIResponse | IErrorObject>>)}
   * @memberof CQAPIOperations
   */
  public async parseAPIResponse<T>(
    response: Response
  ): Promise<CQResponseType<IAPIResponse | IErrorObject>> {
    const resp = await response.json();
    if (resp && response.status) {
      return CQResponseType.onValidResponse(resp.data).value;
    } else {
      throw this.handleErrorMessage(resp);
    }
  }


  /**
   * @param {string} endpoint
   * @param {ApiResponse} response
   * @returns {(CQResponseType<IAPIResponse | IErrorObject> | Promise<CQResponseType<IAPIResponse | IErrorObject>>)}
   * @memberof CQAPIOperations
   */
  public async createPostResponse<T extends ResponseType>(
    response: T
  ): Promise<CQResponseType<APIResponseType> | Promise<CQResponseType<APIResponseType>> | INullErrorType> {
    const res = response as Response;
    
    if (
      res.status !== 200) {
      throw this.handleErrorMessage(await res.json());
    } else {
      return CQAPIOperationsObj.parseAPIResponse(res);
    }
  }


  /**
   * @param {ApiResponse} response
   * @returns {(ICONResponseType<IAPIResponse | IErrorObject>)}
   * @memberof ICONAPIOperations
   */
  public createGetResponse(
    response: Response
  ): CQResponseType<APIResponseType> | Promise<CQResponseType<APIResponseType>> | INullErrorType {
    switch (
    response.status === CQAppConstant.API_RESPONSE_CODES.UNAUTHORIZED
    ) {
      case true: {
        const resp = response as string | IErrorObject;
        return CQResponseType.onError(resp).error;
      }
      case false:
      default: {
        return this.parseAPIResponse(response);
      }
    }
  }

  /**
   * @param {IErrorObject} errorObj
   * @returns {CQResponseType<IErrorObject>}
   * @memberof CQAPIOperations
   */
  public handleErrorMessage(errorObj: IErrorObject): IErrorObject | string | undefined {
    
    if (errorObj[0] && errorObj[0].message) {
      return CQResponseType.onError(errorObj[0].message).error;
    } else {
      return this.handleError(errorObj);
    }
  }

  /**
   * @private
   * @param {IErrorObject} errorObj
   * @returns
   * @memberof CQAPIOperations
   */
  private handleError(errorObj: IErrorObject) {
    
    if (errorObj.success === false && errorObj.messages) {
      return CQResponseType.onError(errorObj.messages[0]).error;
    } else {
      return CQResponseType.onError(errorObj).error;
    }
  }
}

export const CQAPIOperationsObj = new CQAPIOperations();

