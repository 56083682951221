import React from "react";
import { Droppable } from 'react-beautiful-dnd';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import { LayoutProps} from "@jsonforms/core";

const editableFieldsSection = (props: LayoutProps) => {
    const layoutUISchema : any = props.uischema; //TODO: figure out a better way to get elements

    const hasNoElements = layoutUISchema.elements.length === 0;
    return (<>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            {
                hasNoElements ? 
                <>
                    <Droppable droppableId={layoutUISchema.options?.id + '-' + 0} direction="vertical" type="FIELD">
                        {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}
                                className="slds-p-around_medium"
                                style={{
                                    'outline': '1px dashed rgba(43,40,38,.35)',
                                    flex: '1 1 100%',
                                    height: '3rem',
                                    backgroundColor: snapshot.isDraggingOver
                                    ? "gainsboro"
                                    : "inherit"
                                }}>
                        </div>)}
                    </Droppable>
                </>
                :
                <>
                    {layoutUISchema.elements.map((child, index) =>
                        <Droppable droppableId={layoutUISchema.options?.id + '-' + index} direction="vertical" type="FIELD">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}
                            style={{
                                flex: '1 1 100%',
                                backgroundColor: snapshot.isDraggingOver
                                ? "gainsboro"
                                : "inherit"
                            }}>
                                <div key={`${props.path}-${index}`}>
                                    <JsonFormsDispatch
                                        uischema={child}
                                        schema={props.schema}
                                        path={props.path}
                                        enabled={props.enabled}
                                        renderers={props.renderers}
                                        cells={props.cells}
                                    />
                                </div>
                            </div>
                        )}
                        </Droppable>
                    )}
                </>
            }
            </div>
        </>);
}


export default withJsonFormsLayoutProps(editableFieldsSection);