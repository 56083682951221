import React, { useState, useEffect, useContext } from 'react';
import strings from '../../localizations/homeScreen';
import Button from '@salesforce/design-system-react/components/button';
import moment from 'moment';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { deleteFormFromDB, storeFormDefInDB, getFormDef } from '../../api/submissiondbapi'
import { storeGuideImageInDB, deleteGuideImageFromDB } from '../../api/imagedbapi';
import AccessManagerContext from '../../context/AccessManagerContext';
import IAccessManager from '../../api/IAccessManager';
import { getNameSpaceField }  from '../../api/namespace'
import SFAPI from '../../api/sfapi';
import { AccessManager } from '../../api/AccessManager';
import {isMobileOnly} from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        Title: {
            color: '#0070D2'
        }
    }),
);

function CQFormList(props) {
    const classes = useStyles();
    const [download, setDownload] = useState(true);
    const accessManager : IAccessManager = useContext(AccessManagerContext);
    const sfAPI = new SFAPI().setAccessManager(accessManager);
    const accessManagerConst = new AccessManager();

    /**
     * This method encode image url into base64 data
     */
    const toDataURL = (src, callback, outputformat) => {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var canvas: any = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var dataURL;
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputformat);
            callback(dataURL);
        };
        img.src = src;
        if (img.complete || img.complete === undefined) {
            img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            img.src = src;
        }
    }

    /**
     * This method cache guide images
     * @param formDef 
     * @param Id 
     * @returns 
     */
    const cacheGuideImg = async (formDef, Id) => {
        try {
            let guides = formDef.ui.elements[2].options.guides;
            if (guides === undefined) {
                return;
            }
            guides.forEach(element => {
                toDataURL(
                    element.imageURL,
                    async function (dataUrl) {
                        let mapImageUrlWithEncoded = { Id, ImageUrl : element.imageURL, encodedImage : dataUrl}
                        await storeGuideImageInDB(mapImageUrlWithEncoded);
                    }
                    ,element.imageURL)
            });
        } catch (error) {
            console.error(error)
        }
    }

    /**
    * mthod help to cache form in DB
    * @param form 
    */
    const cacheOffline = async (form: any) => {
        try {
            let formDef = await sfAPI.getFormDefinition(form.Id);
            cacheGuideImg(formDef, form.Id);
            form = { ...form, formDef };
            storeFormDefInDB(form);
            setDownload(false);
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * method help to delete cached form
     * @param form 
     */
    const deleteForm = async (form: any) => {
        try {
            if (window.confirm(strings.deleteCache)) {
                deleteFormFromDB(form.Id);
                deleteGuideImageFromDB(form.Id);
                setDownload(true);
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * method help to cache form and clear form
     * @param form 
     */
    const toggleDownload = async (form: any) => {
        if (download) {
            cacheOffline(form);
        } else {
            deleteForm(form);
        }
    }

    /**
     * method checks whether forms is already cached or not 
     * @param form 
     */
    const checkInDB = async (form: any) => {
        let response = await getFormDef(form.Id);
        response.hasOwnProperty('queries') ? setDownload(false) : setDownload(true);
    }

    useEffect(() => {
        checkInDB(props.item);
    }, [])

    useEffect(() => {
    }, [download])

    return (

        <React.Fragment>
            {isMobileOnly ? (
                <div className="slds-box slds-grid slds-m-top_x-small slds-text-body_regular">
                    <div className="slds-col slds-size_4-of-12">
                        <p style={{ border: "none", fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small slds-m-horizontal_xxx-small">{strings.checklist}</p>
                        <div className={classes.Title} style={{overflowWrap: "anywhere"}}>{strings.documentTitle.replace('{name}', props.item.Name).replace('{title}', props.item.compliancequest__Title__c)}
                        </div>
                    </div>
                    <div className="slds-col slds-size_4-of-12 slds-m-horizontal_xxx-small cq-form-list-sync">
                        <p style={{ border: "none", fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small slds-m-horizontal_xxx-small">{strings.synced}</p>
                        <p>{moment(accessManagerConst.getDateTimeStringInUserTimeZone(props.item.LastModifiedDate)).calendar()}</p>
                    </div>
                    <div className="slds-col slds-size_4-of-12 slds-m-horizontal_small cq-form-list-button">
                        {getNameSpaceField(props.item, 'Supports_Offline__c') && (download ?
                            <Button
                                style={{height: 30}}
                                className="cq-formlist-download-btn"
                                iconCategory="utility"
                                iconName="download"
                                iconSize='small'
                                onClick={() => toggleDownload(props.item)}
                            /> :
                            <Button
                                style={{height: 30}}
                                iconCategory="utility"
                                className="cq-formlist-download-btn"
                                iconName="download"
                                iconSize='small'
                                variant='brand'
                                onClick={() => toggleDownload(props.item)}
                            />
                        )
                        }
                        <Button style={{height: 30}} onClick={() => props.createSubmission(props.item)}>{strings.launch}</Button>  
                    </div>
                </div>):(
                <div className="slds-box slds-grid slds-m-top_x-small slds-text-body_regular">
                    <div className="slds-col slds-size_6-of-12">
                        <p style={{ border: "none", fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small">{strings.checklist}</p>
                        <div className={classes.Title} style={{overflowWrap: "anywhere"}}>{strings.documentTitle.replace('{name}', props.item.Name).replace('{title}', props.item.compliancequest__Title__c)}
                        </div>
                    </div>
                    <div className="slds-col slds-size_3-of-12 slds-p-horizontal_small slds-m-horizontal_xxx-small">
                        <p style={{ border: "none", fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small">{strings.synced}</p>
                        <p>{moment(accessManagerConst.getDateTimeStringInUserTimeZone(props.item.LastModifiedDate)).calendar()}</p>
                    </div>
                    <div className="slds-col slds-size_3-of-12 slds-p-horizontal_medium slds-text-align_center">
                        {getNameSpaceField(props.item, 'Supports_Offline__c') && (download ?
                            <Button
                                style={{height: 30}}
                                iconCategory="utility"
                                iconName="download"
                                iconSize='medium'
                                onClick={() => toggleDownload(props.item)}
                            /> :
                            <Button
                                style={{height: 30}}
                                iconCategory="utility"
                                iconName="download"
                                iconSize='medium'
                                variant='brand'
                                onClick={() => toggleDownload(props.item)}
                            />
                        )
                        }
                        <Button onClick={() => props.createSubmission(props.item)}>{strings.launch}</Button>  
                    </div>
                </div>    
            )}
        </React.Fragment>
    )
}

export default CQFormList;