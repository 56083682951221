import React from "react";
import {
     ResolvedJsonFormsDispatch, 
     withJsonFormsLayoutProps,
} from "@jsonforms/react";

/**
 * Renderer used to render picklist values based on selected value in another field.
 */
const CQDependentPicklist = (props: any) => {
    const { data, uischema, schema, path, cells, visible, renderers, enabled } = props; 
    
    let tempSchema : any = { ...schema};  
    let ui : any = { ...uischema};
    const getSchema = () => {
        return updateSchemaOnBasisOfDependentValue();
    }
    const getUISchema = () => {
        ui.type = 'select';
        return ui;  
    }  
    /** This method updates the dependent field schema  
     *  @returns update the dependent field schema  
    */ 
    const updateSchemaOnBasisOfDependentValue = () => {
        try{
            let oneOfValues;
            let controllingFieldValue;
            let mainObj = ui?.controllingfield.split('/')[0];
            let controllingfield = ui?.controllingfield.split('/')[1];
            let dependentField = ui.scope.split('/').pop();  
            if(controllingfield && mainObj ){
                let filterOneOfvalue =  tempSchema.properties?.[mainObj].properties?.[controllingfield];
                if(tempSchema.properties?.[mainObj].properties?.[controllingfield].hasOwnProperty('child')){
                    //Address field logic
                    controllingFieldValue  = filterOneOfValueBasedOnConst(filterOneOfvalue, data && Object.keys(data).length ? data[mainObj][controllingfield] : undefined);
                    oneOfValues = controllingFieldValue.length ? tempSchema.properties?.[mainObj].properties?.[controllingfield].validFor[ controllingFieldValue[0].const] : [];
                }else{
                    //Audit logic
                    controllingFieldValue = data[mainObj][controllingfield]
                    oneOfValues = tempSchema.properties?.[mainObj].properties?.[controllingfield].validFor[controllingFieldValue]
                }
                tempSchema = JSON.parse(JSON.stringify(tempSchema));
                tempSchema.properties[mainObj].properties[dependentField].oneOf = oneOfValues;
        }  
        }catch(e){
            console.log(e)
        }finally{
            return tempSchema;
        }
    } 

    const filterOneOfValueBasedOnConst = (data, constant) => {
        if(data?.oneOf){
            data = data.oneOf.filter((item) => (item.title === constant || item.const === constant));
        }
        return data;
    }
    
    return (  
        <>   
        <ResolvedJsonFormsDispatch    
            schema={getSchema()}    
            uischema={getUISchema()}    
            path={path}    
            renderers={renderers}    
            cells={cells}    
            visible={visible}    
            enabled={enabled}   />  
        </> 
    );
};
export default withJsonFormsLayoutProps(CQDependentPicklist)