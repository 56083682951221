import axios from 'axios';
import IAccessManager, { ConnectionInfo } from './IAccessManager';
import UserContext from './UserContext';


const INSTANCE_URL = 'instanceurl';
const ORGANIZATION_ID = 'organizationId';
const USER_DETAILS = 'userDetails';
const USER_DETAILS_SF = 'userDetailsSF';

export class AccessManager implements IAccessManager {
    token : String = '';
    fetchTokenPromise: Promise<any> = Promise.resolve(false);
    loadedToken : boolean = false;
    public isCanvasMode: boolean = false;
    constructor(){
        this.fetchTokenPromise = this.getToken();
        if(window.location.href.includes('launchFromSF') || window.location.href.includes('CQEditFormSF')){
            this.isCanvasMode = true;
        }
    }

    getUserContextSync(): UserContext {
        let context = new UserContext();
        if(window && window.localStorage){
            
            context.url = window.localStorage.getItem(INSTANCE_URL) as String;
            context.token = this.token;
            context.organizationId = window.localStorage.getItem(ORGANIZATION_ID) as String;
            context.userDetails = window.localStorage.getItem(USER_DETAILS) as String;
        }

        return context;
    }
    
    getUserContext = async ()  : Promise<UserContext> => {
        if(this.loadedToken === false && window.navigator.onLine) {
            await this.fetchTokenPromise;
        }
        return this.getUserContextSync();
    }


    clearAuthorization = async () : Promise<void> => {
        window.localStorage.removeItem(INSTANCE_URL);
        window.localStorage.removeItem(ORGANIZATION_ID);
        window.localStorage.removeItem(USER_DETAILS);
        await this.deleteCookie();
        return Promise.resolve()
    }

    setConnectionDetails = async(connectionInfo : ConnectionInfo) => {
        localStorage.setItem(INSTANCE_URL, connectionInfo.instanceUrl);
        localStorage.setItem(ORGANIZATION_ID, connectionInfo.organizationId);
        await this.init(connectionInfo.token);
    }

    setUserDetails = async (connection ) => {
        localStorage.setItem('userDetails',JSON.stringify(connection.details));
    };

    getDateTimeStringInUserTimeZone = (dateValue) => {
        let timeZoneString : string = '';
        let dateString : string = '';
        if(localStorage.getItem(USER_DETAILS)){
            timeZoneString = JSON.parse(String(localStorage.getItem(USER_DETAILS))).TimeZoneSidKey;
        }else if(localStorage.getItem(USER_DETAILS_SF)) {
            timeZoneString = JSON.parse(String(localStorage.getItem(USER_DETAILS_SF))).timeZone;
        }else if(localStorage.getItem('timezoneKey')){
            timeZoneString = String(localStorage.getItem('timezoneKey'));
        }

        if(timeZoneString){
            dateString = new Date(dateValue).toLocaleString("en-US", {timeZone: timeZoneString});
        }else{
            dateString = new Date(dateValue).toLocaleString();
        }
        return dateString;
    }

    async postToken(token){
        await axios.post('/api/storeToken', {"token": token});
    }

    async init(token){
        this.token = token;
        await this.postToken(token);
    }

    public handleResponseErrors(ex : any, response: any, path : string) : Promise<void> {
        if(ex.message === 'Network Error') {
            throw new Error('Network Error');
        }
        if(response.status !== 200) {
            if(response.status === 401) {
                // TODO : clear auth if we receive forbidden/unauthorized
                //this.clearAuthorization();
            }
            //throw new Error(`Could not get the response from server ${path}`);
        }

        return Promise.resolve();
    }

    async getToken(){
        let response : any ='';
        try {
            response = await axios.get('/api/getToken');
            if (response.data) {
                this.token = response.data;
            } else {
                if (localStorage.getItem(USER_DETAILS)) {
                    window.location.reload();
                }
                localStorage.removeItem(USER_DETAILS);
            }
        } catch (ex) {
            this.handleResponseErrors(ex, ex.response,'/api/getToken' );
        } finally {
            this.loadedToken = true;
        }
        return response.data
    }

    async deleteCookie(){
        try {
            await axios.get('/api/deleteToken');
        } catch (ex) {
            throw Error('could not get response from server to delete token');
            
        }       
    }
}