import React, { useEffect } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Labels } from '@jsonforms/core/lib/util/renderer';
import Paper from '@material-ui/core/Paper';
import SignatureCanvas from 'react-signature-canvas';
import ReactSignatureCanvas from 'react-signature-canvas';
import Modal from '@salesforce/design-system-react/components/modal';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Button from '@salesforce/design-system-react/components/button';
import SignHere from '../assets/images/signHere.jpg';
import { isSalesforceBuild } from '../salesforceBuild';
import strings from '../localizations/formScreen'
import IconSettings from '@salesforce/design-system-react/components/icon-settings';

interface CQLookupProps {
  data: any;
  path: string;
  handleChange(path: string, value: any): void;
  required?: boolean;
  label: string | Labels;
  schema : any;
  uischema: any;
  errors: any;
  enabled : boolean;
}

const CQSignatureControl = ({ data, handleChange, path, required, label, schema, uischema, errors, enabled }: CQLookupProps) => {
  const enabledConst = window.location.pathname.indexOf('selected-submission') === -1 && enabled;

  const signaturePad = React.createRef<ReactSignatureCanvas>();
 
  const [toggle, setToggle] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');

  const clearSig = () => {
    if(signaturePad.current) {
      signaturePad.current.clear();
      handleChange(path + '/properties/SignatureBase64', '');
    }
  }

  useEffect(() => {
    if (data.SignatureBase64){
      setImageUrl(data.SignatureBase64);
    }
    if (signaturePad.current) {
      if (!signaturePad.current.getSignaturePad().onEnd) {
        signaturePad.current.getSignaturePad().onEnd = handleOnEnd;
      }
      if(data && signaturePad.current.isEmpty()) {
        signaturePad.current.fromDataURL(data.SignatureBase64);
      }

      if(!enabledConst) {
        signaturePad.current.off();
      }

    }
  }, [])
  const handleOnEnd = (): void => {
    if (signaturePad.current) {
      let sign: string = signaturePad.current.toDataURL();
      handleChange(path + '.SignatureBase64', sign);
      setImageUrl(sign);
      setToggle(false);
    }
  }

  /**
   * This method set toggle
   */
  const signatureClicked = () => {
    if(!isSalesforceBuild() && enabled){
      setToggle(true)
    }
  }

  return (
      <>  
      <div className="slds-box" onClick={signatureClicked}>
        {
          imageUrl ? (
            <>
            <img
              src={imageUrl}
              alt="signature"
              className="cq-image-preview"
            />
            </>
          ) : <img src={SignHere} alt="signature" className="cq-sign-here-img" onClick={signatureClicked}/>
        }
        <div className="slds-border_top">
          <div className="slds-align_absolute-center">
            {data?.Purpose}
           </div> 
        </div>
        <IconSettings iconPath="/assets/icons">
          <Modal
            isOpen={toggle}
            footer={[
              <Button label={strings.cancel} onClick={() => setToggle(false)} />,
              <Button label={strings.saveLabel} variant="brand" onClick={handleOnEnd} />,
            ]}
            onRequestClose={() => setToggle(false)}
            heading="Sign Here"
          >
            <section className="slds-p-around_large slds-m-bottom_large">
              <Paper elevation={0} style={{ border: 'solid 1px black', 'height': '200px', marginLeft: 'auto', marginBottom: '30px' }}>
                <div className="slds-m-bottom_large">
                  <SignatureCanvas ref={signaturePad}
                    canvasProps={{ className: 'cq-canvas-size' }} />
                  {enabled ? <DeleteOutlinedIcon onClick={clearSig}/>  : ''}
                </div>
                <div className="slds-border_top slds-p-bottom_small slds-align_absolute-center">
                  {data?.Purpose}
                </div>
              </Paper>
            </section>
          </Modal>
        </IconSettings>
      </div>
      {errors ? JSON.stringify(errors) : ''}
    </>
  );
};

export default withJsonFormsControlProps(CQSignatureControl);