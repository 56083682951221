import UserContext from "./UserContext";


export class ConnectionInfo {
    public instanceUrl;
    public organizationId;
    public token;
}

export default interface IAccessManager {
    setUserDetails(connection: any ): Promise<void>;
    setConnectionDetails(connectionInfo : ConnectionInfo) : Promise<void>;
    getUserContext() : Promise<UserContext>;
    getUserContextSync() : UserContext;
    clearAuthorization() : Promise<void>;
    handleResponseErrors(ex : any, response: any, path : string) : Promise<void>;
    isCanvasMode : Boolean;
}