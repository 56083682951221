import React from 'react';
import strings from '../../localizations/homeScreen';
import Button from '@salesforce/design-system-react/components/button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import {isMobileOnly} from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Title: {
      color: '#0070D2'
    }
  }),
);


function CQAssignedList(props) {
  const classes = useStyles();

  let instanceUrl = localStorage.getItem('instanceurl');
  return (

    <React.Fragment>
      {
        isMobileOnly ? (
        <div className="slds-box slds-grid slds-m-top_x-small slds-text-body_regular">
          <div className="slds-col slds-size_4-of-12">
            <p style={{ border: "none",fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small">{strings.checklist}</p>
            <div className={classes.Title}>
              <a href={instanceUrl + '/'+ props.item.taskId} target="_blank">{props.item.checklist}</a>
            </div>
          </div>
          <div className="slds-col slds-size_4-of-12">
            <p style={{ border: "none",fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small">{strings.subject}</p>
            <a href={instanceUrl + '/'+ props.item.taskId} target="_blank">{props.item.subject}</a>
          </div>
          <div className="slds-col slds-size_4-of-12 slds-p-horizontal_xx-small">
            <p style={{ border: "none",fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small">{strings.scheduledFor}</p>
            <a href={instanceUrl + '/'+ props.item.taskId} target="_blank">{props.item.scheduledFor}</a>
          </div>
        {/*  onClick={() => props.startSubmission(props.item)} */}
        </div>
        ):(
        <div className="slds-box slds-grid slds-m-top_x-small slds-text-body_regular">
          <div className="slds-col slds-size_5-of-12">
            <p style={{ border: "none",fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small">{strings.checklist}</p>
            <div className={classes.Title}>
              <a href={instanceUrl + '/'+ props.item.taskId} target="_blank">{props.item.checklist}</a>
            </div>
          </div>
          <div className="slds-col slds-size_5-of-12">
            <p style={{ border: "none",fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small">{strings.subject}</p>
            <a href={instanceUrl + '/'+ props.item.taskId} target="_blank">{props.item.subject}</a>
          </div>
          <div className="slds-col slds-size_2-of-12">
            <p style={{ border: "none",fontWeight: "bold", color: "darkgray"}} className="slds-m-bottom_xx-small">{strings.scheduledFor}</p>
            <a href={instanceUrl + '/'+ props.item.taskId} target="_blank">{props.item.scheduledFor}</a>
          </div>
        {/*  onClick={() => props.startSubmission(props.item)} */}
        </div>

        )
      }
    </React.Fragment>
  )
}

export default CQAssignedList;
