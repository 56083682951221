import React, { useEffect, useState } from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Icon from '@salesforce/design-system-react/components/icon';
import formBuilderScreenStrings from 'localizations/formBuilderScreen';
import Button from '@salesforce/design-system-react/components/button';

const CQEmptyStartingData = ({data,handleChange,path,required,visible, label,enabled,uischema,errors,schema, config}: any) => {


    const message = uischema.options?.dropplaceholder || `Drop documents here`;
    const queries = config.cqconfig?.queries || [];
    const sectionId = config.cqconfig?.sectionId;


    const [ documentsPerSection, setDocumentsPerSection] = useState<Array<any>>([]);

    useEffect(() => {
        if(!queries.hasOwnProperty('sectionId') && sectionId.length > 0) setDocumentsPerSection(queries);
        setDocumentsPerSection(queries.filter((item) => item.sectionId == sectionId));
    },[queries])

    return (<>
        <Droppable droppableId={path + '-' + sectionId} type={uischema.options?.droptype} >
                        {(provided, snapshot) => (
            <div className="slds-box slds-m-bottom_medium slds-m-horizontal_xx-small slds-m-top_small"
                 {...provided.droppableProps}
                 style={{
                    'outline': '1px dashed rgba(43,40,38,.35)',
                    backgroundColor: snapshot.isDraggingOver
                    ? "gainsboro"
                    : "inherit"
                }}
            ref={provided.innerRef}  >
                {provided.placeholder && <h1>{message}</h1>}
            </div>)}
        </Droppable>
        {(queries.length  > 0)  && (documentsPerSection.length > 0 ) ?
            <div className="slds-box slds-m-bottom_medium slds-m-horizontal_xx-small slds-m-top_small">
                <Droppable droppableId={path + '-' + sectionId + "-query"} type={path + '-' + sectionId + "-query"} direction="vertical">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {
                                documentsPerSection.map((query, index) => (            
                                    <Draggable key={query.type + '-' + sectionId + '-' + query.key} draggableId={query.type + '-' + sectionId + '-' + index} index={index}>
                                        {(drprovided, snapshot) => (
                                            <div className="slds-p-around_xx-small slds-text-heading_small" ref={drprovided.innerRef} 
                                                {...drprovided.draggableProps} {...drprovided.dragHandleProps}>
                                                <div className="slds-grid slds-gutters slds-box slds-box_x-small slds-is-relative"
                                                    style={{
                                                        backgroundColor: snapshot.isDragging ? 'white' : 'inherit'
                                                    }}>
                                                    <div className="slds-size_1-of-12 slds-is-absolute"  >
                                                            <Icon
                                                                assistiveText={{ label: formBuilderScreenStrings.dragLabel }}
                                                                category="utility"
                                                                colorVariant="default"
                                                                name="drag_and_drop"
                                                                size="x-small"
                                                            />
                                                    </div>
                                                    <div className="slds-size_10-of-12 slds-m-left_large">
                                                        {query.key}
                                                    </div>
                                                    <div className="slds-size_1-of-12">
                                                        <Button
                                                            iconCategory="utility"
                                                            iconName="delete"
                                                            iconSize="small"
                                                            variant="icon"
                                                            onClick={(evt) => {
                                                                const deleteDoc = new CustomEvent("cqdeletedoc",{
                                                                    bubbles : true,
                                                                    detail : {
                                                                        index : index,
                                                                        docId : query.key
                                                                    },
                                                                })
                                                                document.dispatchEvent(deleteDoc)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
            : <></>    
        }
    </>);
}

export default withJsonFormsControlProps(CQEmptyStartingData);