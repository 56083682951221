
const salesforce = 'Salesforce';

/**
 * this method checks environment varible 
 * If build is for salesforce it returns true otherwise false
 */
export function isSalesforceBuild(){  
  
  return (process.env.REACT_APP_FORMS_APP_MODE === salesforce)

}