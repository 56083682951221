import React, { useEffect, useRef, useState } from "react";
import formBuilderScreenStrings from "localizations/formBuilderScreen";
import CQFormBuilderTableModal from "../CQFormBuilderTableModal";
import { getFormattedProperties } from "../CQFormBuilderSF";
import { CQAppConstant } from "app-constants";
import { isSalesforceBuild } from "salesforceBuild";
import Button from '@salesforce/design-system-react/components/button';
import { adjustTextareaHeights } from "services/adjustTextareaHeight";
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import CQToast from "components/CQToast/CQToast";
import { CQApiConstant } from "api/api-constants";

const CQFormBuilderGrid = ({
  totalTableRows,
  tableId,
  schema,
  data,
  initialRowData,
  relationalAPIName,
  selectedObjectApiName,
  initialColumnData,
  schemaTableHeader,
  columnWidth,
  index
}) => {
  let updatedJsonColumnData = schema ? 
                              JSON.stringify(schema?.definitions[tableId]?.properties[tableId]?.properties) !== JSON.stringify(initialColumnData) ? 
                              getInitialColumns(schema?.definitions[tableId]?.properties[tableId]?.properties) : 
                              JSON.parse(JSON.stringify(initialColumnData)) : 
                              JSON.parse(JSON.stringify(initialColumnData));

  let initialColumnsData: any = useRef({[tableId]: [...updatedJsonColumnData],});

  let updatedJsonRowData =  totalTableRows ? 
                            getInitialRows(totalTableRows) : 
                            initialColumnsData.current[tableId].length !== initialRowData[0].length ? 
                            getInitialRows(initialColumnsData.current[tableId].length) : 
                            JSON.parse(JSON.stringify(initialRowData));

  let initialRowsData: any = useRef<any>([...updatedJsonRowData]);
  const [validationError , setValidationError] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [errorToastMessage, setErrorToastMessage] = useState('');
  const [apiNameByDefault, setApiNameByDefault] = useState('');

   // declare this variable for storing data in form service side format
  const [tableData, setTableData] = useState<object>({ [tableId] : {}});
  const [tableColumns, setTableColumns] = useState<Array<any>>([]);
  const [tableRows, setTableRows] = useState<Array<any>>([]);
  const [selectedColumn, setSelectedColumn] = useState<any>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [input, setInput] = useState('');
  const [validations, setValidations] = useState({regexRuleName : "", pattern: "",errorMessage:""});
  const [tableHeader, setTableHeader] = useState(   schemaTableHeader ? 
                                                    schemaTableHeader : 
                                                    schema?.definitions[tableId]?.properties[tableId]?.title !== formBuilderScreenStrings.tableHeader ? 
                                                    schema.definitions[tableId]?.properties[tableId]?.title : 
                                                    formBuilderScreenStrings.tableHeader
                                                );
  const [tableTitleValue, setTableTitleValue] = useState("");
  let fieldApiName = '';
  const [isColumnModal,setIsColumnModal] = useState(false);

  const [tableRelationalAPIName, setTableRelationalAPIName] = useState<string>(relationalAPIName);

  const tablePattern = /^G_Table_\d+$/;

  /**
   * This method get Initial columns from schema
   * @param properties 
   * @returns 
   */
  function getInitialColumns(properties: any) {
    let initialColumns: Array<any> = [];
    Object.keys(properties).map((property) => {
      initialColumns.push({ id: properties[property].id, name: property, title: properties[property].title, regexRuleName: properties[property].regexRuleName, pattern: properties[property].pattern, errorMessage: properties[property].errorMessage });
    });
    return initialColumns;
  }

  /**
   * This method returns rows on reediting the form
   * @param numberOfRows
   * @returns
   */
  function getInitialRows(numberOfRows: number) {
    let newRows: any = [];
    let totalRows: any = [];

    for (let i = 0; i < initialColumnsData.current[tableId].length; i++) {
      newRows.push({});
    }

    for (let i = 0; i < numberOfRows; i++) {
      totalRows.push(newRows);
    }

    return totalRows;
  }

  let isUpdated = useRef(false);
  let currentIndex: any = useRef();

  /**
   * This method adds new rows
   */
  const handleAddRow = () => {
    let newRows: any = [];
    for (let i = 0; i < tableColumns.length; i++) {
      newRows.push({});
    }
    addNewDataInTable();
    initialRowsData.current.push(newRows);
    setTableRows(initialRowsData.current);
    isUpdated.current = !isUpdated.current;
    updateUISchema();
  };

  /**
   * This method adds new column
   */
  const handleAddColumn = () => {
    let newColumn: any = {
      id: `C${initialColumnsData.current[tableId].length + 1}`,
      name: `C${initialColumnsData.current[tableId].length + 1}`,
      title: `Column ${initialColumnsData.current[tableId].length + 1}`,
      regexRuleName: "",
      pattern: "",
      errorMessage: "",
    };
    initialColumnsData.current[tableId].push(newColumn);
    setTableColumns(initialColumnsData.current[tableId]);
    // Update columnValues to include the title of the newly added column
    const newColumnValues = [...columnValues, newColumn.title];
    setColumnValues(newColumnValues);
    setColumnApiName(newColumn.name)
    for (let i = 0; i < initialRowsData.current.length; i++) {
      if (initialRowsData.current[i].length !== initialColumnsData.current[tableId].length) {
        initialRowsData.current[i].push({});
        tableData[tableId] = tableData[tableId].map(obj => ({ ...obj, [newColumn.name]: undefined })); // added new column data in table
      }
    }
    isUpdated.current = !isUpdated.current;
  };

  /**
   * This method selects/unselects checkbox on clicking
   * @param index
   */
  const handleColumnSelection = (index: any) => {
    if (selectedColumn) {
      if (
        Object.keys(selectedColumn).length &&
        parseInt(Object.keys(selectedColumn)[0]) === index
      ) {
        if (
          selectedColumn[index].hasOwnProperty("isSelected") &&
          selectedColumn[index].isSelected
        ) {
          setSelectedColumn(undefined);
          isUpdated.current = !isUpdated.current;
        }
      }
    } else {
      let newSelection = { [index]: { isSelected: true } };
      setSelectedColumn(newSelection);
      currentIndex.current = index;
      isUpdated.current = !isUpdated.current;
    }
  };

  /**
   * This method removes column from table
   * @param index
   */
  const handleRemoveColumn = (selectedIndex) => {
    const pattern = /^[Cc][0-9]/;
    if (selectedColumn) {
      if ((selectedColumn && Object.keys(selectedColumn).length > 0) || selectedColumn[selectedIndex].isSelected){
        let apiName = initialColumnsData.current[tableId][selectedIndex].name;
        initialColumnsData.current[tableId].splice(selectedIndex, 1);
        let rightColumnData = initialColumnsData.current[tableId].splice(selectedIndex, initialColumnsData.current[tableId].length); //consist of table properties which are on right side from selected column
        //updating column API index 
        rightColumnData.forEach((item, index)=> {
          if(pattern.test(item.name)){
            item.name = 'C' + Number(Number(selectedIndex) + index + 1);
          }
          item.id = 'C' + Number(Number(selectedIndex) + index + 1);
        })  
        //merged data 
        initialColumnsData.current[tableId] = [...initialColumnsData.current[tableId], ...rightColumnData];
        setTableColumns(initialColumnsData.current[tableId]);
        for (let i = 0; i < initialRowsData.current.length; i++) {
          if (
            initialRowsData.current[i].length >
            initialColumnsData.current[tableId].length
          ) {
            initialRowsData.current[i].pop();
          }
        }
        //remove column information from table data
        tableData[tableId].map((obj) => {
          delete obj[apiName];
        })

        //update table data after removed column
        let newTableData:any = [];
        tableData[tableId].map((obj, index) => {
          let innerObj = {};
          Object.keys(obj).map((key, innerIndex) => {
            if (pattern.test(key)) {
              Object.assign(innerObj, { [`C${innerIndex + 1}`]: obj[key] });
            } else {
              Object.assign(innerObj, { [key]: obj[key] });
            }
          });
          newTableData.push(innerObj);
        })

        tableData[tableId] = newTableData;
        setSelectedColumn(undefined);
      }
    } else {
      let lastColumn = initialColumnsData.current[tableId].pop();
      setTableColumns(initialColumnsData.current[tableId]);
      //remove column information from table data
      tableData[tableId].map((obj) => {
        delete obj[lastColumn.name];
      })
      for (let i = 0; i < initialRowsData.current.length; i++) {
        if (
          initialRowsData.current[i].length >
          initialColumnsData.current[tableId].length
        ) {
          initialRowsData.current[i].pop();
        }
      }
    }
    const newColumnValues = [...initialColumnsData.current[tableId].map(column => column.title)];
    const newColumnApiNames = [...initialColumnsData.current[tableId].map(column => column.name)];
    setColumnValues(newColumnValues);
    setColumnApiName(newColumnApiNames[0]);
    setTableData(tableData);
    eventForTableData(tableId);
    updateComponent();
    isUpdated.current = !isUpdated.current;
  };

  /**
   * This method remove row
   */
  const handleRemoveRow = () => {
    initialRowsData.current.pop();
    //removed last data from table data
    tableData[tableId].pop();
    setTableData(tableData);
    eventForTableData(tableId);
    setTableRows(initialRowsData.current);
    isUpdated.current = !isUpdated.current;
    updateUISchema();
  };

  /**
   * This method syns state with useref
   */
  const updateComponent = () => {
    setTableColumns([...initialColumnsData.current[tableId]]);
    setTableRows([...initialRowsData.current]);
    updateSchema();
  };

  /**
   * This method updates schema for table
   */
  const updateSchema = () => {
    if (schema) {
      if (schema.definitions[tableId].properties.hasOwnProperty(tableId)
      ) {
        schema.definitions[tableId].properties[tableId].title = tableHeader;
        schema.definitions[tableId].properties[tableId].properties =
          tableColumns.length
            ? getFormattedProperties(tableColumns)
            : schema.definitions[tableId].properties[tableId].properties;
      }
    }
  };

  /**
   * This method passes values to uischema
   */
  const updateUISchema = () => {
    const updateUISchema = new CustomEvent(
      CQAppConstant.EVENT_LISTENER.CQ_UPDATE_UISCHEMA,
      {
        bubbles: true,
        detail: {
          [fieldApiName ? fieldApiName : tableId]: {
            totalRows: initialRowsData.current.length,
            header: tableTitleValue === "" ? tableHeader : tableTitleValue,
            columnWidth: columnWidthRef.current,
          },
        },
      }
    );

    document.dispatchEvent(updateUISchema);
  };

  const [columnWidths, setColumnWidths] = useState({});
  const initialWidth = 250;
  let columnWidthRef: any = useRef(columnWidth ? columnWidth : {});
  /**
   * @description This method is used to set the increase and descrease width for the column
   * @param event
   * @param index
   */
  const handleMouseDrag = (event, index) => {
    let startPoint = event.pageX;
    //This method is used to calcutate the change in the mouse position and update the column width accordingly
    const handleMouseMove = (moveEvent) => {
      const deltaX = moveEvent.pageX - startPoint;
      setColumnWidths((prevColumnWidths) => {
        const newWidths = { ...prevColumnWidths };
        newWidths[tableColumns[index].name] = Math.max(
          50,
          initialWidth + deltaX
        );
        columnWidthRef.current = newWidths;
        updateUISchema();
        return newWidths;
      });
    };
    //this method is used to removes the event listeners for stoping the draging operation
    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  // This useEffect hooks ensures that the columns are initially distributed equally with a calculated width based on the total number of columns.
  useEffect(() => {
    const initialWidthObj = tableColumns.reduce((acc, column) => {
      acc[column.name] = Object.keys(columnWidthRef.current).length
        ? typeof columnWidthRef.current[column.name] !== "object" &&
          columnWidthRef.current[column.name]
          ? columnWidthRef.current[column.name]
          : initialWidth
        : initialWidth;
      return acc;
    }, {});
    setColumnWidths(initialWidthObj);
  }, [tableColumns]);

  useEffect(() => {
    //For the whole column validation when the pattern of column changes
    tableRows?.map((items,rowIndex)=>{
      items?.map((items,columnIndex)=>{
        const value=getCellValue(columnIndex,rowIndex);
        if(value && value!==undefined){
          const column = tableColumns[columnIndex];
          const regexPattern = column.pattern;
          //Check if the input field match the regex pattern 
          const regex = new RegExp(regexPattern);
          const isValid = regex.test(value);
          if(!isValid){
            setValidationError(prevErrors => ({
              ...prevErrors,
              [`${rowIndex}-${columnIndex}`] : true
            })); 
          }else{
            setValidationError(prevErrors => {
              const newErrors = {...prevErrors};
              delete newErrors[`${rowIndex}-${columnIndex}`];
              return newErrors;
            });
          }
        }
      })
    })
  }, [tableColumns, tableRows, initialColumnData]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      updateComponent();
    }, 100);
    setTableTitleValue(tableHeader);    
    setTableRelationalAPIName(relationalAPIName || tableRelationalAPIName);
    tableData[tableId] = setupData();
    eventForTableData(tableId);
    return () => clearTimeout(timeout);
  }, [isUpdated.current]);

  /**
   * This method updates header on saving modal
   * @param tableFieldApiName
   */
  const handleSave = (tableFieldApiName: string, relnAPIName : string) => {
    let gridIndex : number = Object.keys(schema.definitions).indexOf(tableId);
    let relnIndex : number = Object.keys(schema.properties[selectedObjectApiName].properties).indexOf(relationalAPIName);
    if((schema.definitions && Object.keys(schema.definitions).includes(tableFieldApiName) && Object.keys(schema.definitions).indexOf(tableFieldApiName) !== gridIndex)){
        //fire api duplicate error
        throwApiError('api');
    }else if ( (relationalAPIName !== relnAPIName )  && Object.keys(schema.properties[selectedObjectApiName].properties).includes(relnAPIName) && Object.keys(schema.properties[selectedObjectApiName].properties).indexOf(relnAPIName) !== relnIndex){
        throwApiError('reln');
    }else if (tableFieldApiName === relnAPIName) {
        throwApiError('same')
    }
    else{
      if(tableFieldApiName === ""){
        tableFieldApiName = tableId;
      }
      fieldApiName = tableFieldApiName;
      setTableHeader(tableTitleValue);
      setTableTitleValue("");
      if (fieldApiName && !schema.definitions[tableId].properties.hasOwnProperty(fieldApiName)) {
        //changed api name with save
        schema.properties = JSON.parse(JSON.stringify(schema.properties).replaceAll(`/definitions/${tableId}`, `/definitions/${tableFieldApiName}`));
        schema.definitions = JSON.parse(JSON.stringify(schema.definitions).replaceAll(tableId, tableFieldApiName));
      }
      //change relation API Name;
      if(relnAPIName !== relationalAPIName){
        for(const item in schema.properties[selectedObjectApiName]['properties']){
          if(Object.keys(schema.properties[selectedObjectApiName]['properties']).includes(relationalAPIName)){
            if(relnAPIName.length && item === relationalAPIName){
              schema.properties[selectedObjectApiName]['properties'][relnAPIName] = schema.properties[selectedObjectApiName]['properties'][relationalAPIName];
              delete schema.properties[selectedObjectApiName]['properties'][relationalAPIName];
              setTableRelationalAPIName(relnAPIName);
            }
          }
        }
      }

      updateGridId();
      updateUISchema();
      isUpdated.current = !isUpdated.current;
      setIsOpenModal(false);
    }
  };


  /**
   * Method used to update table id and header value in schema
   */
  const updateGridId = () => {
    const gridUpdateEvent = new CustomEvent('cqupdategridid',
      {
        bubbles: true,
        detail: {
          newGridId: fieldApiName,
          oldGridId: tableId,
          header: tableTitleValue === "" ? tableHeader : tableTitleValue
        },
      }
    );
    eventForTableData(fieldApiName);
    document.dispatchEvent(gridUpdateEvent);
  };

  /**
   * Method used to fire api name duplicate error
   */
  const throwApiError = (message : string) => {
    switch(message){
      case 'api':
        message = formBuilderScreenStrings.apiNameDuplicateError
        break;
      case 'reln':
        message = formBuilderScreenStrings.relnAPINameError
        break;
      case 'same':
        message = formBuilderScreenStrings.relnAndAPIError
    }
    const fireErrorEvent = new CustomEvent('cqthrowduplicateapierror',
      {
        bubbles: true,
        detail: {
          message: message
        },
      }
    );
    document.dispatchEvent(fireErrorEvent);
  };

  /**
   * This method handles header change
   * @param e
   */
  const handleUpdateHeader = (e) => {
    setTableTitleValue(e.target.value);
  };

  /**
   * set table data 
   * @param cellValue 
   * @param columnIndex 
   * @param rowIndex 
   */
  const updateTableData = (cellValue, columnIndex, rowIndex) => {
      if(tableData?.[tableId]){
        let columnAPIName = initialColumnsData.current[tableId][columnIndex].name;
        tableData[tableId][rowIndex][columnAPIName] = cellValue;
      }
  }

  /**
   * This method will add a new empty value row structure data to related table 
   */
  const addNewDataInTable = () => {
    let newRow = {}
    let columns = Object.keys(tableData[tableId][0]);
    columns.forEach((item) => {
       newRow = {...newRow, [item] : undefined}
    })
    tableData[tableId] = [...tableData[tableId], newRow];
    eventForTableData(tableId);
  }

  /**
   * setupData for storing table cell value 
   * @returns formatted data 
   */
  const setupData = () => {
    let rowData : object = {};
    let generalData: Array<object> = [];
    if(tableData[tableId].length) return tableData[tableId];
    if(data && data[tableId] && Object.keys(data[tableId]).length){
      generalData = data[tableId];
    }else{
      initialColumnData.forEach((item : any) => {
          rowData = {...rowData, [item.name] : undefined}
      });
      for(let i = 0; i < initialRowsData.current.length ; i++){
        generalData.push(JSON.parse(JSON.stringify(rowData)));
      }
    }
    return generalData;
  }

  /**
   * this 
   * @param columnIndex 
   * @param rowIndex 
   * @returns 
   */
  const getCellValue = (columnIndex, rowIndex) => {
    if(!Array.isArray(tableData[tableId])) return undefined;
    if(initialColumnsData.current[tableId].length === columnIndex) return undefined;
    let columnAPIName = initialColumnsData.current[tableId][columnIndex].name
    return tableData[tableId][rowIndex][columnAPIName];
  }

  // This method checks if string can be parsed
  const canBeParsedToInt = (str: any) => {
    const parsed = parseInt(str);
    return !isNaN(parsed) && parsed.toString() === str;
  }

  /**
   * This method process cell input and updates the related table data
   * @param e 
   * @param columnIndex 
   * @param rowIndex 
   */
  const handleRowChange = (e, columnIndex, rowIndex) => {
    let rowInput = tableColumns[columnIndex]?.regexRuleName === 'Numeric Only' ?
      canBeParsedToInt(e.target.value) ? 
        parseInt(e.target.value)
        : e.target.value === '' ? undefined : e.target.value
      : e.target.value === '' ? undefined : e.target.value;
    adjustTextareaHeights(e);
    setInput(rowInput);
    updateTableData(rowInput, columnIndex, rowIndex);
    if(rowInput!=="" && rowInput !== undefined && tableColumns[columnIndex]?.pattern !== ""){
      //Get the regex pattern and error message for the coloumn
      const column = tableColumns[index];
      const regexPattern = column.pattern;
      //Check if the input field match the regex pattern 
      const regex = new RegExp(regexPattern);
      const isValid = regex.test(rowInput); 
      if(!isValid){
        setValidationError(prevErrors => ({
          ...prevErrors,
          [`${rowIndex}-${columnIndex}`] : true
        })); 
      }
    }else{
      setValidationError(prevErrors => {
        const newErrors = {...prevErrors};
        delete newErrors[`${rowIndex}-${columnIndex}`];
        return newErrors;
      });
    }
    eventForTableData(tableId);
    updateComponent();
  }

  /**
   * This method provides the data of old apiname for table and set into new api name
   * @param id 
   * @returns 
   */
  const getUpdatedTableData = (id:any) => {
    const tableValues = tableData[id];
    return tableValues;
  }
  
  /**
   * This method fires event for updating parent grid state related to tableData
  */
 const eventForTableData = (id:any) => {
    const tableDataEvent = new CustomEvent("cqTableDataEvent",{
      bubbles : true,
      detail : {
          oldKey: Object.keys(tableData)[0],
          newKey: id,
          data : { [id] : Object.keys(tableData)[0]=== id ? 
            tableData[id]: 
            getUpdatedTableData(Object.keys(tableData)[0])
          }
      },
    })
    document.dispatchEvent(tableDataEvent)
  }

  /**
   * This method stops table being dragged when table column is dragged
   * @param value 
   */
  const disableDrag = (value: boolean) => {
    const dragEvent = new CustomEvent(
      CQAppConstant.EVENT_LISTENER.CQ_DRAG_TABLE,
      {
        bubbles: true,
        detail: {
          dragDisable: value,
        },
      }
    );
    document.dispatchEvent(dragEvent);
  };

  //This method is used to set the default value when user close the modal
  const handleCloseModal = () => {
    setTableTitleValue(tableHeader);
    setIsOpenModal(false);
    setIsColumnModal(false);
    const initialValues = initialColumnsData.current[tableId].map(column => column.title);
    setColumnValues(initialValues);
  }

  const [columnApiName, setColumnApiName] = useState("") ;

  /**
   * This function should be called when the user saves the modal
   * @param fieldApiNameInModal 
   * @param inputValue 
   */
  const handleColumnSave = (columnFieldApiName: string, inputValue: string, regexCode : string, pattern : string, errorMessage : string) => {
    // Generate the default API name based on the column title
    const defaultApiName = `CQ_Form_${inputValue.replace(/\s+/g, '_')}`;
    // Update table data with the new API name and set it in the index which was deleted
    const newApiName = columnFieldApiName ? columnFieldApiName : defaultApiName;
    if(columnFieldApiName ? apiNameByDefault !== columnFieldApiName : apiNameByDefault !== defaultApiName){
      tableData[tableId].forEach((item,index) => {
        item[newApiName] = item[apiNameByDefault] || undefined;
        delete item[apiNameByDefault];
        const entries = Object.entries(item);
        const newDefaultApiEntry :any = [newApiName, item[newApiName]];
        entries.splice(isIndex, 0,  newDefaultApiEntry);
        tableData[tableId][index] = Object.fromEntries(entries);
    });
    }

    // Check if the newApiName already exists for any other column
    const isDuplicateApiName = initialColumnsData.current[tableId].some(column => column.name === newApiName && column !== initialColumnsData.current[tableId][isIndex]);
    if (isDuplicateApiName) {
        // Handle the case where the newApiName already exists for another column
        throwApiError('api');
    } else {
        // Update the column's API name and title
        const newtableColumns = [...initialColumnsData.current[tableId]];
        const initialValues = initialColumnsData.current[tableId].map(column => column.title);
        newtableColumns[isIndex] = {
            ...newtableColumns[isIndex],
            name: newApiName,
            title: inputValue || initialValues[isIndex],
            regexRuleName: regexCode,
            pattern: pattern,
            errorMessage: errorMessage
        };
        initialColumnsData.current[tableId] = newtableColumns;
        setTableColumns(newtableColumns);
        // Update the column title only when the modal is saved
        const newColumnValues = [...columnValues];
        newColumnValues[isIndex] = inputValue || initialValues[isIndex];
        setColumnValues(newColumnValues);
        setColumnApiName(newApiName);
        setTableData({ ...tableData });
        isUpdated.current = !isUpdated.current;
        setIsColumnModal(false);
    }
};


  const [columnValues, setColumnValues] = useState<string[]>([]);

  //Initialize columnValues with the initial titles and api name for each column
  useEffect(() => {
    const initialValues = initialColumnsData.current[tableId].map(column => column.title);
    setColumnValues(initialValues);
  }, [initialColumnsData.current]);


  const [isIndex, setIsIndex] = useState(0);

  /**
   * This method is used update columnValues when a column's value changes
   * @param e 
   * @param index 
   */
  const handleColumnValue = (e:any, index: number) => {
    const newValues = [...columnValues];
    newValues[index] = e.target.value;
    setColumnValues(newValues);
  }

  /**
   * This method is used to open the column modal when user double click the column
   * @param columnIndex 
   */
  const handleDoubleClick = (columnIndex:number) => {
    setIsColumnModal(true);
    setIsOpenModal(false);
    setIsIndex(columnIndex);
    const column = initialColumnsData.current[tableId][columnIndex]; // Get the selected column
    const isDefaultApiName = /^C\d+$/.test(column.name); // Check if the API name follows the pattern C1, C2, etc.
    setApiNameByDefault(column.name);
    const apiName = isDefaultApiName ? '' : column.name; // If it's a default API name, display as empty; otherwise, display the actual API name
    setValidations({regexRuleName:column.regexRuleName,pattern:column.pattern,errorMessage:column.errorMessage})
    setColumnApiName(apiName);
  };

  const handleOpenTableModal = () =>{
    setIsOpenModal(true);
    setIsColumnModal(false);
  }
  let columnModalTitle = formBuilderScreenStrings.columnModalHeader;

  const timeCount = useRef(3000);

  const handleClick = (index:any) => {
    const column = tableColumns[index];
    setErrorToastMessage(column.errorMessage);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, timeCount.current)
  }


  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "0.5rem",
        border: "1px solid lightgrey",
        textAlign: "center",
        marginTop: "0.5rem",
      }}
    >
      <div className="slds-grid">
        <div className="slds-col slds-p-horizontal_medium">
          <label style={{ fontSize: "1rem" }}>{tableHeader}</label>
        </div>
        <div className="slds-p-bottom_x-small">
          <Button
            iconCategory="utility"
            iconName="delete"
            iconSize="medium"
            variant="icon"
            onClick={(evt) => {
                const deleteUISchema = new CustomEvent("cqdeletetable",{
                    bubbles : true,
                    detail : {id: tableId},
                })
                document.dispatchEvent(deleteUISchema)
            }}
          />
        </div>
      </div>
      
      <div className="slds-grid slds-size_12-of-12">
        {
          showToast && validationError ? 
            <CQToast className="slds-p-top_xx-large"
              duration={CQApiConstant.TOAST_TIMER}
              variant='warning'
              heading={errorToastMessage}
            /> : null
        }
        <div className="slds-grid cq-form-table slds-p-around_small slds-size_10-of-12">
          <table
            className="slds-table slds-table_bordered slds-table_col-bordered slds-table_cell-buffer slds-table_fixed-layout slds-table_resizable-cols"
            aria-labelledby="element-with-table-label other-element-with-table-label"
          >
            <thead>
              <tr className="slds-line-height_reset">
                {tableColumns.length > 0 &&
                  tableColumns.map((column, index) => (
                    <th
                      scope="col"
                      key={index}
                      className="slds-is-resizable slds-cell_action-mode slds-truncate"
                      style={{ width: `${columnWidths[column.name]}px` }}
                    >
                      <div
                        className="slds-resizable slds-has-flexi-truncate slds-m-vertical_small"
                        title={column.title}
                        style={{display : "inline-flex"}}
                      >
                        <input
                          type="checkbox"
                          className="slds-m-horizontal_small"
                          checked={
                            selectedColumn
                              ? parseInt(Object.keys(selectedColumn)[0]) ===
                                index
                                ? selectedColumn[index].isSelected
                                : false
                              : false
                          }
                          disabled={
                            selectedColumn
                              ? parseInt(Object.keys(selectedColumn)[0]) ===
                                index
                                ? false
                                : true
                              : false
                          }
                          onClick={() => handleColumnSelection(index)}
                        />
                        <label key={index} htmlFor="" 
                          onDoubleClick={()=>{handleDoubleClick(index)}}
                          style={{width: "99%", paddingRight: '2.5rem'}}
                          className="cq-column-label"
                        >{columnValues[index]}</label>
                        <span
                          className="slds-resizable__handle"
                          onMouseDown={(e) => handleMouseDrag(e, index)}
                          onMouseEnter={() => disableDrag(true)}
                          onMouseLeave={() => disableDrag(false)}
                        >
                          <span className="slds-resizable__divider"></span>
                        </span>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {tableRows.map((rows, rowIndex) => (
                <tr className="slds-hint-parent">
                  {rows.map((row, index) => (
                    <td
                      key={index}
                    >
                      <div style={{display: "flex", flexDirection: "row", justifyContent: "center", border: validationError[`${rowIndex}-${index}`]? '2px solid red' : '0.5px solid white'}}>
                              {
                                  validationError[`${rowIndex}-${index}`]?
                                  <span onClick={()=>handleClick(index)} className="slds-p-vertical_xxx-small">
                                    <IconSettings iconPath="/assets/icons">
                                      <div className="slds-p-horizontal_x-small">
                                        <Icon
                                          style={{cursor: "pointer"}}
                                          assistiveText={{ label: 'Warning' }}
                                          category="utility"
                                          colorVariant="warning"
                                          name="warning"
                                          size="xx-small"
                                        />
                                      </div>
                                    </IconSettings>
                                  </span> : null
                                }

                          <textarea
                          rows={1}
                          className={rows[index].value ? "cq-input-edit cq-table-textarea" : "cq-table-textarea"}
                          style={{width:'99%', border: '0.5px solid white'}} 
                          onChange={(event) => handleRowChange(event, index, rowIndex)}
                          value={tableData[tableId] ? getCellValue(index, rowIndex) : input}
                        />
                       </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="slds-card cq-grid-actions slds-size_2-of-12">
          <button
            className="slds-button slds-button_neutral slds-size_12-of-12"
            onClick={handleAddRow}
            disabled={isSalesforceBuild()}
          >
            {formBuilderScreenStrings.addRow}
          </button>
          <button
            className="slds-button slds-button_neutral slds-size_12-of-12"
            onClick={handleRemoveRow}
            disabled={initialRowsData.current.length < 2 || isSalesforceBuild()}
          >
            {formBuilderScreenStrings.removeRow}
          </button>
          <button
            className="slds-button slds-button_neutral slds-size_12-of-12"
            onClick={handleAddColumn}
            disabled={isSalesforceBuild()}
          >
            {formBuilderScreenStrings.addColumn}
          </button>
          <button
            className="slds-button slds-button_neutral slds-size_12-of-12"
            disabled={
              initialColumnsData.current[tableId].length < 3 ||
              isSalesforceBuild()
            }
            onClick={() => handleRemoveColumn(currentIndex.current)}
          >
            {formBuilderScreenStrings.removeColumn}
          </button>
          <button
            className="slds-button slds-button_neutral slds-size_12-of-12"
            disabled={isSalesforceBuild()}
            onClick={handleOpenTableModal}
          >
            {formBuilderScreenStrings.updateHeader}
          </button>
        </div>
      </div>
      <CQFormBuilderTableModal
        openModal={isOpenModal || isColumnModal}
        closeModal={handleCloseModal}
        validations={validations}
        handleUpdateHeader={isColumnModal ? (e) => handleColumnValue(e,isIndex) : handleUpdateHeader}
        handleSave={isColumnModal ? handleColumnSave : handleSave}
        headerValue={isColumnModal ? columnValues[isIndex] :  tableTitleValue}
        relationalAPIName={relationalAPIName}
        fieldApiName={isColumnModal ? columnApiName : (!tablePattern.test(tableId) ? tableId : fieldApiName)}
        title={ isColumnModal ? columnModalTitle : tableHeader}
        isColumnModal={isColumnModal}
      />
    </div>
  );
};

export default CQFormBuilderGrid;