import Dexie from 'dexie';
import { CQApiConstant } from './api-constants';
import { CQSubmission } from './CQSubmissionManager';

const db = new Dexie("SubmissionDatabase");

db.version(1).stores({
    "cachedforms": 'Id',
    "submissions": "id, syncStatus, organizationId"
});

/*
 * method to store forms in db
 */
export const storeFormDefInDB = async (form) => {
    try {
        await db["cachedforms"].add(form);
    } catch (ex) {
        console.error(ex);
        return false;
    }
    return true;
}

/**
 * @param Id : form Id 
 * @returns: matching form definition 
 */
export const getFormDef = async (Id : string) => {
    try{
        let ret = await db['cachedforms'].get({Id : Id});
        return ret.formDef
    }catch(ex){
        console.log(ex);
        return [];
    }
}

/**
 * @returns all offline forms 
 */

export const getOfflineForms = async () => {
    try{
        let usyncedSubmissions = await db['cachedforms'].toArray();
        return usyncedSubmissions;
    }catch(ex){
        console.log(ex);
        return [];
    }
}

/*
 * delete form of cachedform db for given Id
 */
export const deleteFormFromDB  = async (Id: string) => {
    // @ts-ignore
    try {
        await db['cachedforms'].delete(Id)
    } catch (ex) {
        console.log(ex);
        return false;
    }
    return true;
};

/*
 * method to store submission in db
 */
export const storeSubmissionInDB = async (submission: CQSubmission)  => {
    try{
        //@ts-ignore
        await db['submissions'].add(submission)
    }catch(ex){
        console.log(ex);
        return false;
    }
    return true;
}

/*
 * method to get Submissions from db for given id
 */
export const getSubmissionFromId = async (id: string)  => {

    // @ts-ignore
    try{
        let ret = await db['submissions'].get({'id': id});
        return ret;
    }catch(ex){
        console.log(ex);
        return null;
    }
}

/*
 * method to update submission in DB
 */
export const updateSubmissionDBItem = async (submission: CQSubmission) => {
    // @ts-ignore
    try{
        await db['submissions'].update(submission.id, submission);
    }catch(ex){
        console.log(ex);
        return false;
    }
    return true;
};

/*
 * delete submission of db for given id
 */
export const deleteSubmissionDBItem = async (id: string)  => {
    // @ts-ignore
    try{
        await  db['submissions'].delete(id)
    }catch(ex){
        console.log(ex);
        return false;
    }
    return true;
};


/*
 * method for returning one unsynced submission from db 
 */
export const getOutOfSyncSubmissionInDB = async ()  => {
    
    //@ts-ignore
    try{
        let usyncedSubmission = (await db['submissions'].get({syncStatus: CQApiConstant.SUBMISSION.SYNC_STATUS_OUT_OF_SYNC}));
        return usyncedSubmission;
    }catch(ex){
        console.log(ex);
        return null;
    }
}

/*
 * method for returning submissions from db 
 */
export const getSubmissionsInDB = async (organizationId) => {
    
    //@ts-ignore
    try{
        // check if organization id is null to prevent invalid keys error
        if (organizationId !== null) {
            let usyncedSubmissions = await db['submissions'].where({organizationId: organizationId}).toArray();
            usyncedSubmissions.sort(function(a, b) { 
                var dateA = new Date(a.createdOn).getTime();
                var dateB = new Date(b.createdOn).getTime();
                return dateA < dateB ? 1 : -1; 
                });
            return usyncedSubmissions;
        }

        return [];
    }catch(ex){
        console.log(ex);
        return [];
    }
}

export const getSubmittedSubmissions  = async (organizationId) => {
    try{
        if (organizationId !== null) {
            let usyncedSubmissions = await db['submissions'].where({organizationId: organizationId}).and(item => item.status === CQApiConstant.SUBMISSION.STATUS_SUBMITTED && (item.syncStatus === CQApiConstant.SUBMISSION.SYNC_STATUS_OUT_OF_SYNC || item.syncStatus === CQApiConstant.SUBMISSION.SYNC_STATUS_FILE_UNSYNC)).toArray();
            usyncedSubmissions.sort(function(a, b) { 
                var dateA = new Date(a.createdOn).getTime();
                var dateB = new Date(b.createdOn).getTime();
                return dateA < dateB ? 1 : -1; 
                });
            return usyncedSubmissions;
        }
        return [];
    }catch(ex){
        console.log(ex);
        return [];
    }
}

/** */
export const getInProgressSubmissions = async (organizationId) => {
    try{
        if(organizationId === null){
            return [];
        }
        let usyncedSubmissions = await db['submissions'].where({organizationId: organizationId}).and(item => item.status === CQApiConstant.SUBMISSION.STATUS_IN_PROGRESS).toArray();
        usyncedSubmissions.sort(function(a, b) { 
            var dateA = new Date(a.createdOn).getTime();
            var dateB = new Date(b.createdOn).getTime();
            return dateA < dateB ? 1 : -1; 
            });
        return usyncedSubmissions;
    }catch(ex){
        console.log(ex);
        return [];
    }
}

export const isIndexAvailable = async (submissionId) => {
    let ret = await db['submissions'].get({'id': submissionId});
    if(ret === undefined) return false;
    return true;
}
