
// Connected app properties to be used while authenticating user with salesforce - PRODUCTION
export const ENV_PRODUCTION = {
  production: true,
  CLIENT_ID: '3MVG9xOCXq4ID1uE7.92PkVetqpy4nTu7VJSKtdTknhINGR3cibcunp8Bl_Slwt5iocY3S.82I1nanLoVQc96',
  CLIENT_SECRET: '27D6FABA5039D705A43F834F12D93027B7924C74CAC249D9E3952044465004FC',
  REDIRECT_URI: 'http://localhost:3000/authSuccessCallback',
  AUTH_ENDPOINT : 'https://login.salesforce.com',
  RESPONSE_TYPE : 'token',

  // CQ Lightning Backend 
  NODE_END_POINT: 'http://localhost:8000/'
};
