import React, { useState } from 'react';
import Button from '@salesforce/design-system-react/components/button';
import formBuilderScreenStrings from 'localizations/formBuilderScreen';
import CQToast from '../../CQToast/CQToast';

function CQFormBuilderLogo(props) {
    const [showToast, setShowToast] = useState(false);
    const [errorToastMessage, setErrorToastMessage] = useState('');

    /**
     * This method is used to handle file/image that uploaded and display the image as logo in UI
     * @param evt 
     */
    const handleFileSelect = (evt: any) => {
        var f = evt.target.files[0]; // FileList object
        if(f && (f.type === 'image/png' || f.type === 'image/jpg' || f.type === 'image/jpeg')){
            if (Math.round(f.size / 1024) <= 200) {
                var reader = new FileReader();
                // Closure to capture the file information.
                reader.onload = ((theFile) => {
                    return  ((e : any) => {
                        var binaryData = e.target.result;
                        //Converting Binary Data to base 64
                        var base64String = window.btoa(binaryData);

                        let imgSrc : String = 'data:image/png;base64,' + base64String;
                        // dispatch event to builder component to set the image data string
                        const formLogoSave = new CustomEvent("cqformlogosave",{
                            bubbles : true,
                            detail : {
                                imgSrcString : imgSrc
                            },
                        })
                        document.dispatchEvent(formLogoSave)
                    });
                })(f);
                // Read in the image file as a data URL.
                reader.readAsBinaryString(f);
            }else if(Math.round(f.size / 1024) > 200){
                setErrorToastMessage('Logo size is ' + Math.round(f.size / 1024) + ' KB, Please limit it to 200KB');
                setShowToast(true);
                setTimeout(() => {
                    setShowToast(false);
                }, 5000);
            }
        }else if(f){
            setErrorToastMessage(formBuilderScreenStrings.logoTypeError);
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
        }
    }


    return ( 
        <>
            {
                props.isLogoUploaded ? 
                    <input id="logo-input" type="file" name="logo" data-testid="logo-input" style={{"display":"none"}} accept="image/png, image/jpg" onChange={handleFileSelect}/>
                : ''
            }
            
            {
                props.imageData?
                    <div id="imagepreviewSection">
                        <img id="previewimage"  alt="logo" src={props.imageData}  style={{height: "100px", width: "auto", maxWidth: "300px"}} />
                        <span className="slds-col_padded slds-is-absolute">
                            <Button
                                iconCategory="utility"
                                iconName="delete"
                                iconSize="large"
                                variant="icon"
                                style={{"color": "#005fb2"}}
                                onClick={props.deleteLogo}
                            />
                        </span>
                    </div>
                
                :''            
            }
            {showToast ?
                <CQToast 
                    variant="error"
                    heading={errorToastMessage}
                />: ""}
        </>
    )
}

export default CQFormBuilderLogo;