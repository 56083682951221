import React from 'react';
import Spinner from '@salesforce/design-system-react/components/spinner';
import strings from '../localizations/homeScreen';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }),
);

export default function CQCircularProgress(props) {
    
    const classes = useStyles();

    return (
        <>
        {props.isLoading ?
        <div className={classes.backdrop}>
            <Spinner
                size="small"
                variant="base"
                assistiveText={{ label: strings.mainFrameLoading }}
            />
        </div> : ''}
        </>
    )
}
