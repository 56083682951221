import React from 'react';
import { withJsonFormsLayoutProps, ResolvedJsonFormsDispatch } from '@jsonforms/react';
import { toDataPath } from '@jsonforms/core';
import ChildContext from '../context/CQChildDataContext';
import { isMobileOnly } from 'react-device-detect';

let childValue:any  = {};
const CQFlexLayout = (props) => {
  const { data, uischema, schema, path, visible, renderers, enabled } = props;

  /**
   * This method transforms ui schema type according to element data type
   * @param element 
   * @returns element
   */
  const getUISchema = (element) => {
    if(!(data && data.hasOwnProperty('cqext__SQX_Safety_Checklist__c'))) return element;
    if(data.hasOwnProperty('cqext__SQX_Safety_Checklist__c') && !data.cqext__SQX_Safety_Checklist__c.hasOwnProperty('cqext__Question_Type__c')) return element;    
    if (data.cqext__SQX_Safety_Checklist__c.cqext__Question_Type__c === 'Picklist') {
      element.type = 'select';
    } else if (data.cqext__SQX_Safety_Checklist__c.cqext__Question_Type__c === 'Radio') {
      element.type = 'radio';
    }
    return element;
  }

  const getClasses = (element) => {
    let classNames = ['cq-column'];

    if(element.type) {
      classNames.push('cq-column_' + element.type.toLowerCase());
    }

    if(element.options && element.options.style) {
      classNames.push('cq-column_' + element.options.style.toLowerCase());
    }

    if(element.options && element.options.size){
      classNames.push('slds-size_'+ element.options.size + '-of-12');
    }

    if(element.options && element.options.align){
      classNames.push('slds-text-align_'+ element.options.align.toLowerCase());
    }

    /**
     * Added conditional logic to check if schema has child in options to handle common sharing fields for objects
    */
    if ( element.hasOwnProperty('elements') && element.elements[0].options && element.elements[0].options.child) {
      let tempData = {...data};
      let childElement = element.elements[0];
      let parentPath = toDataPath(childElement.scope).split("."); // stores the path of parent object holding child key in its options
      for( let i = 0  ; i < parentPath.length ; i++){
        if(i === parentPath.length - 1){ // check if index is last element in parentLength array
          childValue[tempData['Name']] = tempData; // update value inside context provider with parent object
          break;
        }
        tempData = tempData[parentPath[i]] // update tempData if index is not last element in parentLength array
      }
    }

    return classNames.join(' ');
  }

  return (
    <>
      <div className="slds-grid slds-gutters slds-wrap">

        {visible && uischema.elements.map((element) => {
          return <>
            <ChildContext.Provider value={childValue}>
              {isMobileOnly ? 
                <ResolvedJsonFormsDispatch
                  uischema={getUISchema(element)}
                  schema={schema}
                  path={path}
                  renderers={renderers}
                  enabled={enabled}
                />
              :
                <div role="gridcell" className={getClasses(element)}>
                  <ResolvedJsonFormsDispatch
                    uischema={getUISchema(element)}
                    schema={schema}
                    path={path}
                    renderers={renderers}
                    enabled={enabled}
                  />
                </div>
              }
              <div className="cq-layout-separator"></div>
            </ChildContext.Provider>
          </>
        })}
      </div>
    </>
  );
};

export default withJsonFormsLayoutProps(CQFlexLayout);