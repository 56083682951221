import React, { useState, useEffect } from 'react';
import { composePaths } from '@jsonforms/core';
import { ResolvedJsonFormsDispatch, withJsonFormsArrayLayoutProps } from '@jsonforms/react';
import '../assets/slds/styles/salesforce-lightning-design-system.min.css'
import map from 'lodash/map';
import range from 'lodash/range';
import CQSectionHeader from '../components/CQSectionHeader';
import { isArray } from 'lodash';



const CQSection = (props) => {

  // const { uischema, schema, path, visible, renderers } = props;
  const [isToggle, setIsToggle] = useState<Boolean[]>([]);
  const [sectionIndex,setSectionIndex]=useState<any>([]);
  const { uischema, schema, path, data, renderers, enabled } = props;
  const childUISchema = uischema.layout;
  const queries = props.config.cqconfig?.queries || [];
  const startingData = props.config.cqconfig?.startingdata ;
  
  /**
   * This method return schema for guide
   * @param childSchema 
   * @param path 
   * @returns guide schema 
   */
  const getGuideSchema = (childSchema, path) => {
    let guideSchema : any = { 'type' : 'guide', 'scope' : path, 'options' : childSchema.options.guides }
    return guideSchema;
  }

  /**
   * This method set initial values of isToggle state
   */
  const initialize = () => {
    console.log('Initialize')
    let copy : Boolean[] = []
    for (let i = 0; i < data; i++) {
        copy[i] = false;
    }
    setIsToggle(copy)
  }


  /**
   * This method sets isToggle state after onClick action
   * @param i : Index of section
   */
  const toggle = (i) => {
    let tempIndex : Boolean[] = [];
    tempIndex = [...isToggle]
    tempIndex[i] = !isToggle[i]
    setIsToggle(tempIndex)
  }

  useEffect(() => {
    initialize();
    console.log("use effect")
  },[])
  
  useEffect(()=>{
    fetchSectionIds();
  },[startingData])

   /**
   * This method retrieve the index of the document's section for every individual section 
   */
  const fetchSectionIds=()=>{
    let documentIndex = 0;
    if(uischema.hasOwnProperty("section")){
      let sectionDocumentIndex:any = []; 
       queries.map((items) => {
          startingData?.map((data) => {
          let recordName;
          let relns;   
          if(isArray(data)){
             recordName= data[0]?.recordTrees[0]?.record.Name
             relns= data[0]?.recordTrees[0]?.relns
          }else{
            recordName= data?.recordTrees[0]?.record.Name
            relns= data?.recordTrees[0]?.relns
          }       
            if (items.filter.s_value === recordName) {
               relns?.cqext__SQX_Sections__r?.map((item) => {         
                const currentIndex = documentIndex;
                documentIndex++;
                if (parseInt(items?.sectionId) === uischema.section - 1) {  
                  sectionDocumentIndex.push(currentIndex);
                }
                });
              }
            });
            
        })
        setSectionIndex(sectionDocumentIndex);
    }else{
      setSectionIndex(null);
    }
  }

  const uiComponent=(i)=>(
  <li key={i} className="slds-accordion__list-item" >
    <section id={'cq_section' + i} className={"slds-accordion__section cq-accordion" + (isToggle[i] === false ? " slds-is-open" : "")}>
      <CQSectionHeader title={<ResolvedJsonFormsDispatch
        schema={schema}
        uischema={uischema.options.headerLayout}
        path={composePaths(path, `${i}`)}
        renderers={renderers}
        key={i}
        enabled={enabled}
      />} toggle={toggle} index={i} isToggle={isToggle[i]} isFormBuilder={false}/>
      <div className="slds-accordion__content">
        {
          uischema.options.guides !== undefined ?
            <ResolvedJsonFormsDispatch
              schema={schema}
              uischema={getGuideSchema(uischema, composePaths(path, `${i}`))}
              path={composePaths(path, `${i}`)}
              renderers={renderers}
              enabled={enabled}
            />
            : null
        }
        <ResolvedJsonFormsDispatch
          schema={schema}
          uischema={childUISchema}
          path={composePaths(path, `${i}`)}
          renderers={renderers}
          key={i}
          enabled={enabled}
        />
      </div>
    </section>
  </li>
  );

  return (
    <>
      <ul className="slds-accordion">
      {sectionIndex !== null ? (
        sectionIndex.map((index) => (
          uiComponent(index)
        ))
      ) : (
        range(data).map((i) => (
          uiComponent(i)
        ))
      )}
      </ul>
    </>
  );
};

export default withJsonFormsArrayLayoutProps(CQSection);