/*
 * Filename: sfAuthSuccess.tsx
 * Description: component for sf auth success.
 */

import React from 'react';
import jsforce from 'jsforce';


class CQSFAuthSuccess extends React.Component{

  componentDidMount() {
    const jsForce: any = jsforce;
    jsForce.browser.init();
  }

  render() {
    return(
      <></>
    );
  }
}
export default CQSFAuthSuccess;
