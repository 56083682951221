import React, { useState, useEffect,useContext } from 'react';
import { useHistory } from 'react-router-dom'
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import Input from '@salesforce/design-system-react/components/input';
import strings from '../../localizations/homeScreen';
import { IOAuthRequestOptions } from '../../interfaces/IApp.interface';
import { CQAppConstant } from '../../app-constants';
import { CQAPIService } from '../../services/http-api.service';
import AccessManagerContext from '../../context/AccessManagerContext';


import CQCircularProgress from '../CQCircularProgress';
import SFAPI from '../../api/sfapi';


function CQFormsLogin() {
    let history = useHistory();
    let cqAPIService = new CQAPIService();

    const sandBoxUrl = 'https://test.salesforce.com'
    const productionUrl = 'https://login.salesforce.com'
    const [configValue, setConfigValue] = useState<IOAuthRequestOptions>({});
    const [isloaded, setIsLoad] = useState<boolean>(false);
    const [isValidURL, setisValidURL] = useState<boolean>(false)
    const [customDomain, setCustomDomain] = useState<string>('')
    const accessManager = useContext(AccessManagerContext);
    const sfAPI = new SFAPI().setAccessManager(accessManager);
    const validationURL = (url) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$', 'i');
        setisValidURL(!!pattern.test(url));
    }

    /**
     * Initiate login process
     */
    const Login = async (loginUrl: string) => {
        let request: IOAuthRequestOptions;
        try {
            console.log('loginUrl', loginUrl);
            request = { ...configValue };
            request.SF_OAUTH_LOGIN_URL = loginUrl
            openSFAuthenticator(request);
        } catch (e) {
            console.error(e);
        }
    }


    function openSFAuthenticator(oAuthRequestBody: IOAuthRequestOptions) {
        initiateJsForceOauthConnection(oAuthRequestBody);
    }

    async function initiateJsForceOauthConnection(oAuthRequestBody: IOAuthRequestOptions) {
        try {
            await cqAPIService.login(oAuthRequestBody).then(async (connection) => {
                await sfAPI.setUserDetails(connection).then(() => {
                    history.push('/');
                });
            });
        } catch (error) {
            const errorObj = {
                errorStatus: error.status,
                errorType: CQAppConstant.ERROR_CUSTOM,
                errorMessage: error.error ? error.error : error.message,
                componentName: ''
            };
            return errorObj;
        }
    }

    /**
     * This method updated the configuration value
    */
    const fetchConfig = async () => {
        try {
            await fetch('/api/getEnv/')
                .then(response => response.json()
                    .then(data => {
                        setConfigValue(data);
                        console.log(data);
                    }));
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Initiate and set the configuration value 
     */
    useEffect(() => {
        fetchConfig();
    }, [])

    /**
     * This helps to make production and sandbox login option available when configValue is set properly
     */
    useEffect(() => {
        let sfClientId: string = 'SF_CLIENT_ID'

        if (configValue.hasOwnProperty(sfClientId)) {
            setIsLoad(true);
        }
    }, [configValue])

    return (<>
        <IconSettings iconPath="/assets/icons">
            <div className="slds-grid slds-grid_align-center cq-full-height slds-grid_vertical-align-center">
                <div className="slds-col slds-card slds-size_10-of-12 cq-login-card">
                    <div className="slds-grid slds-wrap slds-grid_align_center slds-grid_vertical-align-center">
                        <div className="slds-col slds-size_12-of-12 slds-m-top_x-large">
                            <img src="/assets/images/logo.png"></img>
                        </div>
                        <div className="slds-col slds-size_12-of-12">
                            <div className="slds-text-heading_large">{strings.formsTitle}</div>
                        </div>
                        <div className="slds-col slds-size_12-of-12  slds-m-top_x-large">
                            <Button
                                label={strings.loginProduction}
                                onClick={() => {
                                    Login(productionUrl)
                                }}
                                variant="brand"
                                disabled={!isloaded}
                                style={{ width: '90%' }}
                            />
                        </div>
                        <div className="slds-col slds-size_12-of-12  slds-m-top_medium">
                            <Button
                                label={strings.loginSandbox}
                                onClick={() => {
                                    Login(sandBoxUrl)
                                }}
                                variant="brand"
                                disabled={!isloaded}
                                style={{ width: '90%' }}
                            />
                        </div>
                        <div className="slds-col slds-size_12-of-12  slds-m-top_medium">
                            <div style={{ width: '90%', margin: 'auto' }}>
                                <Input
                                    type="url"
                                    assistiveText={{ label: strings.placeHolderCustomDomain }}
                                    placeholder={strings.placeHolderCustomDomain}
                                    onChange={(e, data) => {
                                        validationURL(data.value)
                                        setCustomDomain(data.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="slds-col slds-size_12-of-12  slds-m-top_medium">
                            <Button
                                label={strings.loginCustom}
                                onClick={() => {
                                    Login(customDomain)
                                }}
                                variant="brand"
                                disabled={!isValidURL}
                                style={{ width: '90%' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </IconSettings>

    </>);
}

export default CQFormsLogin;
