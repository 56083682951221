import IAccessManager, { ConnectionInfo } from "./IAccessManager";
import UserContext from "./UserContext";

export default class StubAccessManager implements IAccessManager {
    getUserContext() : Promise<UserContext> {
        
        return Promise.resolve(this.getUserContextSync());
    }

    getUserContextSync(): UserContext {
        let userContext = new UserContext();
        userContext.token = 'aksdjaksdjkasd';
        userContext.organizationId = '123123123';
        userContext.url = 'kasdkasjdkasjd';

        return userContext;
    }

    clearAuthorization() : Promise<void> {
        return Promise.resolve();
    }

    public handleResponseErrors(ex : any, response: any, path : string) : Promise<void> {
        return Promise.resolve();
    }

    setUserDetails(connection: any ): Promise<void>{
        return Promise.resolve();
    }

    async setConnectionDetails(connectionInfo : ConnectionInfo) {

    }

    isCanvasMode;
}