/**
 * Method used to add error in submission with custom message
 * @param submission 
 * @param errorObj 
 */
export const addCustomErrorInSubmission = (submission: any, errorObj: any) => {
    const path = Object.keys(errorObj).length ? errorObj.dataPath : '';
    const errorIndex: any = submission?.error.findIndex(obj => obj.dataPath === path);
    // push error object to error array of submission
    if(errorIndex === -1){
        submission?.error?.push(errorObj);
    }
}
/**
 * Method used to remove error object from error array of submission
 * @param submission 
 * @param dataPath 
 */
export const removeCustomErrorInSubmission = (submission: any, dataPath: string) => {
    const errorIndex: any = submission?.error.findIndex(obj => obj.dataPath === dataPath);
    // check there is already object error in array and remove it from error array of submission
    if(errorIndex > -1){
        submission.error.splice(errorIndex, 1);
    }
}