import React from "react";
import Modal from "@salesforce/design-system-react/components/modal";
import strings from "localizations/formScreen";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import Icon from "@salesforce/design-system-react/components/icon";

interface DiscardModalProps {
  openModal: boolean;
  closeModal: () => void;
  handleDiscardForDiscardModal: () => void;
}

export const CQDiscardModal = ({
  openModal,
  closeModal,
  handleDiscardForDiscardModal,
}: DiscardModalProps) => {
  const discardModalContainer = {
    backgroundColor: "orange",
    padding: "2rem",
  };

  const discardMessageStyle = {
    fontSize: "1.15rem",
  };

  const discardMessageContainer = {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  };

  const modalTopEndStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const modalFooterStyle = {
    marginTop: "3rem",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "2rem",
  };

  const warningIconStyle = {
    fill: "white",
  };

  return (
    <Modal
      dismissOnClickOutside={false}
      isOpen={openModal}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <IconSettings iconPath="/assets/icons">
        <div style={discardModalContainer}>
          <div style={modalTopEndStyle}>
            <div style={discardMessageContainer}>
              <Icon
                className="warning slds-icon-text-warning"
                category="utility"
                name="warning"
                size="medium"
                style={warningIconStyle}
              />
              <label style={discardMessageStyle}>
                {strings.discardMessage}
              </label>
            </div>
            <span
              onClick={closeModal}
              className="slds-col slds-size_1-of-12 slds-button"
            >
              <Icon
                assistiveText={{ label: "close" }}
                category="utility"
                colorVariant="default"
                name="close"
                size="medium"
                className="$font-size-11"
              />
            </span>
          </div>
          <div style={modalFooterStyle}>
            <button
              className="slds-button slds-button_neutral"
              onClick={closeModal}
            >
              {strings.cancel}
            </button>
            <button
              className="slds-button slds-button_brand"
              onClick={handleDiscardForDiscardModal}
            >
              {strings.discard}
            </button>
          </div>
        </div>
      </IconSettings>
    </Modal>
  );
};
