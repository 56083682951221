export class CQApiConstant{

    //submission constants
    public static SUBMISSION = {
        STATUS_IN_PROGRESS : 'in progress',
        STATUS_SUBMITTED: 'submitted',
        STATUS_SCHEDULED: 'scheduled',
        SYNC_STATUS_FILE_UNSYNC: 'file usnyc',
        SYNC_STATUS_OUT_OF_SYNC: 'out of sync',
        SYNC_STATUS_IN_SYNC: 'in sync',
        SYNC_STATUS_IN_ERROR: 'in error',
        SYNC_STATUS_OFFLINE_SUBMISSION_SYNC: 'offline submission sync'
    }

    public static TABS = {
        ASSIGNED_TO_ME : 0,
        IN_PROGRESS : 1,
        SUBMITTED : 2,
        FORMS: 3,
    }

    public static TOAST_TIMER = 3000;
}